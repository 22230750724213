webClient.controller('AirBookingDetails', ['$scope', '$window', '$timeout', '$http', '$q', '$i18next', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', 'trackingService',
    function ($scope, $window, $timeout, $http, $q, $i18next, tseApiClient, tseUtil, airHelper, trackingService) {
        $scope.getText = function (key) { return $window.culture.texts[key]; };

        $scope.dataForTheBot = {};
        $scope.botFirstStart = true;
        $scope.insurancesBookedForBot = null;
        $scope.bookingDetailsLoaded = false;
        $scope.insuranceLoaded = false;
        $scope.isBotLoaded = false;
        $scope.isloadingco2 = null;
        $scope.enableCO2 = false;
        $scope.hasHotel = false;
        $scope.totalCo2Average = 0;

        document.onclick = catchTheClickInTheBot;


        function catchTheClickInTheBot(event) {
            if (event) {
                if (event.type == "click") {

                    var overlay = document.getElementById('joe-chat-full-body-overlay');
                    if (overlay != null) {
                        overlay.style.display = 'none';
                    }
                    var element = event.target ? event.target : event.srcElement;

                    if (element.tagName === 'A' && element.href.includes('fbots')) {
                        event.preventDefault();
                        window.open(element.href);
                    }

                    if (element.tagName === 'IMG' && element.src.includes('fbots'))
                        window.open(element.src)
                }
            }
        }

        window.addEventListener('storage', message_receive);

        $scope.checkBotStart = function () {
            console.info("check starting bot.... ");
            console.info(window.location.hash);
            if (window.location.hash.includes('#restartBot')) {
                var clientId = localStorage.getItem('clientid');
                var botHash = localStorage.getItem('bothash');
                var btnId = localStorage.getItem('btnid');

                window.location.hash = '#botStarted';

                var startTimestampWaitForData;

                function waitForData(timestamp) {
                    if (startTimestampWaitForData === undefined) {
                        startTimestampWaitForData = timestamp;
                    }
                    var elapsed = timestamp - startTimestampWaitForData;
                    if ($scope.insuranceLoaded && $scope.bookingDetailsLoaded) {
                        console.info("loadchatbotfrommemory");
                        startChatBotFromMemory(clientId, botHash, btnId);
                    } else {
                        if (elapsed < 10000) {
                            requestAnimationFrame(waitForData);
                        } else {
                            toggleFreshbotLazyLoad(false, btnId);
                        }
                    }
                }

                function waitForDrawer() {
                    var button = document.getElementById('button-open-resa-drawer');
                    if (button != null) {
                        console.info("-- open drawer --");
                        if ($scope.productType.toUpperCase() !== 'PANIER_B2C') {
                            button.click();
                        }
                        toggleFreshbotLazyLoad(true, btnId);
                    } else {
                        requestAnimationFrame(waitForDrawer);
                    }
                }

                requestAnimationFrame(waitForDrawer);
                requestAnimationFrame(waitForData);
            }
        }

        //RestApi client object
        var apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
        //apiClient.enableDebug();    //Only for debugging....
        window.airHelper = airHelper;

        //Set the current login information
        $scope.loginInfo = $window.site.loginInfo;
        if ($scope.loginInfo != null && $scope.loginInfo.Agent) {
            $scope.agentId = $scope.loginInfo.Agent.AgentId;
        } else {
            $scope.agentId = null;
        }
        //$scope.clientId = $scope.loginInfo.Client.AccountNumber;
        //$scope.loginId = $scope.loginInfo.LoginId;
        $scope.showDetail = null;
        $scope.siteType = $window.site.type;
        $scope.enableOldResa = $window.enableOldResa;
        $scope.partnerId = $window.site.partnerId;
        $scope.partnerName = $window.partnerName;
        $scope.enableBrandedFares = $window.enableBrandedFares;
        $scope.enableContactForm = $window.enableContactForm;
        $scope.enablePostBookingInsurance = $window.enablePostBookingInsurance;
        if ($window.isAirbus) $scope.isAirbus = $window.isAirbus;
        $scope.isServair = $window.isServair;
        $scope.hideInvoices = $window.hideInvoices;

        $scope.login = { reference: '', email: '' };
        $scope.detailError = false;
        $scope.modified = false;
        $scope.isCrossFaring = false;
        $scope.paxType = {};
        $scope.paxType["ADT"] = "Adulte";
        $scope.paxType["CNN"] = "Enfant";
        $scope.paxType["INF"] = "Bébé";
        $scope.containsHotel = false;
        $scope.currentCabinClass = null;
        $scope.enableUpSellProducts = $window.enableUpSellProducts || $window.sessionStorage.getItem('forceUpsell') === 'true';
        $scope.productType = $scope.enableUpSellProducts && $scope.containsHotel ? 'PANIER_B2C' : 'flight';
        $scope.showAlert = new URLSearchParams(window.location.search).get('product') != 'dynamicpackage';
        $scope.displayforgottenbooking = new URLSearchParams(window.location.search).get('forgottenbookingpopup') == 'true';

        // All FindReservation Vars
        $scope.findReservation = {};
        $scope.forgottenResaModalOpened = false;
        $scope.findReservationError = false;
        $scope.findReservationSuccess = false;
        $scope.findReservationSubmitted = false;
        $scope.findReservationWaiting = false;
        $scope.findeReservationTimeout = false;
        $scope.findReservationInvalidEmail = false;
        var findReservationCanceler = $q.defer();
        var findReservationTimeoutRequest = '';

        // Drawer Vars
        $scope.drawerResaOpened = false;
        $scope.drawerDocumentsOpened = false;
        $scope.showEticket = false;
        $scope.showBill = false;
        $scope.showVoucher = false;
        $scope.showCB = false;

        // Open or Close FindResa Modal
        $scope.toggleForgottenResaModal = function () {
            if ($scope.forgottenResaModalOpened) {
                $scope.forgottenResaModalOpened = !$scope.forgottenResaModalOpened;
                $scope.resetFindReservation(true);
            } else {
                $scope.forgottenResaModalOpened = !$scope.forgottenResaModalOpened;
            }
        };

        if ($scope.displayforgottenbooking == true) {
            $scope.toggleForgottenResaModal();
        }

        // Launch Search Request
        $scope.onClickfindReservation = function () {
            $scope.findReservationSubmitted = true;
            if ($scope.findReservation.email && $scope.findReservation.name && !$scope.findReservationInvalidEmail) {
                $scope.resetFindReservation();
                $scope.findReservationWaiting = true;

                findReservationCanceler.resolve();
                findReservationCanceler = $q.defer();

                // Timeout for request
                findReservationTimeoutRequest = $timeout(function () {
                    findReservationCanceler.resolve();
                    $scope.findReservationWaiting = false;
                    $scope.findeReservationTimeout = true;
                }, 60000);

                $http.post('/api/api/reservation/findresabyemailandname', {
                    email: $scope.findReservation.email,
                    name: $scope.findReservation.name,
                    language: $window.lng
                }, { timeout: findReservationCanceler.promise }).then(function (response) {
                    $scope.findReservationWaiting = false;
                    console.log('response : ' + response);
                    if (response) {
                        if (response.status === 200) {
                            if (response.data === 0) {
                                console.log('success');
                                $scope.findReservationSuccess = true;
                            } else {
                                console.log('error');
                                $scope.findReservationError = true;
                            }
                        } else {
                            console.log('error');
                            $scope.findReservationError = true;
                        }
                        $timeout.cancel(findReservationTimeoutRequest);
                    }
                });
            }
        };

        function message_receive(event) {
            if (event.key != 'resetbot')
                return;
            if (event.newValue != null) {
                console.info("resetting instance of chatbot....");
                var newURL = window.location.href.replace('#restartBot', '');
                newURL = newURL.replace('#botStarted', '');
                window.location.href = newURL;
            }
        } 

        $scope.broadcast_bot_reset = function () {
            console.info("Broadcasting resetbot to everyone...");
            localStorage.setItem('resetbot', JSON.stringify('yes'));
            localStorage.removeItem('resetbot');
        }

        $scope.checkFindReservationEmail = function () {
            var email = $scope.findReservation.email;
            if (email && email.length > 0) {
                //var regexMail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                var regexMail = /(.+@.+\|.+@.+)|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                //var regexMail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                $scope.findReservationInvalidEmail = !regexMail.test(email);
            }
        }

        // Reset all for FindResa
        $scope.resetFindReservation = function (all) {
            $scope.findReservationSubmitted = false;
            $scope.findReservationError = false;
            $scope.findReservationSuccess = false;
            $scope.findeReservationTimeout = false;
            if (all) {
                $scope.findReservationWaiting = false;
                $scope.findReservationInvalidEmail = false;
                $scope.findReservation.email = '';
                $scope.findReservation.name = '';
                $timeout.cancel(findReservationTimeoutRequest);
                findReservationCanceler.resolve();
            }
        };

        //Open the modal with the key provided
        $scope.openModal = function (key) {
            if (key) {
                $timeout(function () {
                    $('[data-remodal-id=' + key + ']').remodal().open();
                },
                    200,
                    false);
            }
        };

        //Prepare the object to display in the Taxes Modal
        $scope.prepareTaxesDetailModal = function (key, paxType, fareItem, quantity) {
            $scope.displayTaxesDetailModal = false;
            if (fareItem) {
                var faresTaxes = airHelper.prepareTaxesObjectForBkgDetails(fareItem);

                if (faresTaxes) {

                    var taxesDetailsObject = {
                        paxtype: paxType,
                        key: key,
                        paxnumber: quantity,
                        fares: faresTaxes
                    };

                    $scope.modalTaxesKey = taxesDetailsObject.key;
                    $scope.modalTaxesPaxType = taxesDetailsObject.paxtype;
                    $scope.modalTaxesPaxFares = taxesDetailsObject.fares;
                    $scope.modalTaxesPaxNumber = taxesDetailsObject.paxnumber;

                    $scope.$broadcast("updateTaxesDetailModalData",
                        {
                            key: $scope.modalTaxesKey,
                            paxType: $scope.modalTaxesPaxType,
                            fares: $scope.modalTaxesPaxFares,
                            paxnumber: $scope.modalPaxNumber
                        });

                    $scope.displayTaxesDetailModal = true;

                    $scope.openModal('taxes-detail-modal-' + $scope.modalTaxesKey + '-' + $scope.modalTaxesPaxType);

                } else
                    return;
            }
        };

        //Get City Name or Iata: 
        //@desc this function returns the city name or airport name. In case of name undefined it returns airportIata
        $scope.getCityNameOrIata = function (airportIata) {
            if ($scope.view.airports[airportIata].cityIata) {
                if ($scope.view.cities[$scope.view.airports[airportIata].cityIata])
                    return $scope.view.cities[$scope.view.airports[airportIata].cityIata].name;
            }
            else {
                if ($scope.view.airports[airportIata].name)
                    return $scope.view.airports[airportIata].name;
                else
                    return airportIata;
            }
        };

        //Get Terminal name
        $scope.getTerminalDisplayName = function (airport, terminal) {
            if ($scope.view.airports && $scope.view.airports[airport]) {
                $scope.listTerminalsAux = $scope.view.airports[airport].terminal;
                if ($scope.listTerminalsAux) {
                    $scope.listTerminalsAux = $scope.listTerminalsAux.groupByKey('code');
                    if ($scope.listTerminalsAux[terminal])
                        return terminalDisplayName = $scope.listTerminalsAux[terminal].name;
                }
            }
        };

        $scope.fillInsurances = function (bookingId, view) {

            $window.FLoading({ vAction: 'show' });
            $.ajax({
                url: '/api/api/Insurance/GetBookedInsurance',
                type: 'get',
                data: {
                    bookingId: bookingId,
                },
                success: function (data) {
                    $window.FLoading({ vAction: 'hide' });
                    console.log("fillInsurances success");
                    console.log(data);
                    if (data) {
                        $scope.view.insurances = data;
                        $scope.insurancesBookedForBot = data.length > 0 ? data[0].Type : null;
                    }
                    $scope.insuranceLoaded = true;
                    $scope.$apply();
                },
                error: function (data) {
                    $window.FLoading({ vAction: 'hide' });
                    console.error("fillInsurances error");
                    console.error(data);
                    $scope.insuranceLoaded = true;
                    $scope.$apply();
                }
            });

            
        }

        $scope.retrieveCo2Data = function () {
            var allSegments = [];

            $scope.totalCo2Average = 0;

            $scope.view.itineraries.forEach(function (it) {
                var segments = it.segments;
                for (var i = 0; i < segments.length; i++) {
                    var segmentId = (segments[i].operatingAirline == null ? segments[i].airline : segments[i].operatingAirline) + segments[i].departure.airport + segments[i].arrival.airport + segments[i].equipment;
                    allSegments.push(segmentId);
                }
                it.co2Data = {};
                it.co2Data.co2Average = 0;
            });

            var bodyRequest = {
                segments: allSegments
            }

            var getCO2ValueFromCabinClass = function (cabinClass, co2Data) {
                switch (cabinClass.toUpperCase()) {
                    case "Y":
                        return co2Data.co2Economy;
                    case "S":
                        return co2Data.co2PremiumEconomy;
                    case "C":
                        return co2Data.co2Business;
                    case "F":
                        return co2Data.co2First;
                    default:
                        return co2Data.co2Average;
                }
            };

            $scope.isloadingco2 = true;

            $window.Typescript.CO2.Services.CO2Service.getCO2(bodyRequest).then(function (axiosResponse) {
                try {
                    var responseJson = JSON.parse(axiosResponse.data);
                    $scope.resultCO2 = responseJson.body;
                } catch (e) {
                    console.warn("Exception : " + e);
                    $scope.isloadingco2 = false;
                    $scope.$apply();
                    return;
                }

                $scope.CO2Map = new Map();
                for (var i = 0; i < $scope.resultCO2.length; i++) {
                    $scope.CO2Map.set($scope.resultCO2[i].id, $scope.resultCO2[i]);
                }

                var atLeastOneItineraryHasCo2Data = false;
                $scope.view.itineraries.forEach(function (it) {
                    var segments = it.segments;
                    var hasAllSegments = true;
                    var localAverageCo2Consumption = 0;
                    for (var j = 0; j < segments.length; j++) {
                        var segmentId = (segments[j].operatingAirline == null ? segments[j].airline : segments[j].operatingAirline) + segments[j].departure.airport + segments[j].arrival.airport + segments[j].equipment;
                        var co2Data = $scope.CO2Map.get(segmentId);
                        if (co2Data === undefined || co2Data === null) {
                            hasAllSegments = false;
                            break;
                        }
                        var co2Consumption = getCO2ValueFromCabinClass(segments[j].cabinClass, co2Data);
                        localAverageCo2Consumption += co2Consumption;
                    }
                    if (hasAllSegments) {
                        it.co2Data.co2Average = localAverageCo2Consumption;
                        $scope.totalCo2Average += localAverageCo2Consumption;
                        atLeastOneItineraryHasCo2Data = true;
                    }
                });

                $scope.view.itineraries.forEach(function (it) {
                    it.co2Data.atLeastOneItineraryHasCo2Data = atLeastOneItineraryHasCo2Data;
                });

                $scope.isloadingco2 = false;
                $scope.$apply();
            }, function (err) {
                    console.error(err);
                    $scope.isloadingco2 = false;
                    $scope.$apply();
            });
        }

        $scope.fillDataForTheBot = function (data) {
            console.info("== Information about booking details ==");
            console.info(data);

            var booking = data.bookings[0];
            var userInfo;
            var siteType;

            if (window.site.type == 2) {
                siteType = "B2B";
                var loginInfo = window.site.loginInfo.Agent;
                userInfo = {
                    firstName: loginInfo.FirstName,
                    lastName: loginInfo.LastName,
                    email: loginInfo.Account,
                    mobilePhone: loginInfo.Phone
                };
            } else {
                siteType = "B2C";
                userInfo = {
                    firstName: booking.client.firstName,
                    lastName: booking.client.lastName,
                    email: booking.client.email,
                    mobilePhone: booking.client.mobilePhone
                };
            }

            var getStatusValueFromInt = function (statusInt) {
                switch (statusInt) {
                    case 0:
                        return "Creating";
                    case 1:
                        return "InProgress";
                    case 2:
                        return "Completed";
                    case 3:
                        return "Canceled";
                    case 4:
                        return "Closed";
                    case 5:
                        return "Archived";
                    case 6:
                        return "Pending";
                    case 7:
                        return "Cancelling";
                    default:
                        return "Unknown";
                }
            };

            var getSubStatusValueFromInt = function (subStatusInt) {
                switch (subStatusInt) {
                    case 0:
                        return "none";
                    case 1:
                        return "AccessProvider";
                    case 2:
                        return "FraudControl";
                    case 3:
                        return "Unpaid";
                    case 5:
                        return "ToIssueTicket";
                    case 6:
                        return "Modification";
                    case 7:
                        return "Litigation";
                    case 8:
                        return "Refund";
                    case 9:
                        return "Option";
                    case 10:
                        return "Recovery";
                    default:
                        return "Unknown";
                }
            };

            var possibleFullLC = booking.pnrs[0].productType === "LC" || 
                booking.pnrs[0].productType === "RC";
            var companyType = possibleFullLC ? "FULL LC" : "FULL REG";

            if (possibleFullLC) {
                for (var i = 0; i < booking.pnrs.length; i++) {
                    if (booking.pnrs[i].productType != "LC" && booking.pnrs[i].productType != "RC") {
                        companyType = "MIX";
                        break;
                    }
                }
            } else {
                for (var i = 0; i < booking.pnrs.length; i++) {
                    if (booking.pnrs[i].productType == "LC" || booking.pnrs[i].productType == "RC") {
                        companyType = "MIX";
                        break;
                    }
                }
            }

            var ticketType = "oneWay";
            var itinerariesArray = [];
            var nbItineraries = 0;
            for (var i = 0; i < booking.pnrs.length; i++) {
                for (var j = 0; j < booking.pnrs[i].itineraries.length; j++) {
                    itinerariesArray.push(booking.pnrs[i].itineraries[j]);
                }
            }
            nbItineraries = itinerariesArray.length;
            if (nbItineraries > 1) {
                ticketType = "multiDestination";
            }
            if (nbItineraries == 2) {
                var itinerary1 = itinerariesArray[0];
                var itinerary2 = itinerariesArray[1];
                if (itinerary1.origin == itinerary2.destination &&
                    itinerary1.destination == itinerary2.origin) {
                    ticketType = "roundTrip";
                }
            }

            itinerariesArray.sort( function (it1, it2) { return new Date(it1.date) - new Date(it2.date) });
            var brand = window.location.hostname.includes("h24travel") ? "H24travel": "MISTERFLY";

            paxLeader = null;
            nbPax = 0;

            for (var i = 0; i < booking.pnrs.length; i++) {
                if (booking.pnrs[i].passengers.length > 0) {
                    paxLeader = booking.pnrs[i].passengers[0];
                    nbPax = booking.pnrs[i].passengers.length;
                }
            }

            var marketCodeSafe = window.marketCode;
            if (typeof marketCodeSafe === 'undefined') {
                var listOfAccountMarketFR = [745];
                if (listOfAccountMarketFR.indexOf(booking.agency.accountNumber) != -1) {
                    marketCodeSafe = 'BE';
                } else {
                    marketCodeSafe = 'FR';
                }
            }
            var companyName = (marketCodeSafe == 'FR' ? "00001" : "00003") + "_" + booking.agency.accountNumber + "_" + booking.agency.tradeName;

            formatDate = function (date) {
                return (date.getDate() < 10 ? "0" + date.getDate() : date.getDate())
                    + "/" + (date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1))
                    + "/" + date.getFullYear();
            }

            var reservationDate = formatDate(new Date(booking.timestamp));
            var departureDateFirstFlight = formatDate(new Date(itinerariesArray[0].date));
            var departureDateLastFlight = formatDate(new Date(itinerariesArray[itinerariesArray.length - 1].date));

            $scope.productType = $scope.enableUpSellProducts && $scope.containsHotel ? 'PANIER_B2C' : 'flight';

            $scope.dataForTheBot =
            {
                bookingNumber: booking.bookingNumber,
                brand: brand,
                status: getStatusValueFromInt(booking.status),
                subStatus: getSubStatusValueFromInt(booking.subStatus),
                product: $scope.view.bookingNumber.slice(-1) == 'V' ? 'FLIGHT' : $scope.productType.toUpperCase(),
                market: marketCodeSafe,
                siteType: siteType,
                reservationDate: reservationDate,
                local: window.site.culture == 'be' ? 'nl' : window.site.culture,
                partnerCode: booking.agency.accountNumber,
                partnerName: booking.agency.companyName,
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                email: userInfo.email,
                mobilePhone: userInfo.mobilePhone,
                companyType: companyType,
                companyName: companyName,
                ticketType: ticketType,
                departureDateFirstFlight: departureDateFirstFlight,
                departureDateLastFlight: departureDateLastFlight,
                nbItineraries: nbItineraries,
                optionBooked: booking.flexies.length > 0 ? booking.flexies[0].name : "",
                insuranceBooked: null,
                paxLeaderfirstName: paxLeader != null ? paxLeader.firstName : "",
                paxLeaderLastName: paxLeader != null ? paxLeader.lastName : "",
                paxLeaderPhone: paxLeader != null ? paxLeader.phone : "",
                nbPax: nbPax
            };

            console.info("--- Data for the bot ---");
            console.info($scope.dataForTheBot);

            $scope.bookingDetailsLoaded = true;
        }

        function resetFreshbot() {
            if (document.getElementById("spd-busns-spt")) {
                Freshbots.deActivate(true);
                Freshbots.resetCustomer(true);
                scriptFresh = document.getElementById("spd-busns-spt");
                if ('remove' in Element.prototype) {
                    scriptFresh.remove();
                } else {
                    scriptFresh.parentNode.removeChild(scriptFresh);
                }
                $scope.botFirstStart = false;
            }
        }

        function loadChatBotInMemory(dataClient, dataBotHash, btnId) {

            localStorage.setItem('clientid', dataClient);
            localStorage.setItem('bothash', dataBotHash);
            localStorage.setItem('btnid', btnId);

            if (window.location.hash.includes('#botStarted')) {
                if (typeof Freshbots === 'undefined') {
                    window.location = window.location.href.replace('#botStarted', '') + '#restartBot';
                    window.location.reload();
                } else {
                    window.location = window.location.href.replace('#botStarted', '') + '#restartBot';
                    Freshbots.resetCustomer();
                }
            }
            else {
                var n = document.getElementsByTagName('script')[0],
                    s = document.createElement('script');
                s.id = "spd-busns-spt";
                s.async = "async";
                s.src = 'https://cdn.euc-freshbots.ai/assets/share/js/freshbots.min.js';
                n.parentNode.insertBefore(s, n);
                s.onreadystatechange = s.onload = function (event) {
                    window.location = window.location.href.replace('#restartBot', '') + '#restartBot';
                    Freshbots.resetCustomer();
                };
            }
        }
        function getChatBotDataDynamicPackage() {
            console.info("-----------------  getChatBotData Dynamic Package  --------------------------");

            var accommodationInfo = $scope.view._innerData.bookings[0].accommodations[0];
            var date1 = new Date(accommodationInfo.to);
            var date2 = new Date(accommodationInfo.from);
            var diffTime = Math.abs(date2 - date1);
            var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            var valueees = {
                "cstmr::lng": $scope.dataForTheBot.local,
                "cstmr::xtrInfrmtn:Resa_Contact_LastName": $scope.dataForTheBot.lastName,
                "Resa_Paxleader_FirstName": $scope.dataForTheBot.paxLeaderfirstName,
                "Resa_Network": "",
                "Resa_AccountNumber": $scope.dataForTheBot.partnerCode,
                "Resa_Booking_Date": $scope.dataForTheBot.reservationDate,
                "Resa_LastItin_Date": $scope.dataForTheBot.departureDateLastFlight,
                "Resa_Brand": $scope.dataForTheBot.brand,
                "cstmr::xtrInfrmtn:Resa_Contact_Email": $scope.dataForTheBot.email,
                "Resa_Market": $scope.dataForTheBot.market,
                "Resa_Airline_Type": $scope.dataForTheBot.companyType,
                "Resa_Travel_Doc_Email": "",
                "Resa_EnterpriseID": $scope.dataForTheBot.market == 'FR' ? "00001" : "00003",
                "Resa_Insurance": $scope.insuranceCanceled ? null : $scope.insurancesBookedForBot,
                "Resa_SousStatutDossier": $scope.dataForTheBot.subStatus,
                "Resa_Channel": $scope.dataForTheBot.siteType,
                "Resa_FirstItin_Date": $scope.dataForTheBot.departureDateFirstFlight,
                "Resa_Language": $scope.dataForTheBot.local,
                "cstmr::phn": "",
                "Resa_nb_itineraries": $scope.dataForTheBot.nbItineraries,
                "Resa_StatutDossier": $scope.dataForTheBot.status,
                "Resa_Prestation": "DP_B2C",
                "cstmr::eml": "",
                "Resa_NumeroDossier": $scope.dataForTheBot.bookingNumber,
                "Resa_PaxLeader_LastName": $scope.dataForTheBot.paxLeaderLastName,
                "cstmr::nm": "",
                "Resa_nb_pax": $scope.dataForTheBot.nbPax,
                "cstmr::xtrInfrmtn:Resa_Contact_Phone": $scope.dataForTheBot.mobilePhone,
                "Resa_Options": $scope.dataForTheBot.optionBooked,
                "Resa_CompanyName": $scope.dataForTheBot.companyName,
                "cstmr::xtrInfrmtn:Resa_Contact_FirstName": $scope.dataForTheBot.firstName,
                "Resa_supplier_code": accommodationInfo.supplierCode,
                "Resa_supplier_ref": accommodationInfo.customerBookingRef,
                "Resa_instance": $scope.dataForTheBot.brand,
                "Resa_number_days": diffDays,
                "Resa_Room_type": accommodationInfo.rooms[0].roomTypeName,
                "Resa_number_rooms": accommodationInfo.rooms.length,
                "Resa_paid_price": accommodationInfo.totalPriceRacknet,
                "Resa_destination": accommodationInfo.cityName,
                "Resa_flight_info": "",
                "Resa_accomname": accommodationInfo.accomName,
                "Resa_accom_idnum": accommodationInfo.accomCode
            };
            console.info("les vrais valeurs du bot envoyées .... ");
            console.info(JSON.stringify(valueees));
            return valueees;
        }

        function getChatBotDataFlight() {
            console.info("-----------------  getChatBotData Flight  --------------------------");
            var valueees = {
                "cstmr::xtrInfrmtn:Resa_Contact_LastName": $scope.dataForTheBot.lastName,
                "Resa_Paxleader_FirstName": $scope.dataForTheBot.paxLeaderfirstName,
                "Resa_Network": "",
                "Resa_AccountNumber": $scope.dataForTheBot.partnerCode,
                "Resa_Booking_Date": $scope.dataForTheBot.reservationDate,
                "Resa_LastItin_Date": $scope.dataForTheBot.departureDateLastFlight,
                "Resa_Brand": $scope.dataForTheBot.brand,
                "cstmr::xtrInfrmtn:Resa_Contact_Email": $scope.dataForTheBot.email,
                "Resa_Market": $scope.dataForTheBot.market,
                "Resa_Airline_Type": $scope.dataForTheBot.companyType,
                "Resa_Travel_Doc_Email": "",
                "Resa_EnterpriseID": $scope.dataForTheBot.market == 'FR' ? "00001" : "00003",
                "Resa_Insurance": $scope.insurancesBookedForBot,
                "Resa_SousStatutDossier": $scope.dataForTheBot.subStatus,
                "Resa_Channel": $scope.dataForTheBot.siteType,
                "Resa_FirstItin_Date": $scope.dataForTheBot.departureDateFirstFlight,
                "Resa_Language": $scope.dataForTheBot.local,
                "Resa_nb_itineraries": $scope.dataForTheBot.nbItineraries,
                "Resa_StatutDossier": $scope.dataForTheBot.status,
                "Resa_Prestation": $scope.dataForTheBot.product,
                "Resa_NumeroDossier": $scope.dataForTheBot.bookingNumber,
                "Resa_PaxLeader_LastName": $scope.dataForTheBot.paxLeaderLastName,
                "Resa_nb_pax": $scope.dataForTheBot.nbPax,
                "cstmr::xtrInfrmtn:Resa_Contact_Phone": $scope.dataForTheBot.mobilePhone,
                "Resa_Options": $scope.dataForTheBot.optionBooked,
                "Resa_CompanyName": $scope.dataForTheBot.companyName,
                "cstmr::xtrInfrmtn:Resa_Contact_FirstName": $scope.dataForTheBot.firstName,
                "cstmr::lng": $scope.dataForTheBot.local
            };
            console.info("les vrais valeurs du bot envoyées .... ");
            console.info(JSON.stringify(valueees));
            return valueees;
        }

        function startChatBotFromMemory(dataClient, dataBotHash, btnId) {
            (function (d, c) {
                if (!d.getElementById("spd-busns-spt")) {
                    var n = d.getElementsByTagName('script')[0],
                        s = d.createElement('script');
                    var loaded = false;
                    s.id = "spd-busns-spt";
                    s.async = "async";
                    s.setAttribute("data-self-init", "false");
                    s.setAttribute("data-init-type", "opt");
                    s.src = 'https://cdn.euc-freshbots.ai/assets/share/js/freshbots.min.js';
                    s.setAttribute("data-client", dataClient);
                    s.setAttribute("data-bot-hash", dataBotHash);
                    s.setAttribute("data-env", "prod");
                    s.setAttribute("data-region", "euc");
                    //Bot other
                    if (dataBotHash == "71c5bc8174775dac6828f5440a8bf241a8798a06") {
                        console.info("data-bot-version 86");
                        //s.setAttribute("data-bot-version", "86");
                    }
                    n.parentNode.insertBefore(s, n);
                    if (c) {
                        s.onreadystatechange = s.onload = function (event) {
                            if (!loaded) {
                                c();
                            }
                            loaded = true;
                            $scope.isBotLoaded = true;
                            toggleFreshbotLazyLoad(true, btnId);
                        };
                    }
                }
            })
                (document, function () {
                    Freshbots.initiateWidget(
                        {
                            autoInitChat: false,
                            getClientParams: function () {

                                console.info("$scope.containsHotel --> " + $scope.containsHotel);
                                return $scope.partnerName != 'airbus' && $scope.containsHotel && $scope.siteType != 2 ? getChatBotDataDynamicPackage() : getChatBotDataFlight();
                               
                            }
                        },
                        function (successResponse) {
                            console.info("succes on chatbot initialisation");
                            Freshbots.showWidget(true);
                            toggleFreshbotLazyLoad(false, btnId);
                            startInterceptor();
                        },
                        function (errorResponse) {
                            console.info("error on chatbot initialisation");
                            toggleFreshbotLazyLoad(false, btnId);
                        }
                    );
                });
        }

        $scope.startChatBot = function (botIdentifier, btnId) {
            toggleFreshbotLazyLoad(true, btnId);
            var dataClient;
            var dataBotHash;
            console.info("botIdentifier : " + botIdentifier);
            switch (botIdentifier) {
                case 'cancel':
                    dataClient = "0788ab3bd675d3a96b9939c1663ead373d221d67";
                    dataBotHash = $scope.partnerName != 'airbus' && $scope.containsHotel && $scope.siteType != 2 ? "946dab09aaea40e9e445b3d0399291bda69e17d1" : "012f94809c44f2d2c802b6dd9391734cf0c581ce";
                    break;
                case 'other':
                    dataClient = "0788ab3bd675d3a96b9939c1663ead373d221d67";
                    dataBotHash = $scope.partnerName != 'airbus' && $scope.containsHotel && $scope.siteType != 2 ? "07e40348aa2b43e2974c6b9ac6af279751620bdf" : "71c5bc8174775dac6828f5440a8bf241a8798a06";
                    break;
                case 'modifier':
                    dataClient = "0788ab3bd675d3a96b9939c1663ead373d221d67";
                    dataBotHash = $scope.partnerName != 'airbus' && $scope.containsHotel && $scope.siteType != 2 ? "b0f20bf3eb4ce5b1ac73fa5b6b0ba909daabb8cd" : "c752b51a3980e9353d094aebb262d3ff8fa6a4fc";
                    break;
                case 'luggage':
                    dataClient = "0788ab3bd675d3a96b9939c1663ead373d221d67";
                    dataBotHash = $scope.partnerName != 'airbus' && $scope.containsHotel && $scope.siteType != 2 ? "379f362d96633b39f6cd310194492b1f361dce26" : "379f362d96633b39f6cd310194492b1f361dce26";
                    break;
                case 'basket':
                    dataClient = "0788ab3bd675d3a96b9939c1663ead373d221d67";
                    dataBotHash = "927131523369181cdfc09957dc0727e99eb1eeca";
                    break;
                default:
                    dataClient = "0788ab3bd675d3a96b9939c1663ead373d221d67";
                    dataBotHash = $scope.partnerName != 'airbus' && $scope.containsHotel && $scope.siteType != 2 ? "07e40348aa2b43e2974c6b9ac6af279751620bdf" : "71c5bc8174775dac6828f5440a8bf241a8798a06";
            }
            $scope.broadcast_bot_reset();
            loadChatBotInMemory(dataClient, dataBotHash, btnId);

        };

        function toggleFreshbotLazyLoad(init, btnId) {
            console.info("lazy load is called");
            var btnBot = document.getElementById(btnId);
            if (btnBot) {
                if (init) {
                    btnBot.classList.add('__loading');
                } else {
                    btnBot.classList.remove('__loading');
                }
            }
        }

        function startInterceptor() {
            var minimize = document.querySelector('.pull-right.joe-icon');
            if (minimize) {
                minimize.addEventListener('click', function () {
                    setTimeout(function () { showInterceptor() }, 150)
                }, false);
            }
        }

        function showInterceptor() {
            var show = document.getElementById('mobile-chat-container');
            if (show) {
                show.addEventListener('click', function (event) {
                    event.stopPropagation();
                    Freshbots.showWidget(true)
                }, false);
            }
        }

        $scope.isFlexiesV4 = false;


        //Get booking details by bookingId
        //@desc this function gets booking details and prepares the information of alerts or warnings concerns to flight details, it also prepare branded fare information if there is in the booking
        $scope.getBookingDetails = function (bookingId) {
            $window.FLoading({ vAction: 'show' });
            apiClient.AIR.getBookingDetails([bookingId]).success(function (data) {
                $scope.bkgData = data;
                $scope.fillBookingDetails(data, bookingId);
                $window.FLoading({ vAction: 'hide' });
            });
        };
        //END Booking Details



        $scope.fillBookingDetails = function (data, bookingId) {
            
            if (data && data.status == 0) {
                setTimeout(function () {
                    $window.scrollTo(0, 0);
                }, 100);
                if (data.equipments.length == 1 && data.equipments[0].type == "TRN") $scope.isTrainOnly = true;
                else if (data.equipments.length > 1) {
                    var isTrainOnly = false;
                    for (var i = 0; i < data.equipments.length; i++) {
                        if (data.equipments[i].type == "TRN") isTrainOnly = true;
                        else if (data.equipments[i].type != "TRN") {
                            isTrainOnly = false;
                            break;
                        }
                    }
                    $scope.isTrainOnly = isTrainOnly;
                }
                $scope.showDetail = true;
                $scope.view = airHelper.getViewForBookingDetailsEntityVersion2(data);

                $scope.fillInsurances(bookingId, $scope.view)

                if ($window.site.partnerId == "servair") {

                    var firstPaymentSubvention = null;
                    $scope.view.payments.forEach(function (payment) {
                        var found = false;
                        if (payment.paymentMode == 0 && !found) {
                            firstPaymentSubvention = payment;
                            found = true;
                        }
                    });
                    //cumul de tout les montants de subventions
                    if (firstPaymentSubvention) {
                        firstPaymentSubvention.amount = $scope.view.payments.filter(function (x) { return x.paymentMode == 0; }).sum(function (x) { return x.amount });
                        $scope.view.payments = $scope.view.payments.filter(function (x) { return x.paymentMode != 0; });
                        $scope.view.payments.unshift(firstPaymentSubvention);
                    }


                    $window.Typescript.Servair.Services.ServairService.getReservationSubvention(bookingId).then(function (result) {
                        $scope.view.subventionData = JSON.parse(result.data);
                        $scope.modified = $scope.view.subventionData.some(function (x) { return x.modified });
                        $scope.$apply();
                    })

                } else {
                    $scope.view.subventionData = [];
                }


                $scope.view.invoices = [];
                apiClient.AIR.getInvoicesList(bookingId).success(function (data) {
                    $scope.view.invoices = data.grep(function (i) { return (i.number != null) });
                });
                apiClient.AIR.getETicketsList(bookingId).success(function (data) {
                    $scope.view.etickets = data;
                });
                $window.Typescript.ETickets.Services.ETicketsService.getEticketsTrain(bookingId, $scope.view.bookingNumber).then(function (axiosResponse) {
                    $scope.view.eticketstrain = axiosResponse.data;
                    $scope.$apply();
                }, function (err) {

                });
                $scope.$broadcast("onBookingSummaryUpdate");

                //Start here: CrossFaring
                $scope.isCrossFaring = $scope.view._innerData.bookings[0] && $scope.view._innerData.bookings[0].pnrs.length > 1;
                //End here

                //free flex
                var flexies = $scope.view._innerData.bookings[0].flexies[0];
                if (flexies) {
                    $scope.enableFreeFlex = (flexies.type === "FreeFlex" || flexies.type === 1);
                    $scope.totalFlexSelected = (flexies.type === "TotalFlex" || flexies.type === 2);
                    $scope.totalFlex75 = (flexies.type === "Flex75" || flexies.type === 3);
                    $scope.view.isFlexy = (flexies.type === "Flexy" || flexies.type === 0);
                    $scope.pricePerAdult = flexies.gross;
                    $scope.totalPrice = 0;

                    var f = $scope.view.fares.items.filter(function (f) { return f.type === 10; });
                    for (var j = 0; j < f.length; j++) {
                        $scope.totalPrice += f.amount * f.quantity;
                    }
                }

                try {
                    var flexies = $scope.view._innerData.bookings[0].flexies[0];
                    $scope.flexy = $scope.view._innerData.bookings[0].flexies[0];
                    if (parseInt(flexies.reference) >= 12) {
                        $scope.isFlexiesV4 = true;
                    }
                }
                catch (e) {

                }

                var fares = $scope.view.fares.items.filter(function (f) { return f.type === 41; });

                fares.each(function (fare) {
                    if ($scope.totalFlexSelected) {
                        fare.name = "Flex100";
                    }
                    else if ($scope.enableFreeFlex) {
                        fare.name = "Flex50";
                        fare.isFree = true;
                    }
                });

                var insurances = $scope.view.fares.items.filter(function (f) { return f.type === 40; });

                insurances.each(function (insurance) {
                    if (insurance.subtype === 34 && ($scope.totalFlexSelected || $scope.enableFreeFlex)) {
                        insurance.name = getT('airbooking.repatriationins');
                    }
                });



                //Insurances
                if ($scope.view.insurances) {
                    $scope.view.insurances.each(function (insurance) {
                        if (insurance.companyReference == 2 && insurance.insuranceReference == 2)
                            insurance.displayName = "Pack multirisques";
                        else
                            insurance.displayName = insurance.insuranceName;
                    });
                }

                if ($window.isAirbus) {
                    //Airbus additional datas
                    if ($scope.view.bookingAdditionalData) {
                        if ($scope.view.bookingAdditionalData["airbus.insurance"]) {
                            $scope.airbusInsurance = $scope.view.bookingAdditionalData["airbus.insurance"].value;
                        }

                        if ($scope.view.bookingAdditionalData["airbus.referrer"]) {
                            $scope.airbusReferrer = $scope.view.bookingAdditionalData["airbus.referrer"].value;
                        }
                    }
                }

                //Start here: branded fares
                $scope.hasBrandedFare = $scope.view &&
                    $scope.view._innerData.bookings[0] &&
                    $scope.view._innerData.bookings[0].pnrs &&
                    $scope.view._innerData.bookings[0].pnrs.any(function (item) {
                        if (item.brand && item.brand.brandServices && item.brand.brandServices.length > 0)
                            return true;
                    });

                if ($scope.hasBrandedFare) {
                    $scope.brand = $scope.view._innerData.bookings[0].pnrs[0].brand;
                    $scope.brand.displayName = $scope.brand.displayName.capitalize();
                    $scope.brand.includedServices = [];
                    $scope.brand.brandServices.each(function (bService) {
                        if (bService.pricing == 0) {
                            if (bService.description) {
                                bService.descriptionHtml = bService.description.replaceAll("\n", "<br/>");
                            }

                            bService.cssClass =
                                (bService.rficGroup + (bService.rficSubGroup ? "-" + bService.rficSubGroup : ""))
                                    .toLowerCase();
                            $scope.brand.includedServices.push(bService);
                        }
                    });
                    $scope.hasIncludedBaggage = $scope.view.itineraries[0] &&
                        $scope.view.itineraries[0].baggages[0]
                        && $scope.view.itineraries[0].baggages[0].quantity > 0;
                }

                if ($scope.view._innerData.bookings[0].accommodations != null && $scope.view._innerData.bookings[0].accommodations.any()) {
                    //nights number
                    var nights = moment($scope.view._innerData.bookings[0].accommodations[0].to).diff(moment($scope.view._innerData.bookings[0].accommodations[0].from), "days");
                    $scope.view._innerData.bookings[0].accommodations[0].nightNumber = nights;

                    $scope.containsHotel = true;

                    getBundleWidget("mrfly-widget-accommodation-info.js").done(function () {
                        mrflyWidgets['mrfly-widget-accommodation-info'].locale = $window.lng.substring(0, 2);
                        mrflyWidgets['mrfly-widget-accommodation-info'].resources = i18next.store.data.dev.translation;
                        mrflyWidgets['mrfly-widget-accommodation-info'].accommodation = $scope.view._innerData.bookings[0].accommodations[0];
                        mrflyWidgets['mrfly-widget-accommodation-info'].siteType = window.site.type;
                    })
                    .fail(function (jqxhr, settings, exception) {
                        console.log(exception);
                    });

                    getBundleWidget("mrfly-widget-accommodation-price-detail.js").done(function () {
                        mrflyWidgets['mrfly-widget-accommodation-price-detail'].resources = i18next.store.data.dev.translation;
                        mrflyWidgets['mrfly-widget-accommodation-price-detail'].booking = $scope.view._innerData.bookings[0];
                        mrflyWidgets['mrfly-widget-accommodation-price-detail'].siteType = window.site.type;

                    })
                    .fail(function (jqxhr, settings, exception) {
                        console.log(exception);
                    });
                    
                    getBundleWidget("mrfly-widget-basket-total-price.js").done(function () {

                        var booking = $scope.view._innerData.bookings[0];
                        var extraDetails = [];

                        var totalAmount = 0;
                        
                        for (var fare of booking.fares) { totalAmount += fare.amount * fare.quantity; }

                        var seats = booking.fares.filter(f => f.type == 20 && f.subtype == 24);
                        var totalSeatAmount = 0;
                        for (var fare of seats) { totalSeatAmount += fare.amount * fare.quantity; }

                        extraDetails.push({
                            description: getT('mrfly-widget-accommodation-price-detail.seatmapsimple'),
                            totalPrice: totalSeatAmount,
                            details: [],
                            type: 'SEAT',
                            order: 9
                        });

                        var inssurances = booking.fares.filter(f => f.type == 40 || f.type == 41);
                        var totalInssuranceAmount = 0;
                        for (var fare of inssurances) { totalInssuranceAmount += fare.amount * fare.quantity; }

                        extraDetails.push({
                            description: getT('widgets.common.insurance'),
                            totalPrice: totalInssuranceAmount,
                            details: [],
                            type: 'INS',
                            order: 10
                        });


                        var bags = booking.fares.filter(f => f.type == 20 && f.subtype == 20);
                        var totalbagAmount = 0;
                        for (var fare of bags) { totalbagAmount += fare.amount * fare.quantity; }

                        extraDetails.push({
                            description: getT('mrfly-widget-accommodation-price-detail.baggagesimple'),
                            totalPrice: totalbagAmount,
                            details: [],
                            type: 'BAG',
                            order: 8
                        });



                        var flightTickets = booking.fares.filter(f => f.type == 10);
                        var totalFlightTicketsAmount = 0;
                        for (var fare of flightTickets) {
                            totalFlightTicketsAmount += fare.amount * fare.quantity;

                        }


                        mrflyWidgets['mrfly-widget-basket-total-price'].price = totalAmount;
                        
                        var basketDetails = basketHelper.getPriceDetails(
                            $window.site.type,
                            {
                                totalAmount: { value: totalFlightTicketsAmount },
                                totalAgencyMargin: { value: 0 },
                            },
                            booking.accommodations,
                            {
                                value: 0,
                                margin: 0
                            },
                            extraDetails
                        );
                    
                        mrflyWidgets['mrfly-widget-basket-total-price'].basketDetails = basketDetails;
                    })
                    .fail(function (jqxhr, settings, exception) {
                        console.log(exception);
                    });
                    
                

                }
                if ($scope.view.insurances.length === 0 && //no insurance added
                    $scope.isAirbus !== true && //not avalailable for airbus
                    $scope.enablePostBookingInsurance && //xml config ok
                    $scope.view.request.multi !== true && //no multi destination
                    ($scope.view.status === 1 || $scope.view.status === 2)) {//booking status ok (don't show on payment refused)

                    $timeout(function () { //required for FF

                        getBundleWidget("mrfly-widget-insurance-reminder.js")
                            .done(function () {
                                mrflyWidgets['mrfly-widget-insurance-reminder'].$refs.reminder.bookingId = bookingId;
                                mrflyWidgets['mrfly-widget-insurance-reminder'].$refs.reminder.requestId = bookingId;
                                mrflyWidgets['mrfly-widget-insurance-reminder'].$refs.reminder.todayDeparture = moment().format("YYYY/MM/DD") === moment($scope.view.itineraries[0].departureDate).format("YYYY/MM/DD");
                                mrflyWidgets['mrfly-widget-insurance-reminder'].$refs.reminder.adtNumber = $scope.view.paxAmount[0];
                                mrflyWidgets['mrfly-widget-insurance-reminder'].$refs.reminder.cnnNumber = $scope.view.paxAmount[1];
                                mrflyWidgets['mrfly-widget-insurance-reminder'].$refs.reminder.infNumber = $scope.view.paxAmount[2];
                                if ($scope.agentId != null)
                                    mrflyWidgets['mrfly-widget-insurance-reminder'].$refs.reminder.agentId = $scope.agentId;
                                mrflyWidgets['mrfly-widget-insurance-reminder'].$refs.reminder.dataBind();
                            })
                            .fail(function (jqxhr, settings, exception) {
                                console.log(exception);
                            });

                    }, 300);
                }

                getBundleWidget("mrfly-widget-request-recap.js").done(function () {
                    console.log("Loading done");
                    //set params do mapping

                    var airSearchRequest = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequest']();

                    airSearchRequest.adultNumber = $scope.view.request.adults;
                    airSearchRequest.childNumber = $scope.view.request.childs;
                    airSearchRequest.infantNumber = $scope.view.request.infants;
                    airSearchRequest.isOneWay = $scope.view.request.oneWay;
                    airSearchRequest.isRoundTrip = $scope.view.request.roundTrip;
                    airSearchRequest.isOpenJaw = $scope.view.request.openJaw;
                    airSearchRequest.isMultiDestination = $scope.view.request.multi;

                    //Map itineraries
                    airSearchRequest.itineraries = [];
                    $scope.view.itineraries.forEach(function (itinerary) {
                        var ins = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequestItinerary']();

                        ins.date = itinerary.departureDate;

                        ins.departureAirportIata = itinerary.departure;
                        ins.departureCityIata = itinerary.origin;
                        ins.departureName = $scope.getCityNameOrIata(itinerary.departure);

                        ins.arrivalAirportIata = itinerary.arrival;
                        ins.arrivalCityIata = itinerary.destination;
                        ins.arrivalName = $scope.getCityNameOrIata(itinerary.arrival);

                        airSearchRequest.itineraries.push(ins);
                    });
                    mrflyWidgets['mrfly-widget-request-recap'].airSearchRequest = airSearchRequest;
                    mrflyWidgets['mrfly-widget-request-recap'].locale = $window.lng.substring(0, 2);
                    mrflyWidgets['mrfly-widget-request-recap'].language = $window.lng;
                    mrflyWidgets['mrfly-widget-request-recap'].resources = i18next.store.data.dev.translation;
                });

                //End here
                $window.FLoading({ vAction: 'hide' });

                //scroll to target
                $timeout(function () {
                    var hash = $window.document.location.hash;
                    if ($(hash).length > 0) {
                        $(hash)[0].scrollIntoView();
                    }
                }, 300);

                $scope.fillDataForTheBot(data);
                if (window.enableCO2 && !$scope.view.request.multi) {
                    $scope.enableCO2 = true;
                    $scope.retrieveCo2Data();
                }
            }




        };
        
        //END Booking Details

        $scope.getFormattedDate = function (date) {
            return moment(date).format('D MMMM YYYY');
        }

        //Invoice Dowload
        $scope.getInvoiceDownloadLink = function (invoiceId, forceDownload, bookingNumber) {
            return apiClient.AIR.getInvoiceDownloadLink(invoiceId, forceDownload, bookingNumber);
        };

        //E-ticket Dowload
        $scope.getETicketDownloadLink = function (invoiceId, forceDownload, bookingNumber) {
            return apiClient.AIR.getETicketDownloadLink(invoiceId, forceDownload, bookingNumber);
        };

        //************************** Receipt of Payment
        //Dìsplay Send Receipt Option
        $scope.sendReceiptShow = function (index) {
            var value = $("#i-payment-receipt-" + index).get(0);
            FPop({ oTarget: value, iPop: 'i-receipt-pop-' + index, vStyle: 'classic' });
        };

        //Send Payment Receipt by Email: This function call sendMail transaction to send mail type: 'ReceiptOfPayment'
        $scope.sendReceipt = function (payment, index) {
            if (XLFormValid({ iCont: 'i-form-receipt-' + index })) {
                var pId = payment.paymentId;
                var pspId = payment.bankCards[0].paymentPspId;
                var bId = $scope.view.bookingId;
                var bNum = $scope.view.bookingNumber;
                var to = $("#i-receipt-email-" + index).val();
                var mail = { bookingId: bId, bookingNumber: bNum, paymentId: pId, paymentPspId: pspId, addressTo: to, type: "ReceiptOfPayment" };

                var okMSG = function () { XLConfirm({ vTitle: "Email envoyé", vTextYes: 'OK', vHideNo: true }); };
                var failMSG = function () { XLConfirm({ vTitle: "Erreur dans l'envoi de l'email, veuillez recommencer", vTextYes: 'OK', vHideNo: true }); };

                apiClient.sendMail(mail).success(function (response) {
                    if (response) okMSG();
                    else failMSG();
                    $("#i-receipt-email-" + index).val('');
                }).error(function () { failMSG(); });


                FPopHide({ iPop: 'i-receipt-pop-' + index });
            }
        };
        //************************** End Receipt of Payment

        //************************** Send Eticket
        //Display Send Eticket  Option
        $scope.sendEticketShow = function () {
            var value = $("#i-eticket").get(0);
            FPop({ oTarget: value, iPop: 'i-eticket-pop', vStyle: 'classic' });
        };

        //Send Eticket by email: This function call sendMail transaction to send mail type: 'Eticket'
        $scope.sendEticket = function () {
            if (XLFormValid({ iCont: 'i-form-eticket' })) {
                var bId = $scope.view.bookingId;
                var bNum = $scope.view.bookingNumber;
                var to = $("#i-eticket-email").val();
                var mail = { bookingId: bId, bookingNumber: bNum, addressTo: to, type: "ETicket" };

                var okMSG = function () { XLConfirm({ vTitle: "Email envoyé", vTextYes: 'OK', vHideNo: true }); };
                var failMSG = function () { XLConfirm({ vTitle: "Erreur dans l'envoi de l'email, veuillez recommencer", vTextYes: 'OK', vHideNo: true }); };

                apiClient.sendMail(mail).success(function (response) {
                    if (response) okMSG();
                    else failMSG();
                    $("#i-eticket-email").val('');
                }).error(function () { failMSG(); });

                FPopHide({ iPop: 'i-eticket-pop' });
            }
        };
        //************************** End Send Eticket

        //This is only for debug
        $window.$scope = $scope;
        $window.$scope.apiClient = apiClient;
        $window.$scope.tseUtil = tseUtil;

        //Get Details:
        //@desc this function set a window.location.search param and call handleLoad function to get booking details
        $scope.GetDetails = function () {
            window.location.search = "reference=" + $scope.login.reference + "&email=" + $scope.login.email;
            handleLoad();

        };

        //OnLoad
        //@desc this function check if there the information neccesary (reference and email) in window.location.search to call getBookingId transaction and call getBookingDetails function
        var handleLoad = function () {
            $scope.detailError = false;
            var hashValue = window.location.search;
            console.log("handle Load:" + hashValue);
            $scope.checkBotStart();
            if (hashValue == "" || hashValue == "?" || tseUtil.Location.getQueryStringVar("product") != "" || hashValue.includes("forgottenbookingpopup")) {
                $scope.showDetail = false;
                //$window.sendAnalytics('/BookingDetails/Login', 'Booking view Login');
                //Tracking
                try {
                    //GTM
                    if (trackingService.isActivated()) {
                        trackingService.pushPageData('/BookingDetails/Login', 'Booking view Login', lng, false);
                    }
                }
                catch (ex) {
                    console.log(ex.message);
                    $window.trackingError = ex.message;
                }
                $window.sendInspectlet("pageUrl", $window.getUrl('BookingDetails/Login'));
            }
            else {
                var reference = tseUtil.Location.getQueryStringVar("reference");
                var email = tseUtil.Location.getQueryStringVar("email").replaceAll("!", ".");
                console.log(" data: reference and email:" + reference + " - " + email);

                $window.FLoading({ vAction: 'show' });
                var crypt = tseUtil.Location.getQueryStringVar("crypt");
                if (crypt == "") {
                    $scope.apiClient.AIR.getBookingId(reference, email).success(function (data) {
                        $window.FLoading({ vAction: 'hide' });
                        if (data) {
                            $scope.showDetail = null;
                            $scope.getBookingDetails(data);
                            //$window.sendAnalytics('/BookingDetails/View', 'Booking view');
                            //Tracking
                            try {
                                //GTM
                                if (trackingService.isActivated()) {
                                    trackingService.pushPageData('/Flightsearch/Bookingwait', 'Booking wait', lng, false);
                                }
                            }
                            catch (ex) {
                                console.log(ex.message);
                                $window.trackingError = ex.message;
                            }
                            $window.sendInspectlet("pageUrl", $window.getUrl('BookingDetails/View'));
                        }
                        else {
                            $scope.showDetail = false;
                            $scope.detailError = true;
                        }
                    }).error(function (data) {
                        console.log("Error");
                        console.log(data);
                        $scope.showDetail = false;
                        $scope.detailError = true;
                    });
                }
                else
                {
                    $.ajax({
                        url: 'page/AirBookingDetails/BookingDetailsFromCrypt',
                        type: 'get',
                        data: {
                            crypt: crypt,
                        },
                        success: function (data) {
                            $window.FLoading({ vAction: 'hide' });
                            if (data) {
                                $scope.showDetail = null;
                                console.log(data);
                                $scope.fillBookingDetails(data, data.bookings[0].bookingId);
                                //$window.sendAnalytics('/BookingDetails/View', 'Booking view');
                                //Tracking
                                try {
                                    //GTM
                                    if (trackingService.isActivated()) {
                                        trackingService.pushPageData('/Flightsearch/Bookingwait', 'Booking wait', lng, false);
                                    }
                                }
                                catch (ex) {
                                    console.log(ex.message);
                                    $window.trackingError = ex.message;
                                }
                                $window.sendInspectlet("pageUrl", $window.getUrl('BookingDetails/View'));
                            }
                            else {
                                $scope.showDetail = false;
                                $scope.detailError = true;
                            }
                        },
                        error: function (data) {
                            console.log("Error");
                            console.log(data);
                            $scope.showDetail = false;
                            $scope.detailError = true;
                        }
                    });
                }
            }
        };
        handleLoad();

        //GoToContactForm
        //@desc this function build the url to pass to contact form with the customer data
        $scope.goToContactForm = function (contactFormUrl) {
            if ($scope.view &&
                $scope.view.bookingNumber &&
                $scope.view._innerData &&
                $scope.view._innerData.bookings &&
                $scope.view._innerData.bookings[0] &&
                $scope.view._innerData.bookings[0].client &&
                $scope.view.itineraries &&
                $scope.view.itineraries[0]) {
                if ($scope.view._innerData.bookings[0].client.title && $scope.view._innerData.bookings[0].client.firstName && $scope.view._innerData.bookings[0].client.lastName && $scope.view._innerData.bookings[0].client.email) {
                    var finishUrl = "";
                    if (!contactFormUrl) {
                        if ($window.site.loginUrl.indexOf('?') > 0) {
                            contactFormUrl = $window.site.loginUrl.substring(0, $window.site.loginUrl.indexOf('?'));
                        } else {
                            contactFormUrl = $window.site.loginUrl;
                        }
                        var lastChar = contactFormUrl.substr(-1);
                        if (lastChar !== '/') {
                            contactFormUrl = contactFormUrl + '/';
                        }
                        contactFormUrl = contactFormUrl + "page/contactus/contactform";
                    }
                    finishUrl = 
                    finishUrl = contactFormUrl + (contactFormUrl.indexOf("?") > 0 ? '&' : '?') + "bkgN=" + $scope.view.bookingNumber + "&product=FLIGHT" + "&title=" + $scope.view._innerData.bookings[0].client.title + "&firstName=" + $scope.view._innerData.bookings[0].client.firstName + "&lastName=" + $scope.view._innerData.bookings[0].client.lastName + "&email=" + $scope.view._innerData.bookings[0].client.email + "&departureDate=" + $scope.view.itineraries[0].departureDate;
                    if ($scope.view._innerData.bookings[0].client.mobilePhone) {
                        finishUrl += "&phoneNumber=" + $scope.view._innerData.bookings[0].client.mobilePhone;
                    }
                    window.location.href = finishUrl;
                }
            }
        };

        $scope.getInsuranceName = function (insurance) {
            if (insurance.type === 'MultiRisk' ) {
                return $i18next.t('mrfly-widget-insurance-payment.subtitle');
            }
            else
                return insurance.displayName;
        };

        $scope.getInsurancePdf = function (insurance) {
            if ((insurance.companyReference === '00001' || insurance.companyReference === '00002') && insurance.reference === '00001') {
                return $window.getUrl('page/pdfviewer?pdfname=conditionsassuranceannulation');
            }
            else if ((insurance.companyReference === '00001' || insurance.companyReference === '00002') && insurance.reference === '00002') {
                return $window.getUrl('page/pdfviewer?pdfname=detailsgarantimultiinsurance');
            }
            else if ((insurance.companyReference === '00001' || insurance.companyReference === '00002') && insurance.reference === '00003') {
                return $window.getUrl('page/pdfviewer?pdfname=conditionsassurancerapatriement');
            }
            else if ((insurance.companyReference === '00001' || insurance.companyReference === '00002') && insurance.reference === '00005') {
                return $window.getUrl('page/pdfviewer?pdfname=conditionsassurancerapatriement_00005');
            }
            return null;
        };

        $scope.getInsuranceContractNumber = function (insurance) {
            return insurance.contractReference;
        };

        $scope.print = function () {
            window.print();
        };

        $scope.toggleDrawerResa = function () {
            $scope.drawerResaOpened = !$scope.drawerResaOpened;
            $scope.drawerDocumentsOpened = false;
        };

        $scope.closeDrawerResa = function () {
            if ($scope.drawerResaOpened) {
                $scope.drawerResaOpened = false;
            }
        };

        $scope.toggleDrawerDocuments = function () {
            $scope.drawerDocumentsOpened = !$scope.drawerDocumentsOpened;
            $scope.closeDocumentsTabs();
            $scope.drawerResaOpened = false;
        };

        $scope.closeDrawerDocuments = function () {
            if ($scope.drawerDocumentsOpened) {
                $scope.closeDocumentsTabs();
                $scope.drawerDocumentsOpened = false;
            }
        };

        $scope.closeDocumentsTabs = function () {
            $scope.showEticket = false;
            $scope.showBill = false;
            $scope.showVoucher = false;
            $scope.showCB = false;
        };

        var headerHeight = 0;
        var stickyScrollOffset = undefined;
        var isTablet = window.matchMedia('(min-width: 768px)');

        if (!isTablet.matches) {
            $window.addEventListener('scroll', stickyDrawerBarHandler, false);
        }

        $window.addEventListener('resize', function () {
            resetStickyDrawerBar();
            if (!isTablet.matches) {
                $window.addEventListener('scroll', stickyDrawerBarHandler, false);
            } else {
                $window.removeEventListener('scroll', stickyDrawerBarHandler, false);
            }
        });

        function stickyDrawerBarHandler(event) {
            var scrollTop = (event.target.documentElement.scrollTop !== 0) ? event.target.documentElement.scrollTop : event.target.body.scrollTop;
            var fixed = checkFixedHeader();
            var drawerBtn = document.getElementById('c-drawer-btn');
            var drawerBar = document.getElementsByClassName('c-drawer__bar').item(0);
            var tchatBot = document.getElementsByClassName('cl-chatbox-toggler').item(0);
            if (!stickyScrollOffset && drawerBtn) {
                var bodyScrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body).scrollTop;
                stickyScrollOffset = drawerBtn.getBoundingClientRect().top + drawerBtn.getBoundingClientRect().height + bodyScrollTop;
            }
            if (fixed && headerHeight === 0) {
                headerHeight = document.getElementsByTagName('header').item(0).offsetHeight;
                stickyScrollOffset -= headerHeight;
                stickyTransitionListener();
            }
            if (scrollTop && drawerBar) {
                if (scrollTop >= stickyScrollOffset) {
                    drawerBar.classList.add('--sticky');
                    tchatBot ? tchatBot.classList.add('--bottom') : undefined;
                }
                if (scrollTop < stickyScrollOffset) {
                    drawerBar.classList.remove('--sticky');
                    tchatBot ? tchatBot.classList.remove('--bottom') : undefined;
                }
            }
        }

        function checkFixedHeader() {
            var header = document.getElementsByTagName('header').item(0);
            if (header) {
                return window.getComputedStyle(header).position === 'fixed' || header.className.indexOf('__fixed') > -1;
            }
        }

        function stickyTransitionListener() {
            var header = document.getElementsByTagName('header').item(0);
            if (header) {
                header.addEventListener('transitionend', function () {
                    stickyScrollOffset = undefined;
                    headerHeight = 0;
                });
            }
        }

        function resetStickyDrawerBar() {
            stickyScrollOffset = undefined;
            headerHeight = 0;
            document.getElementsByClassName('c-drawer__bar').item(0) ? document.getElementsByClassName('c-drawer__bar').item(0).classList.remove('--sticky') : undefined;
            document.getElementsByClassName('cl-chatbox-toggler').item(0) ? document.getElementsByClassName('cl-chatbox-toggler').item(0).classList.remove('--bottom') : undefined;
        }
    }]);