webClient.factory('scopeParser', ['$window', function ($window) {

    function scopeParser() { }

    /* 
        Replace enum values by names: F => Première, C => Affaires, S => Economique Premium, Y => Economique
    */
    function getClass(value) {

        var classe = 'Economique';
        switch (value) {
            case 'F': classe = 'Première'; break;
            case 'C': classe = 'Affaires'; break;
            case 'S': classe = 'Economique Premium'; break;
            case 'Y': classe = 'Economique'; break;
        }
        return classe;
    }

    function formatDate(date, separator) {
        var dateString = date.dateString;
        if (separator == null) {
            return dateString.substring(6, 10) + dateString.substring(3, 5) + dateString.substring(0, 2);
        }
        else {
            return dateString.substring(6, 10) + separator + dateString.substring(3, 5) + separator + dateString.substring(0, 2);
        }
    }

    function getRequest() {
        if ($window.$scope.requestSummary)
            return $window.$scope.requestSummary;
        if ($window.$scope.bkgData.request)
            return $window.$scope.bkgData.request;
    }

    function getResponse() {

        if (typeof $window.$scope.bkgData === 'undefined') {
            return null;
        }
        else {
            return $window.$scope.bkgData.purchaseResponse;
        }
    }

    function isMulti() {
        var request = getRequest();
        return request.multiDestination;
    }

    function getBookingNumber() {
        return $window.$scope.bkgData.bookingResponse.bookingNumber;
    }

    function getPaxNumber() {
        var request = getRequest();
        return request.totalPaxes;
    }

    function getAdultNumber() {
        var request = getRequest();
        return request.adults;
    }

    function getChildNumber() {
        var request = getRequest();
        return request.childs;
    }

    function getInfantNumber() {
        var request = getRequest();
        return request.infants;
    }

    function getAmount() {
        var request = getRequest();

        return request.totalAmount.value;
    }

    function getFinalAmount() {
        return $window.$scope.bkgData.prixTotal.value;
    }

    function getTaxAmount() {

        var response = getResponse();
        var tax = 0;
        angular.forEach(response.proposalsRequested.fares, function (fare, key) {

            tax += fare.totalTax.value;
        });

        return tax;
    }

    function getFlightTimeInMinutes() {
        var response = getResponse();

        var totalFlightTime = 0;

        if (response != null) {
            angular.forEach(response.itineraries, function (itinerary, key) {
                angular.forEach(itinerary.segments, function (segment, key) {
                    totalFlightTime += segment.flightTime.value;
                });
            });
        }

        return totalFlightTime;
    }

    function getTravelClass() {
        var request = getRequest();
        return getClass(request.cabinClass);
    }

    function getAirportIATA(stop) {
        return stop.airportIATA == '' ? stop.cityIATA : stop.airportIATA;
    }

    function getItinerariesAsString() {
        var response = getResponse();

        var string = "";

        if (response != null) {
            angular.forEach(response.itineraries, function (itinerary, key) {
                string += getItineraryAsString(itinerary);
            });
        }

        return string;
    }

    function getItineraryAsString(itinerary) {
        var string = "";

        string += itinerary.origin.airportIata + "_";
        string += itinerary.destination.airportIata + "_";
        string += itinerary.marketingCarrier.iata + "_";
        string += itinerary.departureDateTime.substring(0, 10) + "_";

        return string;

    }

    function getOriginAirportIATA() {
        var request = getRequest();
        var origin = request.itineraries[0].origin;
        return getAirportIATA(origin);
    }

    function getPurchaseOriginAirportIATA() {
        var response = getResponse();
        return response.itineraries[0].origin.airportIata;
    }

    function getDestinationIATA() {
        var request = getRequest();
        var index = request.itineraries.length - 1;
        var destination = request.itineraries[index].origin;

        if (!isMulti()) {
            destination = request.itineraries[0].destination;
        }

        return getAirportIATA(destination);
    }

    function getPurchaseDestinationAirportIATA() {
        var response = getResponse();
        var index = response.itineraries.length - 1;
        var destination = response.itineraries[index].origin;

        if (!isMulti()) {
            destination = response.itineraries[0].destination;
        }

        return destination.airportIata;
    }

    function getOriginCityIATA() {
        var request = getRequest();
        var origin = request.itineraries[0].origin;
        return origin.cityIATA;
    }

    function getOriginCityName() {
        var request = getRequest();
        var origin = request.itineraries[0].origin;
        return origin.cityName;
    }

    function getDestinationCityName() {
        var request = getRequest();
        var index = request.itineraries.length - 1;
        var destination = request.itineraries[index].origin;

        if (!isMulti()) {
            destination = request.itineraries[0].destination;
        }

        return destination.cityName;
    }

    function getDestinationCityIATA() {
        var request = getRequest();
        var index = request.itineraries.length - 1;
        var destination = request.itineraries[index].origin;

        if (!isMulti()) {
            destination = request.itineraries[0].destination;
        }

        return destination.cityIATA;
    }

    function getOriginCountryISO() {
        var request = getRequest();
        var origin = request.itineraries[0].origin;
        return origin.countryISO;
    }

    function getDestinationCountryISO() {
        var request = getRequest();
        var index = request.itineraries.length - 1;
        var destination = request.itineraries[index].origin;

        if (!isMulti()) {
            destination = request.itineraries[0].destination;
        }

        return destination.countryISO;
    }

    function getOriginContinent() {
        var response = getResponse();
        var origin = response.itineraries[0].origin;
        return origin.continent;
    }

    function getDestinationContinent() {
        var response = getResponse();
        var index = response.itineraries.length - 1;
        var destination = response.itineraries[index].origin;

        if (!isMulti()) {
            destination = response.itineraries[0].destination;
        }

        console.log(destination);

        return destination.continent;
    }

    function getFirstCompanyIATA() {
        var response = getResponse();

        if (response == null) {
            return null;
        }
        else {
            return response.itineraries[0].marketingCarrier.iata;
        }
    }

    function getDepartingDate(separator) {
        var request = getRequest();
        return formatDate(request.itineraries[0].goDate, separator);
    }

    function getReturningDate(separator) {
        var request = getRequest();
        var index = request.itineraries.length - 1;
        if (index == 0)
            return '';
        return formatDate(request.itineraries[index].goDate, separator);
    }

    scopeParser.round = function (value, decimals) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }

    scopeParser.formatDate = function (date, separator) {

        return date.getDay() + separator + date.getMonth() + separator + date.getFullYear();
    }

    scopeParser.getCabinClass = function () {
        var request = getRequest();
        if (request.cabinClass) {
            return getClass(request.cabinClass);
        }
    }

    scopeParser.getFirstCompanyIATA = function () {
        return getFirstCompanyIATA();
    }

    scopeParser.getOriginIATA = function () {
        return getOriginAirportIATA();
    }

    scopeParser.getOriginAirportIATA = function () {
        return getOriginAirportIATA();
    }

    scopeParser.tryGetOriginIATA = function () {
        try {
            return getOriginAirportIATA();
        }
        catch (ex) {
            return null;
        }
    }

    scopeParser.getDestinationIATA = function () {
        return getDestinationIATA();
    }

    scopeParser.tryGetDestinationIATA = function () {
        try {
            return getDestinationIATA();
        }
        catch (ex) {
            return null;
        }
    }

    scopeParser.getOriginCityIATA = function () {
        return getOriginCityIATA();
    }

    scopeParser.getOriginCityName = function () {
        return getOriginCityName();
    }

    scopeParser.getDestinationCityIATA = function () {
        return getDestinationCityIATA();
    }

    scopeParser.getDestinationCityName = function () {
        return getDestinationCityName();
    }

    scopeParser.tryGetPurchaseOriginAirportIATA = function () {
        try {
            return getPurchaseOriginAirportIATA();
        }
        catch (ex) {
            return null;
        }
    }

    scopeParser.tryGetPurchaseDestinationAirportIATA = function () {
        try {
            return getPurchaseDestinationAirportIATA();
        }
        catch (ex) {
            return null;
        }
    }

    scopeParser.getOriginCountryISO = function () {
        return getOriginCountryISO();
    }

    scopeParser.getDestinationCountryISO = function () {
        return getDestinationCountryISO();
    }

    scopeParser.getOriginContinent = function () {
        return getOriginContinent();
    }

    scopeParser.tryGetOriginContinent = function () {
        try {
            return getOriginContinent();
        }
        catch (ex) {
            return null;
        }
    }

    scopeParser.getDestinationContinent = function () {
        return getDestinationContinent();
    }

    scopeParser.tryGetDestinationContinent = function () {
        try {
            return getDestinationContinent();
        }
        catch (ex) {
            return null;
        }
    }

    scopeParser.getDepartingDate = function () {
        return getDepartingDate(null);
    }

    scopeParser.getDepartingDateWithDash = function () {
        return getDepartingDate("-");
    }

    scopeParser.getReturningDate = function () {
        return getReturningDate();
    }

    scopeParser.getReturningDateWithDash = function () {
        return getReturningDate("-");
    }

    scopeParser.getTravelClass = function () {
        return getTravelClass();
    }

    scopeParser.getPaxNumber = function () {
        return getPaxNumber();
    }

    scopeParser.tryGetPaxNumber = function () {
        try {
            return getPaxNumber();
        }
        catch (ex) {
            return null;
        }
    }

    scopeParser.getAdultNumber = function () {
        return getAdultNumber();
    }

    scopeParser.tryGetAdultNumber = function () {
        try {
            return getAdultNumber();
        }
        catch (ex) {
            return null;
        }
    }

    scopeParser.getChildNumber = function () {
        return getChildNumber();
    }

    scopeParser.getInfantNumber = function () {
        return getInfantNumber();
    }

    scopeParser.getPrice = function () {
        var raw = getAmount() / getPaxNumber();
        return Math.round(raw * 100) / 100; //trick to round to 2 dec
    }

    scopeParser.tryGetPrice = function () {
        try {
            var raw = getAmount() / getPaxNumber();
            return Math.round(raw * 100) / 100; //trick to round to 2 dec
        }
        catch (ex) {
            return null;
        }
    }

    scopeParser.getAmount = function () {
        return getAmount();
    }

    scopeParser.tryGetAmount = function () {
        try {
            return getAmount();
        }
        catch (ex) {
            return null;
        }

    }

    scopeParser.tryGetFinalAmount = function () {
        try {
            return getFinalAmount();
        }
        catch (ex) {
            return null;
        }
    }

    scopeParser.tryGetTaxAmount = function () {
        try {
            return getTaxAmount();
        }
        catch (ex) {
            return null;
        }
    }

    scopeParser.tryGetBookingNumber = function () {
        try {
            return getBookingNumber();
        }
        catch (ex) {
            return null;
        }
    }

    scopeParser.getItinerariesAsString = function () {
        return getItinerariesAsString();
    }

    scopeParser.getFlightTimeInHours = function () {
        return getFlightTimeInMinutes() / 60;
    }

    scopeParser.tryRound = function (value) {
        try {
            return Math.round(value * 100) / 100;
        }
        catch (ex) {
            return null;
        }

    }

    scopeParser.retrieveInsuranceSelected = function () {
        if ($scope.bkgData.insuranceOnce == 1 && window.sessionStorage.getItem("inssurance.repatriation.selected") == "true") {
            if (Object.keys($scope.bkgData.insurancesSelected.isAnnul).length > 0) {
                return 'ANNULATION';
            }
            if (Object.keys($scope.bkgData.insurancesSelected.isAnnulRepatriament).length > 0) {
                return 'ANNULATION_REPATRIAMENT';
            }
            if (Object.keys($scope.bkgData.insurancesSelected.isRepatriament).length > 0) {
                return 'REPATRIAMENT';
            }
        }
        return 'NONE';
    }

    scopeParser.retrieveLuggagesSelected = function () {
        var luggages = [];
        $scope.bkgData.purchaseResponse.itineraries.forEach(function (itin) {
            var listLuggages = [];
            $scope.bkgData.bookingRequest.passengers.forEach(function (passenger) {
                if (passenger.baggageByItinerary.hasOwnProperty(itin.key) && passenger.baggageByItinerary[itin.key].quantity > 0) {
                    listLuggages.push(passenger.baggageByItinerary[itin.key]);
                }
            });
            var additionalLuggages = {
                'itineraryKey': itin.key,
                'luggages': listLuggages
            };
            luggages.push(additionalLuggages);
        });
        return luggages;
    }
    scopeParser.retrieveLuggagesSelectedNumber = function () {
        var ret = 0;
        $scope.bkgData.purchaseResponse.itineraries.forEach(function (itin) {
            
            $scope.bkgData.bookingRequest.passengers.forEach(function (passenger) {
                if (passenger.baggageByItinerary.hasOwnProperty(itin.key) && passenger.baggageByItinerary[itin.key].quantity > 0) {
                    ret += passenger.baggageByItinerary[itin.key].quantity;
                }
            });
        });
        return ret;
    }

    return scopeParser;

}
]);

