webClient.controller('B2B.accountProfileController', ['$scope', '$window', '$timeout', '$i18next', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper',
    function ($scope, $window, $timeout, $i18next, tseApiClient, tseUtil, airHelper) {
        $scope.getText = function (key) { return $window.culture.texts[key]; };
        //Set the current login information
        $scope.agency = null;
        $scope.loginInfo = $window.site.loginInfo;
        $scope.clientId = $scope.loginInfo.Client.AccountNumber;
        $scope.loginId = $scope.loginInfo.LoginId;
        $scope.showDisabledAgents = false;
        $scope.enableHotelMargin = $window.enableHotelMargin;
        $scope.hotelMargin = {};
        $scope.dpkMargin = {};

        //TOREVIEW
        $scope.enableDpkMargin = $window.enableDpkMargin = $window.enableHotelMargin;
        if ($scope.loginInfo) {
            $scope.loginInfo.ProductDpk = true;
        }

        //RestApi client object
        var apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
        //apiClient.enableDebug();    //Only for debugging....


        //Load Agency Information
        $scope.loadAgencyInfo = function() {
            $window.FLoading({ vAction: 'show' });
            apiClient.B2B.getAccountInfo().success(function(data) {
                $scope.agents = data.agency.agents;
                $scope.agents.each(function(agent) {
                    switch (agent.title) {
                    case 1:
                        agent.titleName = "Mr";
                        break;
                    case 2:
                        agent.titleName = "Mme";
                        break;
                    }
                });
                $scope.enabledAgents = $scope.agents.grep(function(a) { return a.enabled; });
                $scope.disabledAgents = $scope.agents.grep(function(a) { return !a.enabled; });

                $scope.responsible = data.agency.responsible;
                switch ($scope.responsible.title) {
                case 1:
                    $scope.responsible.titleName = "Mr";
                    break;
                case 2:
                    $scope.responsible.titleName = "Mme";
                    break;
                }
                $scope.agency = data.agency;
                $scope.request = data;
                $scope.showDisabledAgents = false;
                $window.FLoading({ vAction: 'hide' });
                $scope.getClientMargin($scope.agency.airMatrixMargins);
                $scope.getHotelMargin();
                $scope.getDpkMargin();

                getBundleWidget("mrfly-widget-margin-block.js").done(function() {
                    if (containsCar) {
                        mrflyWidgets["carMarginWidget"].$on('registerMargin',
                            function(marginValue) {

                                $scope.RegisterNewCarMargin(marginValue);
                            });
                        $scope.getCarMargin();
                    }
                    if (containsTransfert) {
                        mrflyWidgets["transfertMarginWidget"].$on('registerMargin',
                            function(marginValue) {

                                $scope.RegisterNewTransfertMargin(marginValue);
                            });
                        $scope.getTransfertMargin();
                    }
                    if (containsLocation) {
                        mrflyWidgets["locationMarginWidget"].$on('registerMargin',
                            function(marginValue) {

                                $scope.RegisterNewLocationMargin(marginValue);
                            });
                        $scope.getLocationMargin();
                    }
                });

            });
        
    };

        //Get Client Margin (type 1 = Client, 0 = Network)
        $scope.getClientMargin = function (type) {
            if (type == 1) {
                $window.FLoading({ vAction: 'show' });
                apiClient.B2B.getClientMargin().success(function (data) {
                    $scope.margins = data;
                    $window.FLoading({ vAction: 'hide' });
                });
            }
            if (type == 0) {
                var obj = { enterpriseId: $scope.agency.enterpriseId, networkCodeId: $scope.agency.networkCode };
                $window.FLoading({ vAction: 'show' });
                apiClient.B2B.getNetWorkMargin(obj).success(function (data) {
                    $scope.margins = data;
                    $window.FLoading({ vAction: 'hide' });
                });
            }
        };
        $scope.loadAgencyInfo();

        $scope.fsmoothopen = function (_oOpener, _iSmooth, vClassPP) {
            if (_oOpener.target)
                _oOpener = _oOpener.target;
            var obj = { oOpener: _oOpener, iSmooth: _iSmooth };
            if (vClassPP) obj.vClassPP = vClassPP;
            $window.FSmoothOpen(obj);
            $window.XLFormInit({ iCont: _iSmooth });
        };
        $scope.callDrop = function (_event, type, index) {
            var name = 'i-' + type + '-' + index;
            var value = $("#ul-" + type + "-" + index).get(0);
            $window.FSelect({ e: _event, myObj: value, vName: name });
        };
        $scope.callTitlePop = function (_event, type, index) {
            var value = _event.target;
            var name = 'i-' + type + '-' + index + '-pop';
            $window.FPop({ oTarget: value, iPop: name, vStyle: 'classic' });
        };
        $scope.createUpdateAgent = function (person) {
            if (person) {
                delete person.u;
                person.u = $window.objectClone(person);
            }
        };
        $scope.saveAgent = function (person, cont, _event) {
            if (person && $window.XLFormValid({ iCont: cont })) {
                if (person.u) {
                    var nPerson = person.u;
                    if (nPerson.titleName == "Mr") nPerson.title = 1;
                    if (nPerson.titleName == "Mme") nPerson.title = 2;
                    delete person.u;
                    $window.FLoading({ vAction: 'show' });
                    apiClient.B2B.updateAgent(nPerson).success(function (response) {
                        $window.FLoading({ vAction: 'hide' });
                        if (response.transactionStatus == 0) {
                            //Update Successful
                            if (response.agent.title == 1) response.agent.titleName = "Mr";
                            if (response.agent.title == 2) response.agent.titleName = "Mme";
                            for (p in response.agent)
                                person[p] = response.agent[p];
                            $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.saved"), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                        }
                        else {
                            $window.XLConfirm({
                                vTitle: $i18next.t("b2baccountagence.erroronsave") + " ({0})".format(response.transactionStatus.padLeft("00")),
                                vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true
                            });
                        }
                    }).error(function () {
                        $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.emailalreadyexists"), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                    });
                }
                else if (person.n) {
                    delete person.n;
                    if (person.titleName == "Mr") person.title = 1;
                    if (person.titleName == "Mme") person.title = 2;

                    person.partnerId = $window.site.partnerId;
                    person.baseUrl = $window.site.passResetUrl;
                    person.queryString = "n=1";
                    person.cultureInfo = $window.site.culture;

                    $window.FLoading({ vAction: 'show' });
                    apiClient.B2B.createAgent(person).success(function (response) {
                        $window.FLoading({ vAction: 'hide' });
                        if (response.transactionStatus == 0) {
                            //New Successful
                            if (response.agent.title == 1) response.agent.titleName = "Mr";
                            if (response.agent.title == 2) response.agent.titleName = "Mme";
                            $scope.agents.push(response.agent);
                            $scope.enabledAgents.push(response.agent);
                            $scope.createAgentObject();
                            $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.agentcreated"), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                        }
                        else if (response.transactionStatus == 4) {
                            $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.agentalreadyexists"), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                        }
                        else if (response.transactionStatus == 5) {
                            //Agent exist and is deactivated
                            person = response.agent;
                            $window.XLConfirm({
                                vTitle: $i18next.t('b2baccountagence.reactivateagent'), vTextNo: $i18next.t("b2baccountagence.no"), vTextYes: $i18next.t("b2baccountagence.yes"),
                                fCallBackYes: function () {
                                    $scope.$apply(function () {
                                        apiClient.B2B.enableAgent(person).success(function (enableResponse) {
                                            if (enableResponse.transactionStatus == 0) {
                                                $scope.loadAgencyInfo();
                                                $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.agentreactivated"), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                                            }
                                            else {
                                                $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.erroronsave") + " ({0})".format(enableResponse.transactionStatus.padLeft("00")), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                                            }
                                        }).error(function () {
                                            $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.erroronsave"), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                                        });
                                    });
                                }
                            });
                        }
                        else {
                            $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.erroronsave") + " ({0})".format(response.transactionStatus.padLeft("00")), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                        }
                    }).error(function () {
                        $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.erroronsave"), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                    });
                }
                $scope.fsmoothopen(_event, cont);
            }
        };
        $scope.removeAgent = function (index, _event) {
            if (index > -1 && $scope.enabledAgents.length > index) {
                $window.XLConfirm({
                    vTitle: $i18next.t('b2baccountagence.desactivateagent3'),
                    vText: $i18next.t('b2baccountagence.desactivateagent2'),
                    vTextNo: $i18next.t("b2baccountagence.no"), vTextYes: $i18next.t("b2baccountagence.yes"),
                    fCallBackYes: function () {
                        $scope.$apply(function () {
                            $window.FLoading({ vAction: 'show' });
                            apiClient.B2B.disableAgent($scope.enabledAgents[index]).success(function (disableResponse) {
                                $window.FLoading({ vAction: 'hide' });
                                if (disableResponse.transactionStatus == 0) {
                                    $scope.loadAgencyInfo();
                                    $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.agentdesactivated"), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                                }
                                else {
                                    $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.errorondelete") + " ({0})".format(disableResponse.transactionStatus.padLeft("00")), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                                }
                            }).error(function () {
                                $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.errorondelete"), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                            });
                        });
                    }
                });
            }
        };
        $scope.reactivateAgent = function (person) {
            $window.FLoading({ vAction: 'show' });
            apiClient.B2B.enableAgent(person).success(function (enableResponse) {
                $window.FLoading({ vAction: 'hide' });
                if (enableResponse.transactionStatus == 0) {
                    $scope.loadAgencyInfo();
                    $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.agentreactivated"), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                }
                else {
                    $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.erroronsave") + " ({0})".format(enableResponse.transactionStatus.padLeft("00")), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                }
            }).error(function () {
                $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.erroronsave"), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
            });
        };
        $scope.newAgent = { n: true };
        $scope.createAgentObject = function () {
            $scope.newAgent = { n: true };
        };
        $scope.toggleDisabledAgents = function () {
            $scope.showDisabledAgents = !$scope.showDisabledAgents;
        };

        $scope.newMargin = { n: true };
        $scope.isCreateMargin = function () {
            $("#ajouter-modifier").html($i18next.t("b2baccountagence.addrange"));
            if ($scope.indexMargin != -2 && $scope.indexMargin < 0)
                $scope.indexMargin = -2;
            else
                $scope.indexMargin = -1;

            $scope.newMargin.u = false;
            $scope.newMargin.n = true;
            $scope.newMargin.ToRange = "";
            $scope.newMargin.Margin = "";
            $scope.newMargin.Method = 0;
            var varBtn = $("#btnAjouterPalier")[0];
            $window.FSmoothOpen({ oOpener: varBtn, iSmooth: 'i-smooth-com' });
        };
        $scope.indexMargin = -1;

        //New Client Margin (n =  new, u = update)
        $scope.newClientMargin = function (newMargin, com) {
            if (newMargin.n && $window.XLFormValid({ iCont: com })) {
                if (newMargin.ToRange != "" && newMargin.ToRange != " " && newMargin.Margin != "" && newMargin.Margin != " ") {
                    $scope.apiClient.B2B.addClientMargin(newMargin).success(function (response) {
                        if (response) {
                            $scope.getClientMargin($scope.agency.airMatrixMargins);
                            newMargin.ToRange = "";
                            newMargin.Margin = "";
                            newMargin.Method = 0;
                            $window.XLConfirm({
                                vTitle: $i18next.t("b2baccountagence.rangeadded"),
                                vTextYes: $i18next.t("b2baccountagence.okthanks"),
                                vHideNo: true
                            });
                            var varBtn = $("#btnAjouterPalier")[0];
                            $window.FSmoothOpen({ oOpener: varBtn, iSmooth: com });
                            $scope.indexMargin = -1;
                        }
                        else {
                            $window.XLConfirm({
                                vTitle: $i18next.t("b2baccountagence.erroronsave"),
                                vTextYes: $i18next.t("b2baccountagence.okthanks"),
                                vHideNo: true
                            });
                        }

                    }).error(function () {
                        $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.rangealreadyexists"), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                    });
                } else {
                    $window.FLoading({ vAction: 'hide' });
                }
            }
            else if (newMargin.u) {
                $scope.apiClient.B2B.updateClientMargin(newMargin).success(function (response) {
                    if (response) {
                        $scope.getClientMargin($scope.agency.airMatrixMargins);
                        newMargin.ToRange = "";
                        newMargin.Margin = "";
                        newMargin.Method = 0;
                        newMargin.u = false;
                        $window.XLConfirm({
                            vTitle: $i18next.t('b2baccountagence.rangeupdated'),
                            vTextYes: $i18next.t("b2baccountagence.okthanks"),
                            vHideNo: true
                        });
                        $scope.getClientMargin($scope.agency.airMatrixMargins);
                        var varBtn = $("#btnAjouterPalier")[0];
                        $window.FSmoothOpen({ oOpener: varBtn, iSmooth: com });
                        $scope.indexMargin = -1;
                    }
                    else {
                        $window.XLConfirm({
                            vTitle: $i18next.t("b2baccountagence.erroronsave"),
                            vTextYes: $i18next.t("b2baccountagence.okthanks"),
                            vHideNo: true
                        });
                    }
                });
            }
        };

        //Delete Client Margin
        $scope.deleteClientMargin = function (toRange) {
            if ($scope.indexMargin > -1) {
                var varBtn = $("#btnAjouterPalier")[0];
                $window.FSmoothOpen({ oOpener: varBtn, iSmooth: 'i-smooth-com' });
                $scope.indexMargin = -1;
            }
            $window.XLConfirm({
                vTitle: 'Etes-vous certain de vouloir supprimer ce palier ?',
                vTextNo: $i18next.t("b2baccountagence.no"),
                vTextYes: $i18next.t("b2baccountagence.yes"),
                fCallBackYes: function () {
                    $scope.$apply(function () {
                        $window.FLoading({ vAction: 'show' });
                        $scope.apiClient.B2B.deleteClientMargin(toRange).success(function (response) {
                            $window.FLoading({ vAction: 'hide' });
                            if (response) {
                                console.log("Response es true");
                                $window.XLConfirm({
                                    vTitle: $i18next.t('b2baccountagence.rangedeleted'),
                                    vTextYes: $i18next.t("b2baccountagence.okthanks"),
                                    vHideNo: true
                                });
                                $scope.getClientMargin($scope.agency.airMatrixMargins);
                            }
                            else {
                                $window.XLConfirm({
                                    vTitle: $i18next.t("b2baccountagence.erroronsave"),
                                    vTextYes: $i18next.t("b2baccountagence.okthanks"),
                                    vHideNo: true
                                });
                            }
                            console.log(response);
                        });
                    });
                }
            });
        };

        //Update Client Margin
        $scope.updateClientMargin = function (index) {
            $("#ajouter-modifier").html($i18next.t("b2baccountagence.updatrange"));
            console.log($scope.indexMargin);
            if ($scope.indexMargin != index) {
                $scope.newMargin.ToRange = $scope.margins[index].toRange + "";
                $scope.newMargin.Margin = $scope.margins[index].margin;
                $scope.newMargin.Method = $scope.margins[index].method;
                $scope.newMargin.n = false;
                $scope.newMargin.u = true;
                if ($scope.indexMargin == -1) {
                    var varBtn = $("#btnAjouterPalier")[0];
                    $window.FSmoothOpen({ oOpener: varBtn, iSmooth: 'i-smooth-com' });
                }
                $scope.indexMargin = index;
            }
            else {
                var varBtn = $("#btnAjouterPalier")[0];
                $window.FSmoothOpen({ oOpener: varBtn, iSmooth: 'i-smooth-com' });
                $scope.indexMargin = -1;
            }
            //XLScrollTo({iObj:'i-palier-scroll'}) ;
        };


        $scope.checkMarginPercent = function (elem, e) {
            if ($scope.newMargin.Method == 1) {
                if (e.srcElement.value > 100)
                    $scope.newMargin.Margin = 100;
            }
        };

        //Register Hotel Margin
        $scope.RegisterNewHotelMargin = function (hotelMargin) {
            //Si cumple las condiciones para poder modificar el margen de hotel
            if ($scope.loginInfo.Agent.Responsible && $scope.loginInfo.Agent.AuthorizedChangeMatrixMargins) {
                if (hotelMargin && hotelMargin.MarginHotelPercent) {
                    newHotelMargin = { value: hotelMargin.MarginHotelPercent + "" };
                    if (newHotelMargin.value.indexOf(',') != -1)
                        newHotelMargin.value = newHotelMargin.value.replace(',', '.');
                    $window.FLoading({ vAction: 'show' });
                    apiClient.B2B.updateClientMarginHotel(newHotelMargin).success(function (response) {
                        $window.FLoading({ vAction: 'hide' });
                        if (response) {
                            //Get hotel margin to refresh the in the view
                            $scope.getHotelMargin();
                            $window.XLConfirm({
                                vTitle: $i18next.t('b2baccountagence.marginupdated'),
                                vTextYes: $i18next.t("b2baccountagence.okthanks"),
                                vHideNo: true
                            });
                        }
                        else {
                            $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.erroronsave"), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                            $scope.getHotelMargin();
                        }
                    }).error(function () {
                        $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.erroronsave"), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                        $window.FLoading({ vAction: 'hide' });
                        $scope.getHotelMargin();
                    });
                }
            }
        };

        //Register Dp Margin
        $scope.RegisterNewDpkMargin = function (dpkMargin) {
            //Si cumple las condiciones para poder modificar el margen de hotel
            if ($scope.loginInfo.Agent.Responsible && $scope.loginInfo.Agent.AuthorizedChangeMatrixMargins) {
                if (dpkMargin && dpkMargin.MarginDpkPercent) {
                    newDpkMargin = { value: dpkMargin.MarginDpkPercent + "" };
                    if (newDpkMargin.value.indexOf(',') != -1)
                        newDpkMargin.value = newDpkMargin.value.replace(',', '.');
                    //newDpkMargin.value = parseFloat(newDpkMargin.value);
                    $window.FLoading({ vAction: 'show' });
                    apiClient.B2B.updateClientMarginDpk(newDpkMargin).success(function (response) {
                        $window.FLoading({ vAction: 'hide' });
                        if (response) {
                            //Get Dp margin to refresh the in the view
                            $scope.getDpkMargin();
                            $window.XLConfirm({
                                vTitle: $i18next.t('b2baccountagence.marginupdated'),
                                vTextYes: $i18next.t("b2baccountagence.okthanks"),
                                vHideNo: true
                            });
                        }
                        else {
                            $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.erroronsave"), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                            $scope.getDpkMargin();
                        }
                    }).error(function () {
                        $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.erroronsave"), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                        $window.FLoading({ vAction: 'hide' });
                        $scope.getDpkMargin();
                    });
                }
            }
        };
        $scope.RegisterNewCarMargin = function (carMargin) {

            if ($scope.loginInfo.Agent.Responsible && $scope.loginInfo.Agent.AuthorizedChangeMatrixMargins) {
                if (carMargin) {
                    newCarMargin = { value: carMargin + "" };
                    if (newCarMargin.value.indexOf(',') != -1)
                        newCarMargin.value = newCarMargin.value.replace(',', '.');
                    $window.FLoading({ vAction: 'show' });
                    apiClient.B2B.updateClientMarginCar(newCarMargin).success(function (response) {
                        $window.FLoading({ vAction: 'hide' });
                        if (response) {
                            //Get hotel margin to refresh the in the view
                            $scope.getCarMargin();
                            $window.XLConfirm({
                                vTitle: $i18next.t('b2baccountagence.marginupdated'),
                                vTextYes: $i18next.t("b2baccountagence.okthanks"),
                                vHideNo: true
                            });
                        }
                        else {
                            $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.erroronsave"), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                            $scope.getCarMargin();
                        }
                    }).error(function () {
                        $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.erroronsave"), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                        $window.FLoading({ vAction: 'hide' });
                        $scope.getCarMargin();
                    });
                }
            }
        };

        $scope.RegisterNewTransfertMargin = function (transfertMargin) {
            if ($scope.loginInfo.Agent.Responsible && $scope.loginInfo.Agent.AuthorizedChangeMatrixMargins) {
                if (transfertMargin) {
                    newTransfertMargin = { value: transfertMargin + "" };
                    if (newTransfertMargin.value.indexOf(',') != -1)
                        newTransfertMargin.value = newCarMargin.value.replace(',', '.');
                    $window.FLoading({ vAction: 'show' });
                    apiClient.B2B.updateClientMarginTransfert(newTransfertMargin).success(function (response) {
                        $window.FLoading({ vAction: 'hide' });
                        if (response) {
                            //Get hotel margin to refresh the in the view
                            $scope.getTransfertMargin();
                            $window.XLConfirm({
                                vTitle: $i18next.t('b2baccountagence.marginupdated'),
                                vTextYes: $i18next.t("b2baccountagence.okthanks"),
                                vHideNo: true
                            });
                        }
                        else {
                            $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.erroronsave"), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                            $scope.getTransfertMargin();
                        }
                    }).error(function () {
                        $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.erroronsave"), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                        $window.FLoading({ vAction: 'hide' });
                        $scope.getTransfertMargin();
                    });
                }
            }
        };

        $scope.RegisterNewLocationMargin = function (locationMargin) {
            if ($scope.loginInfo.Agent.Responsible && $scope.loginInfo.Agent.AuthorizedChangeMatrixMargins) {
                if (locationMargin) {
                    var newLocationMargin = { value: locationMargin + "" };
                    if (newLocationMargin.value.indexOf(',') !== -1)
                        newLocationMargin.value = newLocationMargin.value.replace(',', '.');
                    $window.FLoading({ vAction: 'show' });
                    apiClient.B2B.updateClientMarginLocation(newLocationMargin).success(function (response) {
                        $window.FLoading({ vAction: 'hide' });
                        if (response) {
                            //Get locartion margin to refresh the in the view
                            $scope.getLocationMargin();
                            $window.XLConfirm({
                                vTitle: $i18next.t('b2baccountagence.marginupdated'),
                                vTextYes: $i18next.t("b2baccountagence.okthanks"),
                                vHideNo: true
                            });
                        }
                        else {
                            $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.erroronsave"), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                            $scope.getLocationMargin();
                        }
                    }).error(function () {
                        $window.XLConfirm({ vTitle: $i18next.t("b2baccountagence.erroronsave"), vTextYes: $i18next.t("b2baccountagence.ok"), vHideNo: true });
                        $window.FLoading({ vAction: 'hide' });
                        $scope.getLocationMargin();
                    });
                }
            }
        };

        //Comprobacion de que margen no sea mayor a 100
        $scope.checkHotelMarginPercent = function (elem, e) {
            if (e && e.srcElement && e.srcElement.value > 100 && $scope.hotelMargin && $scope.hotelMargin.MarginHotelPercent)
                $scope.hotelMargin.MarginHotelPercent = 100;
            if (e && e.srcElement && e.srcElement.value < 0 && $scope.hotelMargin && $scope.hotelMargin.MarginHotelPercent)
                $scope.hotelMargin.MarginHotelPercent = 0;
        };

        //Comprobacion de que margen no sea mayor a 100
        $scope.checkDpkMarginPercent = function (elem, e) {
            if (e && e.srcElement && e.srcElement.value > 100 && $scope.dpkMargin && $scope.dpkMargin.MarginDpkPercent)
                $scope.dpkMargin.MarginDpkPercent = 100;
            if (e && e.srcElement && e.srcElement.value < 0 && $scope.dpkMargin && $scope.dpkMargin.MarginDpkPercent)
                $scope.dpkMargin.MarginDpkPercent = 0;
        };

        //Get Dp Margin
        $scope.getDpkMargin = function () {
            debugger;
            setTimeout(function () {
                if ($scope.enableDpkMargin && $scope.loginInfo && $scope.loginInfo.ProductDpk) {
                    $window.FLoading({ vAction: 'show' });
                    apiClient.B2B.getClientMarginDpk().success(function (data) {
                        if (data) {
                            $scope.dpkMargin.MarginDpkPercent = data.value;
                        }
                        $window.FLoading({ vAction: 'hide' });
                    });
                }
            }, 2000);
        };

        //Get Hotel Margin
        $scope.getHotelMargin = function () {
            if ($scope.enableHotelMargin && $scope.loginInfo && $scope.loginInfo.ProductHotel) {
                $window.FLoading({ vAction: 'show' });
                apiClient.B2B.getClientMarginHotel().success(function (data) {
                    if (data) {
                        $scope.hotelMargin.MarginHotelPercent = data.value;
                    }
                    $window.FLoading({ vAction: 'hide' });
                });
            }
        };

        $scope.getCarMargin = function () {

            if ($window.containsCar) {
                $window.FLoading({ vAction: 'show' });
                apiClient.B2B.getClientMarginCar().success(function (data) {
                    if (data) {
                        mrflyWidgets["carMarginWidget"].$refs.instance.marginValue = data.value;
                    }
                    $window.FLoading({ vAction: 'hide' });
                });
            }
        };

        $scope.getTransfertMargin = function () {

            if ($window.containsTransfert) {
                $window.FLoading({ vAction: 'show' });
                apiClient.B2B.getClientMarginTransfert().success(function (data) {
                    if (data) {
                        mrflyWidgets["transfertMarginWidget"].$refs.instance.marginValue = data.value;
                    }
                    $window.FLoading({ vAction: 'hide' });
                });
            }
        };

        $scope.getLocationMargin = function () {

            if ($window.containsLocation) {
                $window.FLoading({ vAction: 'show' });
                apiClient.B2B.getClientMarginVacationRental().success(function (data) {
                    if (data) {
                        mrflyWidgets["locationMarginWidget"].$refs.instance.marginValue = data.value;
                    }
                    $window.FLoading({ vAction: 'hide' });
                });
            }
        };

        //This is only for debug
        $window.$scope = $scope;
        $window.$scope.apiClient = apiClient;
    }]);
