webClient.controller('AirProducts', ['$scope', '$window', '$timeout', '$i18next', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', 'trackingService', 'gtmProduct', 'omnitureService',
    function ($scope, $window, $timeout, $i18next, tseApiClient, tseUtil, airHelper, trackingService, gtmProduct, omnitureService) {
        $window.$scope = $scope;
        $scope.MeanOutbound = null;
        $scope.MeanInbound = null;
        $scope.resultCO2 = null;
        $scope.CO2Map = null;
        $scope.isloadingco2 = null;
        $scope.enableCO2 = false;
        $scope.nbCallCo2 = 0;
        $scope._util = tseUtil;
        $scope.enableCalendar = $window.enableCalendar;
        $scope.site = $window.site;
        $scope.getText = function (key) { return window.culture.texts[key]; };
        $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
        $scope.requestSummary = $window.site.currentAirRequest.summary;
        $scope.searchForm = {};
        $scope.initialPriceValue = 0;
        $scope.searchForm.type = $scope.requestSummary.roundTrip ? 0 : $scope.requestSummary.oneWay ? 1 : 2;
        $scope.searchForm.cabin = $scope.requestSummary.cabinClass;
        $scope.searchForm.origin = $scope.requestSummary.origin.name;
        $scope.searchForm.origin_value = $scope.requestSummary.origin.value;
        $scope.searchForm.destination = $scope.requestSummary.destination.name;
        $scope.searchForm.destination_value = $scope.requestSummary.destination.value;
        $scope.searchForm.goDate = $scope.requestSummary.goDate.dateString;
        $scope.searchForm.adults = $scope.requestSummary.adults;
        $scope.searchForm.childrens = $scope.requestSummary.childs;
        $scope.searchForm.infants = $scope.requestSummary.infants;
        $scope.isTurkmenistan = window.isTurkmenistan;
        $scope.hideFlexy = window.enableFlexy === false;
        $scope.enableFreeFlex = window.enableFreeFlex === true;
        $scope.enableMarketBanner = window.enableMarketBanner === true;
        $scope.market = window.market;
        $scope.hideAirportFilter = $window.site.partnerId === "europa";
        $scope.enableKiwiCheap = $window.enableKiwiCheap;
        $scope.partnerName = $window.partnerName;
        $scope.isServair = window.site.partnerId === "servair";
        $scope.currentLng = lng;
        $scope.firstDirectRoute = window.firstDirectRoute === true;
        $scope.hasFlightHotelFormSummary = hasCookie("FlightHotelFormSummary") && $window.getUrlVars()['hotelSummary'] === 'true';
        $scope.editSearch = true;
        if ($window.isWonderBox) $scope.isWonderBox = $window.isWonderBox;
        if ($scope.requestSummary.roundTrip)
            $scope.searchForm.returnDate = $scope.requestSummary.goDate2.dateString;
        if ($scope.requestSummary.openJaw) {
            $scope.searchForm.origin2 = $scope.requestSummary.origin2.name;
            $scope.searchForm.origin2_value = $scope.requestSummary.origin2.value;
            $scope.searchForm.destination2 = $scope.requestSummary.destination2.name;
            $scope.searchForm.destination2_value = $scope.requestSummary.destination2.value;
            $scope.searchForm.goDate2 = $scope.requestSummary.goDate2.dateString;
        }
        $scope.startProcessDate = new Date();
        $scope.hasToggleAllAndNoneMarketingCarriersFilters = false;
        $scope.isAllAndNoneToggleChecked = false;

        $scope.disableStop = function () {
            angular.element("[name='i-filter-stops']").val(-1);
            $scope.view.appliedFilters.stops = false;
        }

        $scope.isUpsell = $window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true';

        $scope.isMasstockRequest = /ismasstockrequest=([^&]+)/.exec(window.location.href) != null && /ismasstockrequest=([^&]+)/.exec(window.location.href)[1] == "true" ? true : false;

        $scope.SendInspecletUsage = function () {
            //Send inspectlet tag of use Modifier Button
            $window.sendInspectlet('tagSession', { UseModifierButton: true });
        };

        $scope.toggleEdit = function () {
            $scope.editSearch = !$scope.editSearch;
        };

        $scope.sortingPriority = "Price";

        $scope.UpdateCo2Results = function () {
            $scope.nbCallCo2++;
            if ($scope.resultCO2 == null) {
                return;
            }
            $scope.CO2Map = new Map();
            for (var i = 0; i < $scope.resultCO2.length; i++) {
                $scope.CO2Map.set($scope.resultCO2[i].id, $scope.resultCO2[i]);
            }

            var meanOutbound = {
                counter: 0,
                co2Average: 0
            }

            var meanInbound = {
                counter: 0,
                co2Average: 0
            }

            var initProductCO2 = function (product) {
                product.co2Data = {
                    co2Average: 0,
                    hasAllSegmentsData: true,
                    isRecalculation: false
                }
            };

            var getCO2ValueFromCabinClass = function (segment) {
                switch (segment.cabinClass.toUpperCase()) {
                    case "Y":
                        return segment.co2Data.co2Economy;
                    case "S":
                        return segment.co2Data.co2PremiumEconomy;
                    case "C":
                        return segment.co2Data.co2Business;
                    case "F":
                        return segment.co2Data.co2First;
                    default:
                        return segment.co2Data.co2Average;
                }
            };

            var AddCO2Values = function (product, segment) {
                if (typeof segment.co2Data === 'undefined') {
                    product.co2Data.hasAllSegmentsData = false;
                    return null;
                }
                product.co2Data.co2Average += getCO2ValueFromCabinClass(segment);
                return segment.co2Data;
            };

            var SumCO2Products = function (meanReference, product) {
                if (product.co2Data.co2Average === 0)
                    return;
                meanReference.co2Average += product.co2Data.co2Average;
                meanReference.counter++;
            }

            var ComputeCO2Mean = function (meanReference) {
                meanReference.co2Average = meanReference.co2Average / meanReference.counter;
            }

            var ComputeDeviation = function (meanReference, product) {
                if (product.co2Data.co2Average === 0) {
                    return;
                }
                product.co2Data.meanCo2Average = meanReference.co2Average;
                product.co2Data.co2AverageDeviationRaw = product.co2Data.co2Average - meanReference.co2Average;
                product.co2Data.co2AverageDeviation = product.co2Data.co2AverageDeviationRaw * 100 / meanReference.co2Average;
            }

            for (var i = 0; i < $scope.view.data.products.length; i++) {
                var oneProduct = $scope.view.data.products[i];

                initProductCO2(oneProduct.outbound);

                var out_seg = oneProduct.outbound.segments;
                for (var j = 0; j < out_seg.length; j++) {
                    var segmentId = (out_seg[j].operatingAirline == null ? out_seg[j].airline : out_seg[j].operatingAirline) + out_seg[j].departure.airport + out_seg[j].arrival.airport + out_seg[j].equipment;
                    out_seg[j].co2Data = $scope.CO2Map.get(segmentId);
                    if (out_seg[j].equipment == '') {
                        oneProduct.outbound.co2Data.isRecalculation = true;
                    }
                    AddCO2Values(oneProduct.outbound, out_seg[j]);
                }

                if (oneProduct.outbound.co2Data.hasAllSegmentsData) {
                    SumCO2Products(meanOutbound, oneProduct.outbound);
                }

                var inbounds = oneProduct.inbounds;
                if (inbounds != null) {
                    for (var j = 0; j < inbounds.length; j++) {
                        initProductCO2(inbounds[j]);
                        var in_seg = inbounds[j].segments;
                        for (var k = 0; k < in_seg.length; k++) {
                            var segmentId = (in_seg[k].operatingAirline == null ? in_seg[k].airline : in_seg[k].operatingAirline) + in_seg[k].departure.airport + in_seg[k].arrival.airport + in_seg[k].equipment;
                            in_seg[k].co2Data = $scope.CO2Map.get(segmentId);
                            if (in_seg[k].equipment == '') {
                                inbounds[j].co2Data.isRecalculation = true;
                            }
                            AddCO2Values(inbounds[j], in_seg[k]);
                        }
                        if (inbounds[j].co2Data.hasAllSegmentsData) {
                            SumCO2Products(meanInbound, inbounds[j]);
                        }
                    }
                }

            }

            ComputeCO2Mean(meanOutbound);
            ComputeCO2Mean(meanInbound);

            $scope.MeanInbound = meanInbound;
            $scope.MeanOutbound = meanOutbound;

            for (var i = 0; i < $scope.view.data.products.length; i++) {
                ComputeDeviation(meanOutbound, $scope.view.data.products[i].outbound)

                var inbounds = $scope.view.data.products[i].inbounds;
                if (inbounds != null) {
                    for (var j = 0; j < inbounds.length; j++) {
                        ComputeDeviation(meanInbound, inbounds[j]);
                    }
                }

            }

            $scope.isloadingco2 = false;
            $scope.airResults.updateRawResults($scope.view.data);
            $scope.$apply();
        }

        $scope.RequestCo2Data = function (results) {
            var segmentsMap = new Map();

            for (var i = 0; i < results.products.length; i++) {

                // iterate over outbound segments
                var out_seg = results.products[i].outbound.segments;
                for (var j = 0; j < out_seg.length; j++) {
                    var segmentId = (out_seg[j].operatingAirline == null ? out_seg[j].airline : out_seg[j].operatingAirline) + out_seg[j].departure.airport + out_seg[j].arrival.airport + out_seg[j].equipment;
                    segmentsMap.set(segmentId, true);
                }

                // iterate over inboud segments
                var inbounds = results.products[i].inbounds;
                for (var j = 0; j < inbounds.length; j++) {
                    var in_seg = inbounds[j].segments;
                    for (var k = 0; k < in_seg.length; k++) {
                        var segmentId = (in_seg[k].operatingAirline == null ? in_seg[k].airline : in_seg[k].operatingAirline) + in_seg[k].departure.airport + in_seg[k].arrival.airport + in_seg[k].equipment;
                        segmentsMap.set(segmentId, true);
                    }
                }
            }

            var allSegments = Array.from(segmentsMap.keys());

            var bodyRequest = {
                segments: allSegments
            }

            $scope.isloadingco2 = true;
            $window.Typescript.CO2.Services.CO2Service.getCO2(bodyRequest).then(function (axiosResponse) {
                try {
                    var responseJson = JSON.parse(axiosResponse.data);
                    $scope.resultCO2 = responseJson.body;
                    $scope.UpdateCo2Results();
                } catch (e) {
                    console.warn("Exception : " + e);
                    $scope.isloadingco2 = false;
                    $scope.$apply();
                }
            }, function (err) {
                console.error(err);
                $scope.isloadingco2 = false;
            });
        }

        //Traduction
        $scope.getT = function (key) {
            var res = $i18next.t(key.toLowerCase());
            return res;
        };
        if (window.site.partnerId == 'cdiscountvol') {
            try {
                var returningDeparturedate = '';
                var returningArrivaldate = '';
                var destinationAirport = '';
                if ($scope.requestSummary.openJaw) {
                    var itinerariesMultuDestination = $scope.requestSummary.itineraries;
                    for (var i = 0; i <= itinerariesMultuDestination.length - 1; i++) {
                        var itinerariesData = $scope.requestSummary.itineraries[i];
                        destinationAirport = itinerariesData.destination.cityIATA;
                        departingArrivaldate = moment(itinerariesData.goDate.date).format("DD-MM-YYYY");

                        //fbq('track', 'PageView', {
                        //    type_service: 'VOYAGES',
                        //    type_conversion: 'VISITES',
                        //    type_produit: 'VOL'
                        //});
                    }
                } else {
                    if ($scope.requestSummary.roundTrip) {
                        returningDeparturedate = moment($scope.requestSummary.goDate2.date).format("DD-MM-YYYY");
                        returningArrivaldate = moment($scope.requestSummary.goDate2.date).format("DD-MM-YYYY");
                        destinationAirport = $scope.requestSummary.origin2.cityIATA;
                    } else {
                        if ($scope.requestSummary.oneWay) {
                            destinationAirport = $scope.requestSummary.destination.cityIATA;
                        }
                    }

                    //console.log("icic ca plante track pageview en local");
                    //fbq('track', 'PageView', {
                    //    type_service: 'VOYAGES',
                    //    type_conversion: 'VISITES',
                    //    type_produit: 'VOL'
                    //});
                }

            } catch (ex) {
                console.log(ex.message);
                $window.trackingError = ex.message;
            }
        }


        //reset upsell flag on new search
        window.sessionStorage.removeItem('forceUpsell');
        //reset total flex flag on new search
        window.sessionStorage.removeItem("inssurance.totalflex.selected");
        window.sessionStorage.removeItem("inssurance.totalflex.selectedOffer");
        //reset repatriation flag on new search
        window.sessionStorage.removeItem("inssurance.repatriation.selected");
        window.sessionStorage.removeItem("inssurance.repatriation.isChecked");

        $timeout(function () {
            var currentSearch = $scope.searchForm;
            currentSearch.key = $window.flightHistory.createKey(currentSearch);
            currentSearch.requestId = $scope.requestSummary.requestId;
            var lastSearch = $window.flightHistory.loadLastSearch();
            if (lastSearch) {
                if (lastSearch.origin_value == currentSearch.origin_value)
                    currentSearch.origin = lastSearch.origin;
                if (lastSearch.destination_value == currentSearch.destination_value)
                    currentSearch.destination = lastSearch.destination;
                if (lastSearch.origin2_value == currentSearch.origin2_value)
                    currentSearch.origin2 = lastSearch.origin2;
                if (lastSearch.destination2_value == currentSearch.destination2_value)
                    currentSearch.destination2 = lastSearch.destination2;
            }
            $window.flightHistory.bindView(currentSearch);
            //$window.flightHistory.saveSearch(currentSearch);
        }, 0);
        $scope.currentCabinClass = $window.site.currentAirRequest.summary.cabinClass;
        $scope.view = null;
        $scope.minPriceOnOutbound = false;
        $scope.minPriceOnOutboundDeparture = false;
        $scope.minPriceOnOutboundArrival = false;
        $scope.minPriceAirportIATA = null;

        $scope.enableOption = $window.enableOption && !($window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true');


        getBundleWidget("mrfly-widget-wait.js").done(function () {

            var airSearchRequest = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequest']();

            airSearchRequest.adultNumber = window.site.currentAirRequest.summary.adults;
            airSearchRequest.childNumber = window.site.currentAirRequest.summary.childs;
            airSearchRequest.infantNumber = window.site.currentAirRequest.summary.infants;
            airSearchRequest.isOneWay = window.site.currentAirRequest.summary.itineraries.length == 1;
            airSearchRequest.isRoundTripOrOpenJaw = window.site.currentAirRequest.summary.itineraries.length == 2;
            airSearchRequest.isMultiDestination = window.site.currentAirRequest.summary.itineraries.length > 2;
            airSearchRequest.cabinClass = window.site.currentAirRequest.summary.cabinClass;

            //Map itineraries
            airSearchRequest.itineraries = [];
            window.site.currentAirRequest.summary.itineraries.forEach(function (itinerary) {
                var ins = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequestItinerary']();

                ins.date = itinerary.goDate.date;

                ins.departureAirportIata = itinerary.origin.value;
                ins.departureCityIata = itinerary.origin.cityIATA;
                ins.departureName = itinerary.origin.cityName;

                ins.arrivalAirportIata = itinerary.destination.value;
                ins.arrivalCityIata = itinerary.destination.cityIATA;
                ins.arrivalName = itinerary.destination.cityName;

                airSearchRequest.itineraries.push(ins);
            });


            mrflyWidgets['mrfly-widget-wait'].airSearchRequest = airSearchRequest;
            mrflyWidgets['mrfly-widget-wait'].siteType = window.site.type;
            mrflyWidgets['mrfly-widget-wait'].sitePartnerId = window.site.partnerId;
            mrflyWidgets['mrfly-widget-wait'].enableFreeFlex = window.enableFreeFlex;
            mrflyWidgets['mrfly-widget-wait'].partnerName = window.partnerName;
            mrflyWidgets['mrfly-widget-wait'].resources = i18next.store.data.dev.translation;

            if (typeof window.market !== undefined && window.market !== null) {
                mrflyWidgets['mrfly-widget-wait'].market = window.market;
            }
            else {
                mrflyWidgets['mrfly-widget-wait'].market = '';
            }
        }).fail(function (jqxhr, settings, exception) {
            console.log(exception);
        });

        getBundleWidget("mrfly-widget-banners.js").done(function () {
            mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.productType = $scope.isUpsell ? "Flight + Hotel" : "Flight";
            mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.enableFreeFlex = true;
            mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.enableKiwiCheap = true;
            mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.displayMasstockBlock = true;
            mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.enableCalendar = true;
            mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.displayBestPriceBlock = true;
            mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.enableMarketBanner = true;

            mrflyWidgets['mrfly-widget-banners'].$on('clearFilters', function () {
                var nFilters = $scope.view.initialFiltersValue;
                angular.element("#i-filters-menu li").removeClass("c-on");
                angular.element("#i-filter-times-pop .c-btn i").css('display', 'none');
                var initCount = $scope.view.productsCount;
                $scope.airResults.applyFilters(nFilters, null, null, null);
                mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.minPriceOnOutbound = false;
            });
            if (!$scope.isServair) {
                airSearchFaresSearchResults($scope.requestSummary.requestId)
                    .success(function (results) {

                        var labels = new Object();
                        labels['status'] = "OK";
                        window.Typescript.Services.MetricService.SendEvent(window.metricUrlApi, "airSearch", labels);
                        if (window.enableCO2) {
                            $scope.enableCO2 = true;
                            $scope.RequestCo2Data(results);
                        }
                        processResults(results);

                        //console.log("VALUE IS" + $scope.rawResults.products[0].minTotalFare.value);
                        var initialPriceValue = $scope.rawResults.products[0].minAverageFare.value;
                        var bestPriceValue = initialPriceValue;
                        var minTotalFareFeeMargin = $scope.rawResults.products[0].minTotalFareFeeMargin.value;
                        /***Load Calendar*/
                        //console.log("VALUEE" + $scope.view.minPriceProduct.minTotalFare.value);
                        var apiToken = getCookie("authToken_" + window.site.partnerId);


                        var showCalendar = $window.enableCalendar && $scope.rawResults.hasCalendar;//hide/show calendar according to api results
                        mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.enableCalendar = showCalendar;

                        if (showCalendar) {
                            mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.requestId = $scope.requestSummary.requestId;
                            mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.authToken = apiToken;
                            mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.nbAdults = $scope.searchForm.adults;
                            mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.nbChildren = $scope.searchForm.childrens;
                            mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.nbInfants = $scope.searchForm.infants;
                            mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.initialPrice = minTotalFareFeeMargin;
                            mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.bestPrice = bestPriceValue;

                            mrflyWidgets['mrfly-widget-banners'].$on('reLoad', function (data) {

                                var airSearchRequest = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequest']();

                                airSearchRequest.adultNumber = window.site.currentAirRequest.summary.adults;
                                airSearchRequest.childNumber = window.site.currentAirRequest.summary.childs;
                                airSearchRequest.infantNumber = window.site.currentAirRequest.summary.infants;
                                airSearchRequest.isOneWay = window.site.currentAirRequest.summary.itineraries.length == 1;
                                airSearchRequest.isRoundTrip = window.site.currentAirRequest.summary.itineraries.length == 2 && window.site.currentAirRequest.summary.itineraries[0].origin.cityIATA.localeCompare(window.site.currentAirRequest.summary.itineraries[1].destination.cityIATA) === 0;
                                airSearchRequest.isOpenJaw = window.site.currentAirRequest.summary.itineraries.length === 2 && window.site.currentAirRequest.summary.itineraries[0].origin.cityIATA.localeCompare(window.site.currentAirRequest.summary.itineraries[1].destination.cityIATA) !== 0;
                                airSearchRequest.isMultiDestination = window.site.currentAirRequest.summary.itineraries.length > 2;
                                airSearchRequest.cabinClass = window.site.currentAirRequest.summary.cabinClass;

                                //Map itineraries
                                airSearchRequest.itineraries = [];
                                window.site.currentAirRequest.summary.itineraries.forEach(function (itinerary) {
                                    var ins = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequestItinerary']();

                                    ins.date = moment(data.from);

                                    ins.departureAirportIata = itinerary.origin.value;
                                    ins.departureCityIata = itinerary.origin.cityIATA;
                                    ins.departureName = itinerary.origin.cityName;

                                    ins.arrivalAirportIata = itinerary.destination.value;
                                    ins.arrivalCityIata = itinerary.destination.cityIATA;
                                    ins.arrivalName = itinerary.destination.cityName;

                                    airSearchRequest.itineraries.push(ins);
                                });

                                if (airSearchRequest.itineraries.length == 1) {
                                    airSearchRequest.itineraries[0].date = moment(data.from, 'DD/MM/YYYY');
                                    $("#i-search-vols input[name='goDate']").val(data.from);
                                }

                                if (airSearchRequest.itineraries.length == 2) {
                                    airSearchRequest.itineraries[0].date = moment(data.from, 'DD/MM/YYYY').toDate();
                                    $("#i-search-vols input[name='goDate']").val(data.from);
                                    airSearchRequest.itineraries[1].date = moment(data.to, 'DD/MM/YYYY').toDate();
                                    $("#i-search-vols input[name='returnDate']").val(data.to);
                                }


                                mrflyWidgets['mrfly-widget-request-recap'].airSearchRequest = airSearchRequest;

                                $window.FLoading({ vAction: "show" });
                                var fromDate = data.from;
                                //console.log("VALUE IS" + $scope.rawResults.products[0].minTotalFare.value);
                                $.ajax({
                                    url: 'airsearch/',
                                    type: 'get',
                                    data: {
                                        origin: $scope.searchForm.origin_value,
                                        destination: $scope.searchForm.destination_value,
                                        adults: $scope.searchForm.adults,
                                        childs: $scope.searchForm.childrens,
                                        infants: $scope.searchForm.infants,
                                        cabinClass: $scope.searchForm.cabin,
                                        godate: fromDate,
                                        returndate: data.oneWay ? null : data.to,
                                        fromCalendar: true
                                    },
                                    success: function (rawResponse) {
                                        var response = rawResponse.split('-').join('');
                                        //console.log(response);

                                        airSearchFaresSearchResults(response).success(function (results) {
                                            var labels = new Object();
                                            labels['status'] = "OK";
                                            window.Typescript.Services.MetricService.SendEvent(window.metricUrlApi, "airSearch", labels);

                                            processResults(results);

                                            $window.FLoading({ vAction: "hide" });

                                            var oldId = $scope.requestSummary.requestId;


                                            var tempRequest = JSON.stringify($scope.requestSummary);
                                            tempRequest = tempRequest.replaceAll(oldId, response);
                                            $scope.requestSummary = JSON.parse(tempRequest);

                                            var tempAirRequest = JSON.stringify($window.site.currentAirRequest);
                                            tempAirRequest = tempAirRequest.replaceAll(oldId, response);
                                            $window.site.currentAirRequest = JSON.parse(tempAirRequest);

                                            $window.site.currentAirRequest.requestId = response;
                                            var departDate = new Date(results.request.itineraries[0].date);
                                            $window.site.currentAirRequest.summary.goDate.date = departDate;
                                            $scope.requestSummary.goDate.date = departDate;
                                            if (!data.oneWay) {
                                                var returnDate = new Date(results.request.itineraries[1].date);
                                                $window.site.currentAirRequest.summary.goDate2.date = returnDate;
                                                $scope.requestSummary.goDate2.date = returnDate;
                                            }
                                            var bookingUrl = $window.site.currentAirRequest.bookingUrl;
                                            var regex = /[a-f0-9]{8}(?:[a-f0-9]{4}){3}[a-f0-9]{12}/;
                                            if (bookingUrl.match(regex)) {
                                                var result = bookingUrl.replace(regex, response);
                                                $window.site.currentAirRequest.bookingUrl = result;
                                            }
                                        }).error(function (data, code) {

                                        });
                                    }
                                });
                            });
                            //console.log('calendar loaded');
                        }
                        /***End Load Calendar*/
                        //Send metrics time
                        var processTime = Math.abs(new Date().getTime() - $scope.startProcessDate.getTime()) / 1000;
                        window.Typescript.Services.MetricService.SendTime(window.metricUrlApi, "flight_airsearch", processTime, new Object());
                    })
                    .error(function (data, code) {

                        if (data && data.Type == "Token") return;

                        var labels = new Object();
                        labels['status'] = "KO";
                        window.Typescript.Services.MetricService.SendEvent(window.metricUrlApi, "airSearch", labels);


                        $window.lastError = $window.objectToArray(arguments);
                        tseUtil.Storage.set("lastError", $window.lastError);
                        if (code == 410) {
                            $window.lastMessage = "Results Timeout!, we have to do another search!";
                            tseUtil.Storage.set("lastMessage", $window.lastMessage);
                            tseUtil.Debug.log($window.lastMessage);
                            $window.redoSearch();
                        } else if (code == 404) {
                            $window.lastMessage = "There is not Results for this search!, first we need to create a search.";
                            tseUtil.Storage.set("lastMessage", $window.lastMessage);
                            tseUtil.Debug.log($window.lastMessage);
                            $window.redoSearch();
                        }
                        else if (code == 400) {
                            $window.lastMessage = "Ups! Bad Request???";
                            tseUtil.Storage.set("lastMessage", $window.lastMessage);
                            tseUtil.Debug.log($window.lastMessage);
                            $window.redoSearch();
                        }
                        else if (code == 0) {
                            $window.lastMessage = "Empty Response (Fortinet error)";
                            tseUtil.Storage.set("lastMessage", $window.lastMessage);
                            tseUtil.Debug.log($window.lastMessage);
                            $window.redoSearch();
                        }
                        else if (code == 501) {
                            $window.lastMessage = "The response never came to the restapi";
                            tseUtil.Storage.set("lastMessage", $window.lastMessage);
                            $window.location.href = $window.site.errorsUrls.NoData;
                        }
                        else {
                            $window.lastMessage = "Error Code: " + code;
                            tseUtil.Storage.set("lastMessage", $window.lastMessage);
                            $window.location.href = $window.site.errorsUrls.NoData;
                        }
                    });
            }
            else {
                var token = getCookie("authToken_" + window.site.partnerId);
                var origin2 = null;
                var destination2 = null;
                var goDate2 = null;
                if ($scope.requestSummary.roundTrip) {
                    goDate2 = $scope.requestSummary.goDate2.dateString;
                    origin2 = $scope.requestSummary.origin2.name;
                    destination2 = $scope.requestSummary.destination2.name;
                }
                console.log("origin2test", origin2);
                console.log("destination2", destination2);
                console.log("goDate2", goDate2);

                $.ajax({
                    url: '/API/Servair/SearchServair',
                    type: 'get',
                    data: {
                        origin: $scope.searchForm.origin_value,
                        token: token,
                        destination: $scope.searchForm.destination_value,
                        adults: $scope.searchForm.adults,
                        childs: $scope.searchForm.childrens,
                        infants: $scope.searchForm.infants,
                        cabinClass: $scope.searchForm.cabin,
                        goDate: $scope.searchForm.goDate,
                        returnDate: $scope.searchForm.returnDate,
                        requestId: $scope.requestSummary.requestId,
                        origin2: origin2,
                        destination2: destination2,
                        goDate2: goDate2
                    },
                    success: function (results) {

                        if (results && results.Products && results.Products.length === 0) {
                            window.location.href = window.site.errorsUrls.NoProducts;
                        }
                        results = JSON.parse(results);
                        if (results.summary.request.isOneWay) {
                            //on reset le prix minimum pour qu'il coincide avec le prix minimum subventionné et pas le non subventionné sinon ça pete les filtres
                            //et on va supprimer des vols à tort.
                            results.summary.averageFareFeeMargin.from = results.products.min(function (f) {
                                return f.outbound.proposal.totalSubvention;
                            });
                            results.summary.averageFareFeeMargin.to = results.products.max(function (f) {
                                return f.outbound.proposal.totalSubvention;
                            });
                        }
                        else {
                            //on reset le prix minimum pour qu'il coincide avec le prix minimum subventionné et pas le non subventionné sinon ça pete les filtres
                            //et on va supprimer des vols à tort.
                            results.summary.averageFareFeeMargin.from = results.products.min(function (f) {
                                return f.inbounds.min(function (t) {
                                    return t.proposal.totalSubvention
                                })
                            });

                            results.summary.averageFareFeeMargin.to = results.products.max(function (f) {
                                return f.inbounds.max(function (t) {
                                    return t.proposal.totalSubvention
                                })
                            });
                        }

                        if (window.enableCO2) {
                            $scope.enableCO2 = true;
                            $scope.RequestCo2Data(results);
                        }
                        processResults(results);

                        if ($scope.isServair) {
                            //alert("servair");
                            jQuery.ajax({
                                type: 'GET',
                                traditional: true,
                                contentType: 'application/json; charset=utf-8',
                                dataType: 'json',
                                url: '/api/PurchaseNewSubvention/GetAyantDroit',
                                data: { uniqueKey: window.site.currentAirRequest.requestId },
                                success: function (result) {
                                    $scope.ayantDroitList = JSON.parse(result);
                                    console.log("$scope.getAyantDroitList", $scope.ayantDroitList);
                                    $scope.$apply();

                                    //Send metrics time
                                    var processTime = Math.abs(new Date().getTime() - $scope.startProcessDate.getTime()) / 1000;
                                    window.Typescript.Services.MetricService.SendTime(window.metricUrlApi, "flight_airsearch", processTime, new Object());
                                },
                                error: function (xhr, ajaxOptions, thrownError) {


                                }
                            });
                        };
                    }
                })
                //****servair*****//
            }

        });

        getBundleWidget("mrfly-widget-request-recap.js").done(function () {
            console.log("Loading done");
            //set params do mapping

            var airSearchRequest = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequest']();

            airSearchRequest.adultNumber = window.site.currentAirRequest.summary.adults;
            airSearchRequest.childNumber = window.site.currentAirRequest.summary.childs;
            airSearchRequest.infantNumber = window.site.currentAirRequest.summary.infants;
            airSearchRequest.isOneWay = window.site.currentAirRequest.summary.itineraries.length == 1;
            airSearchRequest.isRoundTrip = window.site.currentAirRequest.summary.itineraries.length == 2 && window.site.currentAirRequest.summary.itineraries[0].origin.cityIATA.localeCompare(window.site.currentAirRequest.summary.itineraries[1].destination.cityIATA) === 0;
            airSearchRequest.isOpenJaw = window.site.currentAirRequest.summary.itineraries.length === 2 && window.site.currentAirRequest.summary.itineraries[0].origin.cityIATA.localeCompare(window.site.currentAirRequest.summary.itineraries[1].destination.cityIATA) !== 0;
            airSearchRequest.isMultiDestination = window.site.currentAirRequest.summary.itineraries.length > 2;
            airSearchRequest.cabinClass = window.site.currentAirRequest.summary.cabinClass;

            //Map itineraries
            airSearchRequest.itineraries = [];
            window.site.currentAirRequest.summary.itineraries.forEach(function (itinerary) {
                var ins = new window['Typescript']['Class']['AirSearchRequest']['AirSearchRequestItinerary']();

                ins.date = itinerary.goDate.date;

                ins.departureAirportIata = itinerary.origin.value;
                ins.departureCityIata = itinerary.origin.cityIATA;
                ins.departureName = itinerary.origin.cityName;

                ins.arrivalAirportIata = itinerary.destination.value;
                ins.arrivalCityIata = itinerary.destination.cityIATA;
                ins.arrivalName = itinerary.destination.cityName;

                airSearchRequest.itineraries.push(ins);
            });
            mrflyWidgets['mrfly-widget-request-recap'].airSearchRequest = airSearchRequest;
            mrflyWidgets['mrfly-widget-request-recap'].locale = $window.lng.substring(0, 2);
            mrflyWidgets['mrfly-widget-request-recap'].language = $window.lng;
            mrflyWidgets['mrfly-widget-request-recap'].resources = i18next.store.data.dev.translation;
        });

        $scope.productVisibleFilter = function (item) {
            return item.outbound.visible;
        };

        $scope.listMultiDeparturesOptions = [
            { departureIata: 'BRU', listIATA: ['AMS', 'PAR'] },
            { departureIata: 'LUX', listIATA: ['FRA', 'DUS', 'CGN'] },
            { departureIata: 'AMS', listIATA: ['RTM', 'EIN', 'MST'] },
            { departureIata: 'FRA', listIATA: ['DUS', 'CGN'] }
        ]


        var swatch = tseUtil.StopWatch.createNew();
        var airSearchFaresSearchResults = function (requestId) {
            return $scope.apiClient.getAirFaresSearchResults(requestId);
        };

        var processTrainResults = function (results) {
            angular.forEach(results.products, function (prod, indexP) {
                if (prod.outbound.segments.length == 1 && prod.outbound.segments[0].equipment == "TRN") $scope.rawResults.products[indexP].outbound.isTrainOnly = true;
                else {
                    var isTrainOnly = false;
                    var hasBus = false;
                    for (var i = 0; i < prod.outbound.segments.length; i++) {
                        if (prod.outbound.segments[i].equipment == "TRN") isTrainOnly = true;
                        if (prod.outbound.segments[i].equipment == "BUS") hasBus = true;
                        else if (prod.outbound.segments[i].equipment != "TRN") {
                            isTrainOnly = false;
                            break;
                        }
                    }
                    $scope.rawResults.products[indexP].outbound.isTrainOnly = isTrainOnly;
                }
                angular.forEach(prod.inbounds, function (inb, indexB) {
                    if (inb.segments.length == 1 && inb.segments[0].equipment == "TRN") $scope.rawResults.products[indexP].inbounds[indexB].isTrainOnly = true;
                    else {
                        var isTrainOnly = false;
                        var hasBus = false;
                        for (var i = 0; i < inb.segments.length; i++) {
                            if (inb.segments[i].equipment == "TRN") isTrainOnly = true;
                            if (inb.segments[i].equipment == "BUS") hasBus = true;
                            else if (inb.segments[i].equipment != "TRN") {
                                isTrainOnly = false;
                                break;
                            }
                        }
                        $scope.rawResults.products[indexP].inbounds[indexB].isTrainOnly = isTrainOnly;
                    }
                });
            });
        };

        var applyStopFilters = function (stops) {

            currentStops = stops;
            index = currentStops.slice(-1)[0];

            //ES6 not allowed because of minification
            //filterStops = [...Array(index + 1).keys()];

            //equivalent of line above but in ES5
            var filterStops = [];
            for (var f = 0; f < index + 1; f++) {
                filterStops.push(f);
            }

            var finalStops = [];
            for (var i = 0; i < filterStops.length; i++) {
                finalStops.push({
                    display: currentStops.includes(i),
                    value: i
                });
            }
            //return result;
            return finalStops;
        };


        var processResults = function (results) {
            var filters = applyStopFilters(results.summary.stops);

            $scope.stopFilters = filters;
            //results.summary.stops = filters;
            swatch.stop();
            $scope.resultsTime = swatch.getElapsed();
            $scope.rawResults = results;
            $scope.displayFareTypeFilter = false;
            /*Train results processing*/
            processTrainResults(results);
            if (results.messages.any(function (mi) { return mi.category == 2; })) {
                tseUtil.Storage.set("lastMessages", results.messages);
                if (results.messages.any(function (mi) { return mi.text == "NO RESPONSE FROM PROVIDER OR IS NOT AUTHORIZED."; }))
                    $window.location.href = $window.site.errorsUrls.NoResponseFromProviderOrNotAuthorized;
                else
                    $window.location.href = $window.site.errorsUrls.GeneralError;
            }
            else {
                if (results.products.length == 0) {
                    $window.location.href = $window.site.errorsUrls.NoProducts;
                    return;
                }

                var initialFilters = $window.objectClone(results.summary);




                //filter trains according to flag in Upsell
                var isUpsell = $window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true';
                initialFilters.excludeTrain = isUpsell && $window.hideTrainInUpsell === true;
                if ($window.partnerName == "airbus") initialFilters.excludeTrain = true;
                    
                //Sets the initial filters for airports
                airHelper.prepareSummary(initialFilters);
                if ($scope.requestSummary.origin.airportIATA && ($scope.requestSummary.origin.airportIATA != $scope.requestSummary.origin.cityIATA)) {
                    if (initialFilters.outbound != null && initialFilters.outbound.departure != null) {
                        var outVal = initialFilters.outbound.departure.contains($scope.requestSummary.origin.airportIATA);
                        if (outVal)
                            initialFilters.outbound.departure = [$scope.requestSummary.origin.airportIATA];
                    }
                    if (initialFilters.inbound != null && initialFilters.inbound.arrival != null) {
                        var inVal = initialFilters.inbound.arrival.contains($scope.requestSummary.origin.airportIATA);
                        if (inVal)
                            initialFilters.inbound.arrival = [$scope.requestSummary.origin.airportIATA];
                    }
                }
                if ($scope.requestSummary.destination.airportIATA && ($scope.requestSummary.destination.airportIATA != $scope.requestSummary.destination.cityIATA)) {
                    if (initialFilters.outbound != null && initialFilters.outbound.arrival) {
                        var outVal = initialFilters.outbound.arrival.contains($scope.requestSummary.destination.airportIATA);
                        if (outVal)
                            initialFilters.outbound.arrival = [$scope.requestSummary.destination.airportIATA];
                    }
                    if (initialFilters.inbound != null && initialFilters.inbound.departure) {
                        var inVal = initialFilters.inbound.departure.contains($scope.requestSummary.destination.airportIATA);
                        if (inVal)
                            initialFilters.inbound.departure = [$scope.requestSummary.destination.airportIATA];
                    }
                }

                //Load Context
                $scope.loadContext();
                var initialOrder = ordersBy[0];
                if ($scope.currentContext && $scope.currentContext.appliedFiltersValue)
                    initialFilters = $scope.currentContext.appliedFiltersValue;
                if ($scope.currentContext && $scope.currentContext.appliedOrderBy)
                    initialOrder = ordersBy[$scope.currentContext.appliedOrderBy];

                var aFilter = tseUtil.Location.getQueryStringVar("airline");
                if (aFilter)
                    initialFilters.marketingCarriers = aFilter.toUpperCase().split(",").map(function (val) { return val.trim() });

                //Sets the URL filters for airports

                //DirectFlight

                var dFilter = tseUtil.Location.getQueryStringVar("direct");
                if (dFilter) {
                    console.log("ADD filter dieect");
                    console.log($scope.stopFilters);
                    initialFilters.stops = [0];
                    angular.element("[name='i-filter-stops']").val(0);
                    $("#i-filter-stops-pop li").removeClass("c-on");
                    $("#stopsSelector > li:nth-child(2)").click();
                    $scope.stopFilters[0].isSelected = true;
                    
                } 


                var aFilterDepURL = tseUtil.Location.getQueryStringVar("departure").replace(/\s+/g, "");
                var aFilterArrURL = tseUtil.Location.getQueryStringVar("arrival").replace(/\s+/g, "");

                if (aFilterDepURL) {
                    var aFilterDepartureURL = aFilterDepURL.toUpperCase().split(",");
                }
                if (aFilterArrURL) {
                    var aFilterArrivalURL = aFilterArrURL.toUpperCase().split(",");
                }

                if (aFilterDepartureURL || aFilterArrivalURL) {
                    if (aFilterDepartureURL) {
                        //vuelos de ida puertos origen
                        if (initialFilters.outbound != null && initialFilters.outbound.departure != null) {
                            var DeparturesoutVal = initialFilters.outbound.departure.intersect(aFilterDepartureURL);
                            var outVal = DeparturesoutVal.any();
                            if (outVal)
                                initialFilters.outbound.departure = DeparturesoutVal;
                        }
                        //puertos destino
                        if (initialFilters.inbound != null && initialFilters.inbound.arrival != null) {
                            var ArrivalinVal = initialFilters.inbound.arrival.intersect(aFilterDepartureURL);
                            var inVal = ArrivalinVal.any();
                            if (inVal)
                                initialFilters.inbound.arrival = ArrivalinVal;
                        }
                    }
                    if (aFilterArrivalURL) {
                        //vuelos de vuelta puertos destino 
                        if (initialFilters.outbound != null && initialFilters.outbound.arrival) {
                            var DeparturesoutVal = initialFilters.outbound.arrival.intersect(aFilterArrivalURL);
                            var outVal = DeparturesoutVal.any();
                            if (outVal)
                                initialFilters.outbound.arrival = DeparturesoutVal;
                        }
                        //puertos origen
                        if (initialFilters.inbound != null && initialFilters.inbound.departure) {
                            var DeparturesinVal = initialFilters.inbound.departure.intersect(aFilterArrivalURL);
                            var inVal = DeparturesinVal.any();
                            if (inVal)
                                initialFilters.inbound.departure = DeparturesinVal;
                        }
                    }
                }

                var firstRender = true;
                //$window.sendAnalytics('/Flightsearch/Result', 'Result');

                //Tracking
                try {
                    //GTM
                    if (trackingService.isActivated()) {
                        trackingService.pushPageData('/Flightsearch/Result', 'Result', lng, false);
                    }
                }
                catch (ex) {
                    console.log(ex.message);
                    $window.trackingError = ex.message;
                }

                //$window.sendXiti("moteur_resavol::resultats_recherche_resavol", 1);
                $window.sendInspectlet("pageUrl", $window.getUrl('Flightsearch/Result'));
                $window.sendInspectlet('identify', tseUtil.Cookies.get("MFsid"));
                $window.sendInspectlet('tagSession', "RequestId=" + $scope.requestSummary.requestId);


                $scope.airResults = airHelper.createNonPagedHelper(results, function (view) {

                    //****************************************************************************************************************
                    //workarround to remove the waiting div+css
                    angular.element("#waitingDiv").remove();
                    //****************************************************************************************************************


                    $scope.view = view;

                    //filter train in upsell (if window.hideTrainInUpsell flag is true )
                    var isUpsell = $window.sessionStorage.getItem('forceUpsell') === 'true' || $window.getUrlVars()['upsell'] === 'true';
                    $scope.view.initialFiltersValue.excludeTrain = isUpsell && $window.hideTrainInUpsell === true;
                    if ($window.partnerName == "airbus") $scope.view.initialFiltersValue.excludeTrain = true;

                    if ($scope.view.initialFiltersValue.excludeTrain === true) {
                        //remove train companies from filter (has to filter both lists to avoid checking filter boxes)
                        $scope.view.initialFiltersValue.marketingCarriers = $scope.view.initialFiltersValue.marketingCarriers.filter(function (carrier) {
                            return $scope.view.data.airlines[carrier].type != 0;
                        });
                        $scope.view.appliedFiltersValue.marketingCarriers = $scope.view.appliedFiltersValue.marketingCarriers.filter(function (carrier) {
                            return $scope.view.data.airlines[carrier].type != 0;
                        });
                    }

                    window.sessionStorage.setItem("airlines", JSON.stringify($scope.view.data.airlines));

                    //***********************************************************************************************************************
                    //TO Filter 1==TO
                    $scope.displayFareTypeFilter = $scope.enableOption && site.type == 2 && $scope.view.data.products.any(function (item) { return item.outbound.productType === 1 });

                    //BestPrice Block
                    $scope.displayBestPriceBlock = $scope.view.data.products.any(function (productItem) { return productItem.hasAnyBestPrice });

                    //Masstock
                    $scope.displayMasstockBlock = $scope.view.data.products.any(function (productItem) { return productItem.hasAnyMasstock });
                    $scope.displayCharterBlock = $scope.view.data.products.any(function (productItem) { return productItem.isCharter });
                    if (mrflyWidgets['mrfly-widget-banners']) {
                        mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.displayCharterBlock = $scope.displayCharterBlock;
                    }
                    //Kiwi
                    $scope.hasAnyKiwi = $scope.view.data.products.any(function (productItem) {
                        return productItem.inbounds && productItem.inbounds.length > 0 ? productItem.inbounds[0].proposal.kiwi : productItem.outbound.proposal.kiwi;
                    });

                    if ($scope.hasAnyKiwi) {
                        getBundleWidget("mrfly-widget-modal.js").done(function () {

                            $scope.$on("showPopInSelfConnect", function () {
                                mrflyWidgets['reducModal'].$store.commit("showModal");
                            });
                            $.ajax({
                                url: '/api/api/kiwi/GetPageKiwi',
                                data: '',
                                dataType: 'json',
                                contentType: 'application/json; charset=utf-8',
                                type: 'GET',
                                async: false,
                                success: function (response) {
                                    mrflyWidgets['reducModal'].$refs.modal.additionalData = response;
                                    mrflyWidgets['reducModal'].$refs.modal.width = 850;
                                    mrflyWidgets['reducModal'].$refs.modal.showHeader = true;
                                },
                                error: function () {

                                }
                            });

                        }).fail(function (jqxhr, settings, exception) {
                            console.log(exception);
                        });
                    }

                    //Get all cabin classes
                    $scope.displayAlertCabinClass = false;
                    $scope.allCabinClassesInbound = $scope.view.data.products.select(function (prod) {
                        if (prod.inbounds == null) return [];
                        return prod.inbounds.select(function (inb) {
                            return inb.segments.select(function (seg) { return seg.cabinClass });
                        });
                    }).toSimpleArray().distinct();

                    $scope.allCabinClassesOutbound = $scope.view.data.products.select(function (prod) {
                        return prod.outbound.segments.select(function (seg) {
                            return seg.cabinClass;
                        });
                    }).toSimpleArray().distinct();

                    $scope.allCabinClasses = $scope.allCabinClassesInbound.concat($scope.allCabinClassesOutbound).toSimpleArray().distinct();

                    if ($scope.view.data.request.cabinClass != 'Y') {
                        $.each($scope.allCabinClasses, function (index, value) {
                            if (value != $scope.view.data.request.cabinClass) {
                                if (!$scope.displayAlertCabinClass) {
                                    if ($scope.view.data.request.cabinClass == "C" && value == "J")
                                        $scope.displayAlertCabinClass = false;
                                    else if ($scope.view.data.request.cabinClass == "F" && value == "P")
                                        $scope.displayAlertCabinClass = false;
                                    else
                                        $scope.displayAlertCabinClass = true;
                                }
                            }
                        });
                    }
                    //end get all cabin classes
                    //**************************************************************************************************************************

                    view.data._lateProducts = [];
                    //console.log("PRODUCTS");
                    //console.log(view.data.products);
                    //console.log("END PRODUCTS");




                    var loadPbyP = function (pIdx) {
                        if (view.data.products.length <= 10) {

                            view.data._lateProducts = view.data.products;
                            $window.sendInspectlet('virtualPage');
                            $window.sendInspectlet("pageUrl", $window.getUrl('Flightsearch/Result'));
                            //Add InspecLet Cabin Class and Flight Type tag
                            $window.sendInspectlet('tagSession', { CabinClass: $scope.searchForm.cabin });
                            if ($scope.requestSummary.oneWay)
                                $window.sendInspectlet('tagSession', { SearchType: "Go Flight" });
                            if ($scope.requestSummary.openJaw)
                                $window.sendInspectlet('tagSession', { SearchType: "Multi Flight" });
                            if ($scope.requestSummary.roundTrip)
                                $window.sendInspectlet('tagSession', { SearchType: "Return Flight" });
                            if ($scope.requestSummary.childs > 0)
                                $window.sendInspectlet('tagSession', { Childs: true });
                            if ($scope.requestSummary.infants > 0)
                                $window.sendInspectlet('tagSession', { Infants: true });



                        }
                        else {
                            $timeout(function () {
                                if (view.data.products.length > pIdx) {
                                    view.data._lateProducts.push(view.data.products[pIdx]);
                                    loadPbyP(++pIdx);
                                }
                                else {
                                    //Add InspecLet Cabin Class and Flight Type tag
                                    $window.sendInspectlet('virtualPage');
                                    $window.sendInspectlet("pageUrl", $window.getUrl('Flightsearch/Result'));
                                    $window.sendInspectlet('tagSession', { CabinClass: $scope.searchForm.cabin });
                                    if ($scope.requestSummary.oneWay)
                                        $window.sendInspectlet('tagSession', { SearchType: "Go Flight" });
                                    if ($scope.requestSummary.openJaw)
                                        $window.sendInspectlet('tagSession', { SearchType: "Multi Flight" });
                                    if ($scope.requestSummary.roundTrip)
                                        $window.sendInspectlet('tagSession', { SearchType: "Return Flight" });
                                    if ($scope.requestSummary.childs > 0)
                                        $window.sendInspectlet('tagSession', { Childs: true });
                                    if ($scope.requestSummary.infants > 0)
                                        $window.sendInspectlet('tagSession', { Infants: true });
                                }
                            });
                        }
                    };
                    loadPbyP(0);

                    //Refresh SearchBox
                    $window.FSelectMPG_Refresh();

                    FFlightsUpdated({
                        vFlightsCount: view.productsCount,
                        callback: function () {
                            $scope.applyContextForScroll();
                        },
                        itemRender: function (item) { $scope.applyContextOpenProduct(item); }
                    }); // NICO ADDED 15-04-08

                    if (!firstRender)
                        $scope.saveContext(true);

                    $scope.restoreViewFromContext();
                    //Calculate warning messages for cheaper products
                    if (view.minPriceProduct) {
                        var minPriceProductOutbound = view.minPriceProduct.outbound;
                        $scope.minPriceOnOutboundDeparture = !view.appliedFiltersValue.outbound.departure.contains(minPriceProductOutbound.departure);
                        $scope.minPriceOnOutboundArrival = !view.appliedFiltersValue.outbound.arrival.contains(minPriceProductOutbound.arrival);
                        $scope.minPriceAirportIATA = ($scope.minPriceOnOutboundDeparture) ? minPriceProductOutbound.departure : minPriceProductOutbound.arrival;
                        $scope.minPriceOnOutbound = $scope.minPriceOnOutboundDeparture || $scope.minPriceOnOutboundArrival;
                    }

                    if (mrflyWidgets['mrfly-widget-banners']) {
                        var minPriceAirportIATA = $scope.minPriceAirportIATA;
                        mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.minPriceAirportName = view.data.airports[minPriceAirportIATA].name;
                        mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.minPriceAirport = view.minPriceProduct.minAverageFareFeeMargin.value;
                        mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.minPriceProductDelta = view.minPriceProductDelta.value;

                        mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.minPriceOnOutbound = $scope.minPriceOnOutbound;
                        mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.minPriceOnOutboundDeparture = $scope.minPriceOnOutboundDeparture;
                        mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.minPriceOnOutboundArrival = $scope.minPriceOnOutboundArrival;

                        var byLeavingFrom = $scope.getT('resultpage.byleavingfrom');
                        var byArrivingIn = $scope.getT('resultpage.byarrivingin');;
                        mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.byLeavingFrom = byLeavingFrom;
                        mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.byArrivingIn = byArrivingIn;
                        mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.multiDeparturesOptions = $scope.multiDeparturesOptions;

                        mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.showMultiDepartureOptions = false;

                        $scope.multiDeparturesOptions = [];
                        //multideparture for b2b be market
                        if (window.site.type == 2 && window.market.toLowerCase() === "be" && !window.disableMultiDeparture) {
                            if (window.site.currentAirRequest && window.site.currentAirRequest.summary.itineraries.length > 0) {
                                var departureOption = $scope.listMultiDeparturesOptions.find(function (ele) {
                                    return ele.departureIata === window.site.currentAirRequest.summary.itineraries[0].origin.cityIATA;
                                });

                                if (departureOption) {

                                    jQuery.ajax({
                                        type: 'GET',
                                        traditional: true,
                                        contentType: 'application/json; charset=utf-8',
                                        dataType: 'json',
                                        url: '/api/api/autocomplete/Cities',
                                        data: { listCityIata: departureOption.listIATA },
                                        success: function (result) {
                                            mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.showMultiDepartureOptions = true;
                                            result.forEach(function (el) {
                                                var regExpUrlSearch = "airresults/([^?]+)";
                                                var regExpOrigin = "origin=([^&]+)";
                                                el.UrlSearch = window.location.href.replace(new RegExp(regExpUrlSearch), "airsearch");
                                                el.UrlSearch = el.UrlSearch.replace(new RegExp(regExpOrigin), "origin=" + el.cityIATA);
                                            });
                                            $scope.multiDeparturesOptions = result;
                                            if (mrflyWidgets['mrfly-widget-banners']) {
                                                mrflyWidgets['mrfly-widget-banners'].$refs.bannersList.multiDeparturesOptions = $scope.multiDeparturesOptions;
                                            }
                                        }
                                    });


                                }
                            }
                        }


                    }

                    if (firstRender) {
                        //Calculate if Airport IATAS requested has results.
                        $scope.containsOriginIATA = $scope.view.data.products.any(function (p) {
                            return $scope.requestSummary.origin.airportIATA ? p.outbound.origin === $scope.requestSummary.origin.airportIATA || p.outbound.departure === $scope.requestSummary.origin.airportIATA : true;
                        });
                        $scope.containsDestinationIATA = $scope.view.data.products.any(function (p) {
                            return $scope.requestSummary.destination.airportIATA ? p.outbound.destination === $scope.requestSummary.destination.airportIATA || p.outbound.arrival === $scope.requestSummary.destination.airportIATA : true;
                        });
                        $scope.containsOrigin2IATA = true;
                        if ($scope.requestSummary.origin2) {
                            $scope.containsOrigin2IATA = $scope.view.data.products.any(function (p) {
                                return $scope.requestSummary.origin2.airportIATA ? p.inbounds.any(function (i) { return i.origin === $scope.requestSummary.origin2.airportIATA || i.departure === $scope.requestSummary.origin2.airportIATA; }) : true;
                            });
                        }
                        $scope.containsDestination2IATA = true;
                        if ($scope.requestSummary.destination2) {
                            $scope.containsDestination2IATA = $scope.view.data.products.any(function (p) {
                                return $scope.requestSummary.destination2.airportIATA ? p.inbounds.any(function (i) { return i.destination === $scope.requestSummary.destination2.airportIATA || i.arrival === $scope.requestSummary.destination2.airportIATA; }) : true;
                            });
                        }
                        $scope.containsIATAS = $scope.containsOriginIATA && $scope.containsDestinationIATA && $scope.containsOrigin2IATA && $scope.containsDestination2IATA;

                        $timeout(function () {
                            FPopNoFee(); //Or Call The Function
                        }, 25);
                    }

                    firstRender = false;

                    //Tracking
                    try {
                        //GTM
                        if (trackingService.isActivated()) {
                            trackingService.initDataLayer('detail');
                            trackingService.addProduct(gtmProduct.parseRequest($scope.requestSummary).applyFilter(view));
                            trackingService.parseScope($scope);
                            trackingService.pushDataLayer('list', 'Search');

                            if (trackingService.pageViewEvent) {
                                trackingService.pageViewEvent("search_result_page", "airresults");
                            }

                            if (trackingService.interactionEvent) {
                                trackingService.interactionEvent("view_search_results", null, null);
                            }
                        }
                        //omniture
                        if (omnitureService.isActivated()) {
                            console.info("=========================  send cdtm SEARCH_PAGE -- 1/3 =================");
                            omnitureService.sendCdtm(omnitureService.SEARCH_PAGE);
                            omnitureService.sendEvent(omnitureService.SEARCH_PAGE, omnitureService.SEARCH_PAGE, $scope.requestSummary);
                        }

                        if (window.site.partnerId == 'cdiscountvol') {
                            console.log("avant  le track Air Product body. js  ===");

                            var endDate = $scope.requestSummary.oneWay ? '' : moment($scope.requestSummary.itineraries.last().goDate.date).format("YYYY-MM-DD");

                            console.log("END DATE IS = " + endDate);

                            var cabinClassName = function (item) {
                                switch (item) {
                                    case "Y":
                                        return "economy";
                                    case "S":
                                        return "premium";
                                    case "C":
                                        return "business";
                                    case "F":
                                        return "first";
                                    default:
                                        return "economy";
                                }
                            };

                            fbq('track', 'Search',
                                {
                                    type_service: 'VOYAGES',
                                    content_category: 'VOL',
                                    content_type: 'flight',
                                    content_ids: $scope.requestSummary.origin.cityIATA + '-' + $scope.requestSummary.destination.cityIATA,
                                    origin_airport: $scope.requestSummary.origin.cityIATA,
                                    destination_airport: $scope.requestSummary.destination.cityIATA,
                                    departing_departure_date: moment($scope.requestSummary.goDate.date).format("YYYY-MM-DD"),
                                    returning_departure_date: endDate,
                                    num_adults: $scope.requestSummary.adults > 0 ? $scope.requestSummary.adults : 0,
                                    num_children: $scope.requestSummary.childs > 0 ? $scope.requestSummary.adults : 0,
                                    num_infants: $scope.requestSummary.infants > 0 ? $scope.requestSummary.adults : 0,
                                    travel_class: cabinClassName($scope.requestSummary.cabinClass),
                                    price: $scope.view.minPriceProductVisible.minTotalFare.value
                                });
                            console.log("appres le track  Air Product body. js ===");
                        }
                    }
                    catch (ex) {
                        console.log(ex.message);
                        $window.trackingError = ex.message;
                    }

                }, initialFilters, null, initialOrder);


            }
            $scope.searchDirectRoutes();
        }





        //***********************************************************************************************************************
        //Context stuff
        $scope.currentContext = null;
        $scope.ensureContext = function (force) {
            if ($scope.currentContext == null || force) {
                tseUtil.Debug.log("Creating new Context Object");
                $scope.currentContext = {
                    scrollX: $window.scrollX,
                    scrollY: $window.scrollY,
                    openedProducts: Array.prototype.map.call(document.getElementsByClassName("c-flight-on-back"), function (elm) { return elm.getAttribute("name"); }),
                    appliedFiltersValue: $scope.view.appliedFiltersValue,
                    appliedFilters: $scope.view.appliedFilters,
                    anyAppliedFilters: $scope.view.anyAppliedFilters,
                    appliedOrderBy: $("#i-sort-pop .c-on").index()
                };
            }
        };
        $scope.applyContext = function () {
            if ($scope.currentContext != null) {
                $timeout(function () {
                    var resultsContext = $scope.currentContext;
                    if (resultsContext) {
                        tseUtil.Debug.log("Context: Open products.");

                        //Open all previous opened products
                        resultsContext.openedProducts.each(function (key) {
                            var productDOM = document.getElementsByName(key);
                            var productIndex = productDOM[0].getAttribute("data-index");
                            var selectedProduct = $scope.view.data.products.first(function (product) { return product.outbound.key == key; });

                            if (selectedProduct != null && selectedProduct.inbounds != null && selectedProduct.inbounds.length > 0) {
                                selectedProduct._lateInbounds = selectedProduct.inbounds;
                                $window.FFBack(productIndex);
                            }
                        });

                        tseUtil.Debug.log("Context: Restore scroll position.");

                        //Scroll down to the previous position
                        $window.scrollTo(resultsContext.scrollX, resultsContext.scrollY);

                        tseUtil.Debug.log("Previous context loaded.");
                    }
                }, 0);
            };
        };
        var applyContextOpenProductAlreadyScroll = false;
        $scope.applyContextOpenProduct = function (product) {
            $scope.$apply(function () {
                if ($scope.currentContext != null && product != null) {
                    var resultsContext = $scope.currentContext;
                    var productIndex = product.getAttribute("data-index");
                    var productName = product.getAttribute("name");
                    if (resultsContext.openedProducts.any(function (item) { return item == productName })) {
                        var selectedProduct = $scope.view.data.products.first(function (product) { return product.outbound.key == productName; });
                        if (selectedProduct != null && selectedProduct.inbounds != null && selectedProduct.inbounds.length > 0) {
                            selectedProduct._lateInbounds = selectedProduct.inbounds;
                            $window.FFBack(productIndex);
                        }
                    }
                    if (product.offsetTop >= resultsContext.scrollY && !applyContextOpenProductAlreadyScroll) {
                        $window.scrollTo(resultsContext.scrollX, resultsContext.scrollY);
                        applyContextOpenProductAlreadyScroll = true;
                    }
                    //else if (!applyContextOpenProductAlreadyScroll) {
                    //    $window.scrollTo(0, product.offsetTop);
                    //}
                }
            });
        };
        $scope.applyContextForScroll = function () {
            $scope.$apply(function () {
                if ($scope.currentContext != null && !applyContextOpenProductAlreadyScroll) {
                    var resultsContext = $scope.currentContext;

                    //Scroll down to the previous position
                    $window.scrollTo(resultsContext.scrollX, resultsContext.scrollY);
                }
            });
        };
        $scope.loadContext = function () {
            $scope.currentContext = tseUtil.Session.get("resultContext_" + $scope.requestSummary.requestId);
        };
        $scope.saveContext = function (newContext) {
            $scope.ensureContext(newContext);
            tseUtil.Debug.log("Saving Context");
            tseUtil.Session.set("resultContext_" + $scope.requestSummary.requestId, $scope.currentContext);
        };

        //***********************************************************************************************************************
        //Restore Filters and Sort from Context
        $scope.restoreViewFromContext = function () {
            if ($scope.currentContext != null) {
                tseUtil.Debug.log("Restoring Filters and Sort Items.");

                //Now we update the filters view
                var status = $scope.view.appliedFilters;
                var filters = $scope.currentContext.appliedFiltersValue;

                if (filters.averageFareFeeMargin == null)
                    return;

                //Time Filters
                $("#i-slider-filter-time-go [name='my-slider-a']").val(Math.floor(filters.outbound.departureTime.from.totalHours));
                $("#i-slider-filter-time-go [name='my-slider-b']").val(Math.ceil(filters.outbound.departureTime.to.totalHours));
                if (filters.inbound != null && filters.inbound.departureTime != null) {
                    $("#i-slider-filter-time-back [name='my-slider-a']").val(Math.floor(filters.inbound.departureTime.from.totalHours));
                    $("#i-slider-filter-time-back [name='my-slider-b']").val(Math.ceil(filters.inbound.departureTime.to.totalHours));
                }

                //Price Filters
                $("#i-slider-filter-price [name='my-slider-e']").val(Math.ceil(filters.averageFareFeeMargin.to.value));

                //Stops Filters
                $("#i-filter-stops-pop li").removeClass("c-on");
                console.log("!!!!!!!!!!!!------------!!!!!!!!!!!!!!!!!");
                console.log(status.stops);
                if (status.stops) {
                    var maxStops = filters.stops.max();
                    if (maxStops == -2) {
                        $("#atLeastOneDirectFilterId").addClass("c-on");
                    }
                    $("[name='i-filter-stops']").val(maxStops);
                    var stopsText = $("#i-filter-stops-pop li[data-value=" + maxStops + "]").addClass("c-on").text();
                    $("#i-filter-stops-text").text(stopsText);
                }
                else {
                    $("[name='i-filter-stops']").val(-1);
                    $("#i-filter-stops-text").text(getT('resultpage.stopovers'));
                    $("#i-filter-stops-pop li").first().addClass("c-on");
                }

                //Airports
                //Outbound Airports
                $("#airportOutboundFilter [type='checkbox']").attr("Checked", false);
                $("#airportOutboundFilter [type='checkbox']").each(function () {
                    if (filters.outbound.departure.contains($(this).data("value")))
                        $(this).attr("Checked", true);
                });
                $("#airportOutboundFilter2 [type='checkbox']").attr("Checked", false);
                $("#airportOutboundFilter2 [type='checkbox']").each(function () {
                    if (filters.outbound.arrival.contains($(this).data("value")))
                        $(this).attr("Checked", true);
                });

                //Inbound or Arrival for OneWay Airports
                $("#airportInboundFilter [type='checkbox']").attr("Checked", false);
                $("#airportInboundFilter [type='checkbox']").each(function () {
                    if (filters.inbound != null && filters.inbound.departure != null) {
                        if (filters.inbound.departure.contains($(this).data("value")))
                            $(this).attr("Checked", true);
                    }
                });
                $("#airportInboundFilter2 [type='checkbox']").attr("Checked", false);
                $("#airportInboundFilter2 [type='checkbox']").each(function () {
                    if (filters.inbound != null && filters.inbound.arrival != null) {
                        if (filters.inbound.arrival.contains($(this).data("value")))
                            $(this).attr("Checked", true);
                    }
                });

                //Baggages
                $("#baggagesFilter [type='checkbox']").attr("Checked", false);
                if (!filters.baggages.contains(0)) {
                    filters.baggages.each(function (bag) {
                        $("#baggagesFilter [type='checkbox'][data-value=" + bag + "]").attr("Checked", true);
                    });
                }

                //Update the sort view
                if (typeof $scope.currentContext.appliedOrderBy != 'undefined')
                    $("#i-sort-text").text($($("#i-sort-pop li").removeClass("c-on")[$scope.currentContext.appliedOrderBy]).addClass("c-on").data("text"));

                //TO Fares
                $("#productsTypesFilter [type='checkbox']").attr("Checked", false);
                $("#productsTypesFilter [type='checkbox']").each(function () {
                    if (filters.productTypes.contains($(this).data("value")))
                        $(this).attr("Checked", true);
                });
            };
        };


        //***********************************************************************************************************************
        //Inspectlet Stuff

        $scope.SendInspectlet = function (item) {
            switch (item) {
                case 0:
                    //Send inspectlet tag of use Stops filter
                    $window.sendInspectlet('tagSession', { FilterStops: true });
                    break;
                case 1:
                    //Send inspectlet tag of use Airports filter
                    $window.sendInspectlet('tagSession', { FilterAirports: true });
                    break;
                case 2:
                    //Send inspectlet tag of use Luggages filter
                    $window.sendInspectlet('tagSession', { FilterLuggages: true });
                    break;
                case 3:
                    //Send inspectlet tag of use Airlines filter
                    $window.sendInspectlet('tagSession', { FilterAirlines: true });
                    break;
                case 4:
                    //Send inspectlet tag of changed Sort Method
                    $window.sendInspectlet('tagSession', { SortChanged: true });
                    break;
            }
        };

        //Filters stuff
        $scope.popTimeGoFilter = function () {
            //Time Go Slider
            $("#i-slider-filter-time-go").empty();
            //Send inspectlet tag of use horaires filter
            $window.sendInspectlet('tagSession', { FilterHoraires: true });
            var appliedFiltersValue = $scope.view.appliedFiltersValue;
            var initialFiltersValue = $scope.view.initialFiltersValue;
            if (appliedFiltersValue.outbound != null && appliedFiltersValue.outbound.departureTime != null) {
                var opts = {
                    iSlider: 'i-slider-filter-time-go',
                    vDual: true,
                    vDefault: Math.floor(appliedFiltersValue.outbound.departureTime.from.totalHours),
                    vDefaultDual: Math.ceil(appliedFiltersValue.outbound.departureTime.to.totalHours),
                    vMin: 0,
                    vMax: 24,
                    vMinDrag: Math.floor(initialFiltersValue.outbound.departureTime.from.totalHours), // NICO 15-04-08
                    vMaxDrag: Math.ceil(initialFiltersValue.outbound.departureTime.to.totalHours), // NICO 15-04-08
                    vMinShow: false,
                    vMaxShow: false,
                    vText: '{VAL}h',
                    vInputName: 'my-slider-a',
                    vInputDualName: 'my-slider-b',
                    onSlideCallBack: $scope.updateFilters
                };
                XLRangeSlider(opts);
            }
        };
        //Filters stuff
        $scope.popTimeGoFilterArrival = function () {
            //Time Go Slider
            $("#i-slider-filter-time-go-arrival").empty();
            //Send inspectlet tag of use horaires filter
            $window.sendInspectlet('tagSession', { FilterHoraires: true });
            var appliedFiltersValue = $scope.view.appliedFiltersValue;
            var initialFiltersValue = $scope.view.initialFiltersValue;
            if (appliedFiltersValue.outbound != null && appliedFiltersValue.outbound.arrivalTime != null) {
                var opts = {
                    iSlider: 'i-slider-filter-time-go-arrival',
                    vDual: true,
                    vDefault: Math.floor(appliedFiltersValue.outbound.arrivalTime.from.totalHours),
                    vDefaultDual: Math.ceil(appliedFiltersValue.outbound.arrivalTime.to.totalHours),
                    vMin: 0,
                    vMax: 24,
                    vMinDrag: Math.floor(initialFiltersValue.outbound.arrivalTime.from.totalHours), // NICO 15-04-08
                    vMaxDrag: Math.ceil(initialFiltersValue.outbound.arrivalTime.to.totalHours), // NICO 15-04-08
                    vMinShow: false,
                    vMaxShow: false,
                    vText: '{VAL}h',
                    vInputName: 'my-slider-a',
                    vInputDualName: 'my-slider-b',
                    onSlideCallBack: $scope.updateFilters
                };
                XLRangeSlider(opts);
            }
        };
        $scope.popTimeReturnFilter = function () {
            //Time Return Slider
            //Send inspectlet tag of use horaires filter
            $window.sendInspectlet('tagSession', { FilterHoraires: true });
            $("#i-slider-filter-time-back").empty();
            var appliedFiltersValue = $scope.view.appliedFiltersValue;
            var initialFiltersValue = $scope.view.initialFiltersValue;
            if (appliedFiltersValue.inbound != null && appliedFiltersValue.inbound.departureTime != null) {
                var opts = {
                    iSlider: 'i-slider-filter-time-back',
                    vDual: true,
                    vDefault: Math.floor(appliedFiltersValue.inbound.departureTime.from.totalHours),
                    vDefaultDual: Math.ceil(appliedFiltersValue.inbound.departureTime.to.totalHours),
                    vMin: 0,
                    vMax: 24,
                    vMinDrag: Math.floor(initialFiltersValue.inbound.departureTime.from.totalHours), // NICO 15-04-08
                    vMaxDrag: Math.ceil(initialFiltersValue.inbound.departureTime.to.totalHours), // NICO 15-04-08
                    vMinShow: false,
                    vMaxShow: false,
                    vText: '{VAL}h',
                    vInputName: 'my-slider-a',
                    vInputDualName: 'my-slider-b',
                    onSlideCallBack: $scope.updateFilters
                };
                XLRangeSlider(opts);
            }
        };
        $scope.popTimeReturnFilterArrival = function () {
            //Time Return Slider
            //Send inspectlet tag of use horaires filter
            $window.sendInspectlet('tagSession', { FilterHoraires: true });
            $("#i-slider-filter-time-back-arrival").empty();
            var appliedFiltersValue = $scope.view.appliedFiltersValue;
            var initialFiltersValue = $scope.view.initialFiltersValue;
            if (appliedFiltersValue.inbound != null && appliedFiltersValue.inbound.arrivalTime != null) {
                var opts = {
                    iSlider: 'i-slider-filter-time-back-arrival',
                    vDual: true,
                    vDefault: Math.floor(appliedFiltersValue.inbound.arrivalTime.from.totalHours),
                    vDefaultDual: Math.ceil(appliedFiltersValue.inbound.arrivalTime.to.totalHours),
                    vMin: 0,
                    vMax: 24,
                    vMinDrag: Math.floor(initialFiltersValue.inbound.arrivalTime.from.totalHours), // NICO 15-04-08
                    vMaxDrag: Math.ceil(initialFiltersValue.inbound.arrivalTime.to.totalHours), // NICO 15-04-08
                    vMinShow: false,
                    vMaxShow: false,
                    vText: '{VAL}h',
                    vInputName: 'my-slider-a',
                    vInputDualName: 'my-slider-b',
                    onSlideCallBack: $scope.updateFilters
                };
                XLRangeSlider(opts);
            }
        };
        $scope.popPriceFilter = function () {
            $("#i-slider-filter-price").empty();
            //Send inspectlet tag of use price filter
            $window.sendInspectlet('tagSession', { FilterPrice: true });
            var appliedFiltersValue = $scope.view.appliedFiltersValue;
            var initialFiltersValue = $scope.view.initialFiltersValue;
            var initialPrice = initialFiltersValue.averageFareFeeMargin;
            var currentPrice = appliedFiltersValue.averageFareFeeMargin;
            if (currentPrice != null) {
                var opts = {
                    iSlider: 'i-slider-filter-price',
                    vDefault: Math.ceil(currentPrice.to.value),
                    vMin: Math.ceil(initialPrice.from.value),
                    vMax: Math.ceil(initialPrice.to.value),
                    vMinShow: false,
                    vMaxShow: false,
                    vText: (getT('resultpage.lessthan')) + ' {VAL}€',
                    vInputName: 'my-slider-e',
                    onSlideCallBack: $scope.updateFilters
                };
                XLRangeSlider(opts);
            }
        };
        $scope.updateFilters = function () {
            $scope.$apply(function () {
                tseUtil.Debug.log("Updating filters...");

                var initialFilters = $scope.view.initialFiltersValue;
                var oFilters = $window.objectClone($scope.view.appliedFiltersValue);
                var nFilters = $scope.view.appliedFiltersValue;
                var change = { type: [], o: [], n: [], prevCount: $scope.view.productsCount };

                //Time GoFlight
                var goFlightMinTime = parseInt(angular.element("#i-slider-filter-time-go [name='my-slider-a']").val());
                var goFlightMaxTime = parseInt(angular.element("#i-slider-filter-time-go [name='my-slider-b']").val());
                var goFlightMinTimeInitial = parseInt(initialFilters.outbound.departureTime.from.HH);
                var goFlightMaxTimeInitial = parseInt(initialFilters.outbound.departureTime.to.HH);
                parseInt(initialFilters.outbound.departureTime.to.mm) > 0 ? goFlightMaxTimeInitial = goFlightMaxTimeInitial + 1 : goFlightMaxTimeInitial = goFlightMaxTimeInitial;
                if (goFlightMinTime.toString() != "NaN")
                    nFilters.outbound.departureTime.from = airHelper.getTimeObject(goFlightMinTime * 60);
                if (goFlightMaxTime.toString() != "NaN")
                    nFilters.outbound.departureTime.to = airHelper.getTimeObject(goFlightMaxTime * 60);

                if (nFilters.outbound.departureTime.from.value != oFilters.outbound.departureTime.from.value) {
                    change.type.push("OutboundDepartureTimeFrom");
                    change.o.push(oFilters.outbound.departureTime.from.value);
                    change.n.push(nFilters.outbound.departureTime.from.value);
                }
                if (nFilters.outbound.departureTime.to.value != oFilters.outbound.departureTime.to.value) {
                    change.type.push("OutboundDepartureTimeTo");
                    change.o.push(oFilters.outbound.departureTime.to.value);
                    change.n.push(nFilters.outbound.departureTime.to.value);
                }
                parseInt(nFilters.outbound.departureTime.from.HH) != goFlightMinTimeInitial || parseInt(nFilters.outbound.departureTime.to.HH) != goFlightMaxTimeInitial ? angular.element("#i-button-filter-time-go i").css('display', 'inline-block') : angular.element("#i-button-filter-time-go i").css('display', 'none');

                //Time GoFlightArrival
                var goFlightArrivalMinTime = parseInt(angular.element("#i-slider-filter-time-go-arrival [name='my-slider-a']").val());
                var goFlightArrivalMaxTime = parseInt(angular.element("#i-slider-filter-time-go-arrival [name='my-slider-b']").val());
                var goFlightArrivalMinTimeInitial = parseInt(initialFilters.outbound.arrivalTime.from.HH);
                var goFlightArrivalMaxTimeInitial = parseInt(initialFilters.outbound.arrivalTime.to.HH);
                parseInt(initialFilters.outbound.arrivalTime.to.mm) > 0 ? goFlightArrivalMaxTimeInitial = goFlightArrivalMaxTimeInitial + 1 : goFlightArrivalMaxTimeInitial = goFlightArrivalMaxTimeInitial;
                if (goFlightArrivalMinTime.toString() != "NaN")
                    nFilters.outbound.arrivalTime.from = airHelper.getTimeObject(goFlightArrivalMinTime * 60);
                if (goFlightArrivalMaxTime.toString() != "NaN")
                    nFilters.outbound.arrivalTime.to = airHelper.getTimeObject(goFlightArrivalMaxTime * 60);

                if (nFilters.outbound.arrivalTime.from.value != oFilters.outbound.arrivalTime.from.value) {
                    change.type.push("OutboundArrivalTimeFrom");
                    change.o.push(oFilters.outbound.arrivalTime.from.value);
                    change.n.push(nFilters.outbound.arrivalTime.from.value);
                }
                if (nFilters.outbound.arrivalTime.to.value != oFilters.outbound.arrivalTime.to.value) {
                    change.type.push("OutboundArrivalTimeTo");
                    change.o.push(oFilters.outbound.arrivalTime.to.value);
                    change.n.push(nFilters.outbound.arrivalTime.to.value);
                }
                parseInt(nFilters.outbound.arrivalTime.from.HH) != goFlightArrivalMinTimeInitial || parseInt(nFilters.outbound.arrivalTime.to.HH) != goFlightArrivalMaxTimeInitial ? angular.element("#i-button-filter-time-go-arrival i").css('display', 'inline-block') : angular.element("#i-button-filter-time-go-arrival i").css('display', 'none');

                //Time ReturnFlight
                if (nFilters.inbound != null && nFilters.inbound.departureTime != null) {
                    var returnFlightMinTime = parseInt(angular.element("#i-slider-filter-time-back [name='my-slider-a']").val());
                    var returnFlightMaxTime = parseInt(angular.element("#i-slider-filter-time-back [name='my-slider-b']").val());
                    var returnFlightMinTimeInitial = parseInt(initialFilters.inbound.departureTime.from.HH);
                    var returnFlightMaxTimeInitial = parseInt(initialFilters.inbound.departureTime.to.HH);
                    parseInt(initialFilters.inbound.departureTime.to.mm) > 0 ? returnFlightMaxTimeInitial = returnFlightMaxTimeInitial + 1 : returnFlightMaxTimeInitial = returnFlightMaxTimeInitial;
                    if (returnFlightMinTime.toString() != "NaN")
                        nFilters.inbound.departureTime.from = airHelper.getTimeObject(returnFlightMinTime * 60);
                    if (returnFlightMaxTime.toString() != "NaN")
                        nFilters.inbound.departureTime.to = airHelper.getTimeObject(returnFlightMaxTime * 60);
                }

                if (nFilters.inbound.departureTime.from.value != oFilters.inbound.departureTime.from.value) {
                    change.type.push("InboundDepartureTimeFrom");
                    change.o.push(oFilters.inbound.departureTime.from.value);
                    change.n.push(nFilters.inbound.departureTime.from.value);
                }
                if (nFilters.inbound.departureTime.to.value != oFilters.inbound.departureTime.to.value) {
                    change.type.push("InboundDepartureTimeTo");
                    change.o.push(oFilters.inbound.departureTime.to.value);
                    change.n.push(nFilters.inbound.departureTime.to.value);
                }
                parseInt(nFilters.inbound.departureTime.from.HH) != returnFlightMinTimeInitial || parseInt(nFilters.inbound.departureTime.to.HH) != returnFlightMaxTimeInitial ? angular.element("#i-button-filter-time-back i").css('display', 'inline-block') : angular.element("#i-button-filter-time-back i").css('display', 'none');

                //Time ReturnFlightArrival
                if (nFilters.inbound != null && nFilters.inbound.arrivalTime != null) {
                    var returnFlightArrivalMinTime = parseInt(angular.element("#i-slider-filter-time-back-arrival [name='my-slider-a']").val());
                    var returnFlightArrivalMaxTime = parseInt(angular.element("#i-slider-filter-time-back-arrival [name='my-slider-b']").val());
                    var returnFlightArrivalMinTimeInitial = parseInt(initialFilters.inbound.arrivalTime.from.HH);
                    var returnFlightArrivalMaxTimeInitial = parseInt(initialFilters.inbound.arrivalTime.to.HH);
                    parseInt(initialFilters.inbound.arrivalTime.to.mm) > 0 ? returnFlightArrivalMaxTimeInitial = returnFlightArrivalMaxTimeInitial + 1 : returnFlightArrivalMaxTimeInitial = returnFlightArrivalMaxTimeInitial;
                    if (returnFlightArrivalMinTime.toString() != "NaN")
                        nFilters.inbound.arrivalTime.from = airHelper.getTimeObject(returnFlightArrivalMinTime * 60);
                    if (returnFlightArrivalMaxTime.toString() != "NaN")
                        nFilters.inbound.arrivalTime.to = airHelper.getTimeObject(returnFlightArrivalMaxTime * 60);
                }

                if (nFilters.inbound.arrivalTime.from.value != oFilters.inbound.arrivalTime.from.value) {
                    change.type.push("InboundArrivalTimeFrom");
                    change.o.push(oFilters.inbound.arrivalTime.from.value);
                    change.n.push(nFilters.inbound.arrivalTime.from.value);
                }
                if (nFilters.inbound.arrivalTime.to.value != oFilters.inbound.arrivalTime.to.value) {
                    change.type.push("InboundArrivalTimeTo");
                    change.o.push(oFilters.inbound.arrivalTime.to.value);
                    change.n.push(nFilters.inbound.arrivalTime.to.value);
                }
                parseInt(nFilters.inbound.arrivalTime.from.HH) != returnFlightArrivalMinTimeInitial || parseInt(nFilters.inbound.arrivalTime.to.HH) != returnFlightArrivalMaxTimeInitial ? angular.element("#i-button-filter-time-back-arrival i").css('display', 'inline-block') : angular.element("#i-button-filter-time-back-arrival i").css('display', 'none');

                //Price
                var minPrice = parseInt(angular.element("#i-slider-filter-price [name='my-slider-e']").val());
                if (minPrice.toString() != "NaN") {
                    nFilters.averageFareFeeMargin.to = airHelper.getNumberObject(Math.ceil(minPrice));
                }

                if (nFilters.averageFareFeeMargin.to.value != oFilters.averageFareFeeMargin.to.value) {
                    change.type.push("MaxPrice");
                    change.o.push(oFilters.averageFareFeeMargin.to.value);
                    change.n.push(nFilters.averageFareFeeMargin.to.value);
                }

                //Stops
                var stops = parseInt(angular.element("[name='i-filter-stops']").val());
                console.log("STOP : " + stops);

                if (stops == -1) {
                    nFilters.stops = initialFilters.stops;
                } else if (stops == -2) {
                    var stp = [-2];
                    nFilters.stops = stp;
                }
                else {
                    var stp = [];
                    for (var i = 0; i <= stops; i++) stp.push(i);
                    nFilters.stops = stp;
                }

                console.log(" nFilters.stops : " + nFilters.stops);

                var ostp = nFilters.stops.intersect(oFilters.stops);
                if (nFilters.stops.length != oFilters.stops.length || ostp.length != oFilters.stops.length) {
                    change.type.push("Stops");
                    change.o.push(oFilters.stops.join(","));
                    change.n.push(nFilters.stops.join(","));
                }

                //Airports
                var outboundDepartureAirports = [];
                angular.element("#airportOutboundFilter [type='checkbox']:checked").each(function () { outboundDepartureAirports.push($(this).data("value")); });
                if (initialFilters.outbound.departure.length == 1)
                    outboundDepartureAirports = initialFilters.outbound.departure;

                var outboundArrivalAirports = [];
                angular.element("#airportOutboundFilter2 [type='checkbox']:checked").each(function () { outboundArrivalAirports.push($(this).data("value")); });
                if (initialFilters.outbound.arrival.length == 1)
                    outboundArrivalAirports = initialFilters.outbound.arrival;

                var inboundDepartureAirports = [];
                angular.element("#airportInboundFilter [type='checkbox']:checked").each(function () { inboundDepartureAirports.push($(this).data("value")); });
                if (initialFilters.inbound.departure && initialFilters.inbound.departure.length == 1)
                    inboundDepartureAirports = initialFilters.inbound.departure;

                var inboundArrivalAirports = [];
                angular.element("#airportInboundFilter2 [type='checkbox']:checked").each(function () { inboundArrivalAirports.push($(this).data("value")); });
                if (initialFilters.inbound.arrival && initialFilters.inbound.arrival.length == 1)
                    inboundArrivalAirports = initialFilters.inbound.arrival;

                if ($scope.requestSummary.oneWay) {
                    nFilters.outbound.departure = outboundDepartureAirports;
                    nFilters.outbound.arrival = outboundArrivalAirports;
                }
                else if ($scope.requestSummary.roundTrip) {
                    nFilters.outbound.departure = outboundDepartureAirports;
                    nFilters.outbound.arrival = inboundDepartureAirports;

                    nFilters.inbound.departure = inboundDepartureAirports;
                    nFilters.inbound.arrival = outboundDepartureAirports;
                }
                else if ($scope.requestSummary.openJaw) {
                    nFilters.outbound.departure = outboundDepartureAirports;
                    nFilters.outbound.arrival = outboundArrivalAirports;

                    nFilters.inbound.departure = inboundDepartureAirports;
                    nFilters.inbound.arrival = inboundArrivalAirports;
                }


                var iSecOutboundDeparture = nFilters.outbound.departure.intersect(oFilters.outbound.departure);
                if (nFilters.outbound.departure.length != oFilters.outbound.departure.length || iSecOutboundDeparture.length != oFilters.outbound.departure.length) {
                    change.type.push("OutboundDepartureAirports");
                    change.o.push(oFilters.outbound.departure.join(","));
                    change.n.push(nFilters.outbound.departure.join(","));
                }
                var iSecOutboundArrival = nFilters.outbound.arrival.intersect(oFilters.outbound.arrival);
                if (nFilters.outbound.arrival.length != oFilters.outbound.arrival.length || iSecOutboundArrival.length != oFilters.outbound.arrival.length) {
                    change.type.push("OutboundArrivalAirports");
                    change.o.push(oFilters.outbound.arrival.join(","));
                    change.n.push(nFilters.outbound.arrival.join(","));
                }
                if (nFilters.inbound) {
                    var iSecInboundDeparture = nFilters.inbound.departure.intersect(oFilters.inbound.departure);
                    if (nFilters.inbound.departure.length != oFilters.inbound.departure.length || iSecInboundDeparture.length != oFilters.inbound.departure.length) {
                        change.type.push("InboundDepartureAirports");
                        change.o.push(oFilters.inbound.departure.join(","));
                        change.n.push(nFilters.inbound.departure.join(","));
                    }
                    var iSecInboundArrival = nFilters.inbound.arrival.intersect(oFilters.inbound.arrival);
                    if (nFilters.inbound.arrival.length != oFilters.inbound.arrival.length || iSecInboundArrival.length != oFilters.inbound.arrival.length) {
                        change.type.push("InboundArrivalAirports");
                        change.o.push(oFilters.inbound.arrival.join(","));
                        change.n.push(nFilters.inbound.arrival.join(","));
                    }
                }

                //Airlines
                var airlines = [];


                //Check all or None Airlines
                if ($scope.hasToggleAllAndNoneMarketingCarriersFilters) {
                    var allAndNoneToggle = document.getElementById('i-check-allandnone-marketing-carriers');
                    airlines = $scope.view.initialFiltersValue.marketingCarriers;
                    if (allAndNoneToggle != null) {
                        if (allAndNoneToggle.checked) {
                            $scope.isAllAndNoneToggleChecked = true;
                        } else {
                            $scope.isAllAndNoneToggleChecked = false;
                        }
                    }
                    $scope.hasToggleAllAndNoneMarketingCarriersFilters = false;

                } else {
                    angular.element("#airlinesFilter [type='checkbox']:checked").each(function () { airlines.push($(this).data("value")); });
                    if (airlines.length == 0) {
                        airlines = $scope.view.initialFiltersValue.marketingCarriers;
                        $scope.isAllAndNoneToggleChecked = false;
                    }
                    else if (airlines.length == $scope.view.initialFiltersValue.marketingCarriers.length) {
                        $("#i-check-allandnone-marketing-carriers").attr("checked", true);
                        $scope.isAllAndNoneToggleChecked = true;
                    }
                    else {
                        $("#i-check-allandnone-marketing-carriers").removeAttr("checked");
                        $scope.isAllAndNoneToggleChecked = false;
                    }
                }

                nFilters.marketingCarriers = airlines;

                var iSecMkt = nFilters.marketingCarriers.intersect(oFilters.marketingCarriers);
                if (nFilters.marketingCarriers.length != oFilters.marketingCarriers.length || iSecMkt.length != oFilters.marketingCarriers.length) {
                    change.type.push("Airlines");
                    change.o.push(oFilters.marketingCarriers.join(","));
                    change.n.push(nFilters.marketingCarriers.join(","));
                }

                // Expansion filter 
                var expansionFilter = document.getElementById('i-check-expand-marketing-carriers');
                if (expansionFilter != null && expansionFilter.checked) {
                    nFilters.expandMarketingCarriers = true;
                } else {
                    nFilters.expandMarketingCarriers = false;
                }

                //Baggages
                var baggages = [];
                angular.element("#baggagesFilter [type='checkbox']:checked").each(function () { baggages.push($(this).data("value")); });
                if (baggages.length == 0) {
                    nFilters.baggages = initialFilters.baggages;
                    nFilters.outbound.baggages = initialFilters.outbound.baggages;
                    if (nFilters.inbound != null) nFilters.inbound.baggages = initialFilters.inbound.baggages;
                }
                else {
                    nFilters.baggages = baggages;
                    nFilters.outbound.baggages = baggages;
                    if (nFilters.inbound != null) nFilters.inbound.baggages = baggages;
                }

                var iSecBags = nFilters.baggages.intersect(oFilters.baggages);
                if (nFilters.baggages.length != oFilters.baggages.length || iSecBags.length != oFilters.baggages.length) {
                    change.type.push("Baggages");
                    change.o.push(oFilters.baggages.join(","));
                    change.n.push(nFilters.baggages.join(","));
                }

                //TO
                var productTypes = [];
                var i = 0;
                for (i = 1; i <= 2; i++) {
                    if ($('#i-check-t' + i).is(':checked')) {
                        var elem = document.getElementById('i-check-t' + i);
                        productTypes.push(parseInt(elem.dataset.value));
                    }
                }
                if (productTypes.length == 0)
                    nFilters.productTypes = $scope.view.initialFiltersValue.productTypes;
                else
                    nFilters.productTypes = productTypes;

                $scope.airResults.applyFilters(nFilters, null, null, null);
            });
        }.getBuffered(200);

        $scope.clearFilters = function () {
            var nFilters = $scope.view.initialFiltersValue;
            angular.element("#i-filters-menu li").removeClass("c-on");
            angular.element("#i-filter-times-pop .c-btn i").css('display', 'none');
            $scope.airResults.applyFilters(nFilters, null, null, null);
        };
        $scope.updateAlliances = function () {
            var alliance = angular.element("[name='i-filter-airlines']").val();
            if (alliance == -1)
                angular.element("#airlinesFilter [type='checkbox']").attr("checked", true);
            else {
                var airlines = $window.objectToArray($scope.view.data.airlines, false).grep(function (item) { return item.alliance == alliance }).map(function (item) { return item.iata; });
                angular.element("#airlinesFilter [type='checkbox']").each(function () {
                    $(this).attr("checked", airlines.containsSimilar($(this).data("value")) ? true : null);
                });
            }
            $scope.updateFilters();
        };
        $scope.updateAirlines = function () {
            //angular.element("#i-filter-airlines-text").text("Compagnies");
            //angular.element("#ulAlliances li").removeClass("c-on");
            //angular.element("#ulAlliances li").first().addClass("c-on");
            $scope.updateFilters();
        };

        $scope.toggleAllAndNoneMarketingCarriersFilter = function () {
            $scope.hasToggleAllAndNoneMarketingCarriersFilters = true;
            $scope.updateFilters();
        };

        $scope.ItemRendering = function (idx, container) {
            if ($window["onproductrendering"] !== undefined) {
                var htmlValue = $window["onproductrendering"](idx, container);
                return (htmlValue !== undefined) ? htmlValue : "";
            }
            return "";
        };

        //***********************************************************************************************************************
        //Sort stuff
        var sType = ["Price", "FlightTime", "DepartureTime", "ArrivalTime", "Co2"];
        $scope.sortResults = function () {
            var sortIdx = $("#i-sort-pop .c-on").index();
            console.log("ok papi    !! ==>  " + sortIdx);
            $scope.airResults.orderBy(ordersBy[sortIdx]);
            $scope.sortingPriority = sType[sortIdx];
        };


        var orderByPrix = function (product) { return undefined };
        if ($scope.isServair) {
            orderByPrix = function (product) { return product.minTotalSubvention.value; };
        }
        else {
            orderByPrix = function (product) { return product.minAverageFareFeeMargin.value; };
        }

        var orderByDeparture = function (product) { return product.outbound.departureTime.value; };
        var orderByArrival = function (product) { return product.outbound.arrivalTime.value; };
        var orderByTotalTime = function (product) { return product.outbound.totalTime.value; };
        var orderByCO2 = function (product) {
            if (typeof (product.outbound.co2Data) !== 'undefined') {
                if (product.outbound.co2Data.hasAllSegmentsData) {
                    return product.outbound.co2Data.co2Average;
                }
            }
            return 1000;
        };
        var orderByDirectRoute = function (product) {
            return product.outbound.stopsCount;
        };

        $scope.putDirectRouteAtFirst = function () {
            if ($scope.hasDirectRoute === false) {
                return false;
            }
            return $scope.firstDirectRoute === true;
        }

        $scope.putDirectRouteAtLast = function () {
            if ($scope.hasDirectRoute === false) {
                return false;
            }
            return $scope.firstDirectRoute !== true;
        }

        $scope.searchDirectRoutes = function () {
            $scope.hasDirectRoute = false;
            angular.forEach($scope.rawResults.products, function (prod, indexP) {
                if (prod.outbound.stopsCount === 0) {
                    $scope.hasDirectRoute = true;
                    return;
                }
            });
            ordersBy = fillOrdersBy();
            $scope.sortResults();
        }

        var fillOrdersBy = function () {
            if ($scope.putDirectRouteAtFirst() === true) {
                return [
                    [orderByDirectRoute, orderByPrix, orderByTotalTime, orderByDeparture, orderByArrival],  //directRoute
                    [orderByPrix, orderByDeparture, orderByArrival, orderByTotalTime, orderByCO2],          //price
                    [orderByTotalTime, orderByPrix, orderByDeparture, orderByArrival, orderByCO2],          //flightTime
                    [orderByDeparture, orderByPrix, orderByArrival, orderByTotalTime, orderByCO2],          //departureTime
                    [orderByArrival, orderByPrix, orderByTotalTime, orderByDeparture, orderByCO2],          //arrivalTime
                    [orderByCO2, orderByPrix, orderByTotalTime, orderByDeparture, orderByArrival]           //co2Consumption
                ];
            }
            else if ($scope.putDirectRouteAtLast() === true) {
                return [
                    [orderByPrix, orderByDeparture, orderByArrival, orderByTotalTime, orderByCO2],          //price
                    [orderByTotalTime, orderByPrix, orderByDeparture, orderByArrival, orderByCO2],          //flightTime
                    [orderByDeparture, orderByPrix, orderByArrival, orderByTotalTime, orderByCO2],          //departureTime
                    [orderByArrival, orderByPrix, orderByTotalTime, orderByDeparture, orderByCO2],          //arrivalTime
                    [orderByCO2, orderByPrix, orderByTotalTime, orderByDeparture, orderByArrival],          //co2Consumption
                    [orderByDirectRoute, orderByPrix, orderByTotalTime, orderByDeparture, orderByArrival]   //directRoute

                ];
            }
            else {
                return [
                    [orderByPrix, orderByDeparture, orderByArrival, orderByTotalTime, orderByCO2],          //price
                    [orderByTotalTime, orderByPrix, orderByDeparture, orderByArrival, orderByCO2],          //flightTime
                    [orderByDeparture, orderByPrix, orderByArrival, orderByTotalTime, orderByCO2],          //departureTime
                    [orderByArrival, orderByPrix, orderByTotalTime, orderByDeparture, orderByCO2],          //arrivalTime
                    [orderByCO2, orderByPrix, orderByTotalTime, orderByDeparture, orderByArrival]           //co2Consumption
                ];
            }
        }
        var ordersBy = fillOrdersBy();

        //***********************************************************************************************************************
        //Booking Event
        $scope.$on("onProductBookingClick", function (event, args) {
            //tseUtil.Debug.log("Event Received");
            $window.FLoading({ vAction: 'show' });
            $scope.bkgEvent = event;
            $scope.bkgArgs = args;
            $scope.bkgDataKey = 'bkgData_' + $scope.requestSummary.requestId;

            //console.log('$SCOPE.REQUESTSUMMARY.REQUESTID: ' + $scope.requestSummary.requestId);

            $scope.saveContext(true);

            var co2Data = {};

            co2Data[$scope.bkgArgs.outbound.origin + $scope.bkgArgs.outbound.destination] = $scope.bkgArgs.outbound.co2Data;

            if ($scope.bkgArgs.inbound != null) {
                co2Data[$scope.bkgArgs.inbound.origin + $scope.bkgArgs.inbound.destination] = $scope.bkgArgs.inbound.co2Data;
            } 

            var bookingData = {
                key: $scope.bkgDataKey,
                value: {
                    purchaseRequest: $scope.bkgArgs.purchaseRequest,
                    outboundKey: $scope.bkgArgs.outbound.key,
                    request: $scope.requestSummary,
                    cabinclasses: $scope.view.data.cabinClasses,
                    co2Data: co2Data
                }
            };

            //Airbus
            if ($scope.site.type === 6 && $scope.site.loginInfo != null && $scope.site.loginInfo && $scope.site.loginInfo.Matricule != null) {
                bookingData.value.purchaseRequest.ceUser = $scope.site.loginInfo.Matricule;
            }

            //Add panier
            var submit = function () {

                var upsellParam = $window.getUrlVars()['upsell'];
                var isUpsell = typeof upsellParam !== 'undefined' && upsellParam === 'true';

                if ($window.site.onSecureScheme) {
                    tseUtil.Session.set(bookingData.key, bookingData.value);
                    tseUtil.Debug.log("BookingSession created!");
                    if ($window.enableUpSellProducts && $scope.requestSummary.roundTrip) {
                        tseUtil.Debug.log("______FIRST _____________");
                        $window.location.href = $window.site.currentAirRequest.upsellProducts;
                    }
                    else if (isUpsell) {
                        tseUtil.Debug.log("______TWOOOOO _____________");

                        var bkgData = tseUtil.Session.get('bkgData_' + $window.site.currentAirRequest.requestId);

                        if (bkgData) {

                            var purchaseResponse = { value: "" };

                            console.log(" ***** purchase response feeeeddd ");
                            tseUtil.Debug.log("***** purchase response feeeeddd ");

                            //Add extra data

                            bkgData.purchaseRequest.additionalData = {};
                            if ($scope.hasFlightHotelFormSummary) {
                                bkgData.purchaseRequest.additionalData["basketFormSummary"] = getCookie('FlightHotelFormSummary');
                            }
                           
                            //New Purchase Transaction
                            $scope.apiClient.PURCHASE.new(bkgData.purchaseRequest).success(function (data) {
                                if (data && data.message == null) {
                                    tseUtil.Debug.log("got answer from something !! " + JSON.stringify(data));
                                    console.log("got answer from something !! " + JSON.stringify(data));

                                    
                                    if (data.proposals[0].flexies && data.proposals[0].flexies.length > 0 && !$scope.isServair) {
                                        $scope.enableFreeFlex = data.proposals[0].flexies[0].propose === 2;
                                    }

                                    airHelper.preparePurchaseData(data);
                                    bkgData.purchaseResponse = data;

                                    purchaseResponse.value = data;
                                    bkgData.severalProposals = bkgData.purchaseResponse.proposals.length > 1;
                                    $scope.lastDate = bkgData.purchaseResponse.itineraries[bkgData.purchaseResponse.itineraries.length - 1].arrivalDateTime;

                                    var keyGenerated = bkgData.request.requestId + "_" + bkgData.purchaseRequest.index + "_" + bkgData.purchaseRequest.group + "_" + bkgData.purchaseRequest.proposal;
                                    tseUtil.Session.set("purchaseResponse_" + keyGenerated, data);

                                    // BookingRequest  Object to send to API
                                    bkgData.bookingRequest = {
                                        proposalIndex: "",
                                        bagChanged: false,
                                        requestId: bkgData.request.requestId,
                                        flexyFare: false,
                                        insurance: null,
                                        passengers: [],
                                        additionalData: [],
                                        buyer: {},
                                        contact: { mail: '', phone: '' },
                                        bestPriceFare: false
                                    };

                                    //Pax Init
                                    var paxAmount = [bkgData.request.adults, bkgData.request.childs, bkgData.request.infants];
                                    var init = true;

                                    for (var ptc in paxAmount) {
                                        if (paxAmount.hasOwnProperty(ptc)) {
                                            for (var i = 0; i < paxAmount[ptc]; i++) {
                                                bkgData.bookingRequest.passengers.push({ paxType: ptc });
                                            }
                                        }
                                    }

                                    if (init) {
                                        airHelper.preparePassengersData(bkgData.bookingRequest.passengers, bkgData.purchaseResponse);
                                    }

                                    var upsellProductsRedirect = $window.site.currentAirRequest.upsellProductsRedirect.replace(/&code=\d/i, '');
                                    var redirectUrl = $window.hotelUpSellProductRedirect;

                                    //avoid double slash in the url
                                    if (/\/$/.test(redirectUrl) === false) {
                                        redirectUrl += "/";
                                    }

                                    redirectUrl += "redirect.htm?purchaseId=" + bkgData.purchaseResponse.purchaseId;
                                    redirectUrl += "&requestId=" + bkgData.request.requestId;
                                    redirectUrl += "&lang=" + $window.lng.substr(0, 2);
                                    redirectUrl += "&redirect=" + encodeURIComponent(upsellProductsRedirect);
                                    redirectUrl += "&currency=" + $window.site.currency;

                                    //B2B
                                    if ($window.site.type === 2) {
                                        redirectUrl += "&mid=" + $window.site.loginInfo.Agent.AgentId;
                                        redirectUrl += "&lid=" + $window.site.loginInfo.LoginId;
                                    }

                                    //HOTEL TIMER: save koedia redirection time to init timer later
                                    var timerkey = "hoteltimerstart_" + bkgData.purchaseResponse.purchaseId;
                                    $window.sessionStorage.setItem(timerkey, new Date());

                                    $window.location.href = redirectUrl;
                                }
                                else {
                                    $window.location.href = $window.site.errorsUrls.GeneralError;
                                }
                            }).error(function (data) {
                                $window.location.href = $window.site.errorsUrls.GeneralError;
                            });
                        }
                    }
                    else {
                        tseUtil.Debug.log("______THREEEE _____________");
                        $window.location.href = $window.site.currentAirRequest.bookingUrl;
                        //console.log($window.site.currentAirRequest);
                    }
                }
                else {
                    tseUtil.Debug.log("______FOUR _____________");
                    $window.sslFrame.sendCmd("setSessionStorage", bookingData,
                        function (data) {
                            tseUtil.Debug.log("BookingSession created!");
                            if ((window.enableUpSellProducts || isUpsell) && $scope.requestSummary.roundTrip) {
                                $window.location.href = $window.site.currentAirRequest.upsellProducts;
                                //console.log($window.site.currentAirRequest);
                            } else {
                                $window.location.href = $window.site.currentAirRequest.bookingUrl;
                                //console.log($window.site.currentAirRequest);
                            }
                        });
                }
            };

            submit();
        });


        //Taxes details click
        $scope.$on("displayTaxesDetailsClick", function (event, args) {
            $scope.displayTaxesDetailModal = true;
            $scope.modalTaxesKey = args.key.replaceAll(":", "-");
            $scope.modalTaxesKey = $scope.modalTaxesKey.replaceAll("\\|", "-");
            $scope.modalTaxesKeyAux = $scope.modalTaxesKey + "-" + args.paxType;
            $scope.modalTaxesPaxType = args.paxType;
            $scope.modalTaxesPaxFares = args.paxFares;
            $scope.modalPaxNumber = args.paxNumber;
            $scope.$broadcast("updateTaxesDetailModalData", { key: $scope.modalTaxesKey, paxType: $scope.modalTaxesPaxType, fares: $scope.modalTaxesPaxFares, paxnumber: $scope.modalPaxNumber });

            $scope.modalShowMargin = false;

            args.marketingCarriers.each(function (value, key) {
                if ($scope.view.data.airlines[value].showMargin == true) {
                    $scope.modalShowMargin = true;
                }
            });

            //Remodal workaround
            $(document).find(".remodal").each(function (a, c) {
                var d = $(c), e = d.data("remodal-options");
                e ? ("string" == typeof e || e instanceof String) && (e = j(e)) : e = {},
                    d["remodal"](e)
            });

            $timeout(function () {
                $("[data-remodal-id='taxes-detail-modal-" + $scope.modalTaxesKeyAux + "']").remodal().open();
            },
                200, false);

        });

        $scope.$on("closeTaxesDetailsModal", function (event, args) {
            $scope.displayTaxesDetailModal = false;
        });

    }]);
