/*
    A service used to trigger GTM pushes
*/
webClient.factory('trackingService', ['$window', 'tcModel', 'tcProduct', 'scopeParser',
    function ($window, tcModel, tcProduct, scopeParser) {
        return {

            isActivated: function () {
                return typeof $window.dataLayer !== 'undefined' && $window.dataLayer != null;
            },

            initDataLayer: function (name) {
                
            },

            /*
                Reload data using JSON stored in window.sessionStorage
                The action name / value is used as key 
            */
            loadSessionData: function (action, value) {
                return null;

            },

            set: function(object, property, value) {
                console.log('SET ' + property + " - " + value);
                if (value) {
                    object[property] = value;
                }
            },

            /*
                Use to send page views. Distinct gtm object in order not to mess with ecommerce tunnel
            */
            pushPageData: function (page, title, language, fireEvent, args) {
            },

            addProduct: function (product) {
                
            },

            removeProduct: function (id) {
                
            },

            setOption: function (value) {
                
            },

            parseScope: function (value) {

            },

            pageViewEvent: function (pageType, page) {
                console.log("---ADP track ---> page_data Event ::  " + pageType);
                $window.dataLayer.push({ 'page_data': null });
                var pageViewEvent = {
                    'event': 'page_view',
                    'page_data': {
                        'page_location': document.location.href,
                        'page': page,
                        'page_type': pageType,
                        'type_environment': document.location.href.indexOf('.mrvol') == -1 ? 'prod' : 'preprod',
                        'language': window.lng.split('-').first()
                    }
                }
                console.info("============== show the pageViewEvent ============");
                console.info(pageViewEvent);

                $window.dataLayer.push(
                    pageViewEvent
                );
            },

            ecommerceEvent: function (value) {
                console.log("---ADP track après la confirmation  de paiement, n'envoyer que la première fois ---> ecommerce Event ::  " + value);
                var itineraries = $window.$scope.bkgData.purchaseResponse.itineraries;
                var nbVols = 0;
                for (var i = 0; i < itineraries.length; i++) {
                    nbVols += itineraries[i].segments.length;
                }
                $window.dataLayer.push({ 'ecommerce': null });
                var ecommerceEvent = {
                    'event': 'purchase',
                    'ecommerce': {
                        'transation_id': $window.$scope.bkgData.bookingResponse.numbers[0].bookingNumber,
                        'brand_name': $window.$scope.bkgData.purchaseResponse.platingCarrier,
                        'value': scopeParser.tryGetFinalAmount(),
                        'quantity': nbVols * scopeParser.tryGetPaxNumber()
                    }
                }

                console.info("============== show the ecommerceEvent ============");
                console.info(ecommerceEvent);
                $window.dataLayer.push(
                    ecommerceEvent
                );
            },

            interactionEvent: function (eventName, funnelName, funnelStep) {
                console.log("---ADP track ---> event_data Event ::  " + eventName);
                $window.dataLayer.push({ 'event_data': null });
                var interactionEvent = {};
                if (eventName == 'view_search_results') {
                    var r = $window.$scope.requestSummary;
                    var event_details = r.oneWay ? 'one way' : r.roundTrip ? 'round trip' : r.multiDestination ? 'multi destinations' : 'open Jaw';
                    interactionEvent = {
                        'event': eventName,
                        'event_data': {
                            'quantity': r.itineraries.length * scopeParser.tryGetPaxNumber(),
                            'localisation': scopeParser.getOriginCityIATA() + "-" + scopeParser.getDestinationCityIATA(),
                            'date_start': scopeParser.getDepartingDateWithDash(),
                            'date_end': scopeParser.getReturningDateWithDash(),
                            'event_details': event_details
                        }
                    }
                } else {
                    var interactionEvent = {
                        'event': eventName,
                        'event_data': {
                            'value': eventName == "funnel_step1" ? $window.$scope.bkgData.request.totalAmount.value : scopeParser.tryGetFinalAmount(),
                            'quantity': $window.$scope.bkgData.purchaseResponse.itineraries.length * scopeParser.tryGetPaxNumber(),
                            'brand_name': $window.$scope.bkgData.purchaseResponse.platingCarrier,
                            'localisation': scopeParser.getOriginCityIATA() + "-" + scopeParser.getDestinationCityIATA(),
                            'date_start': scopeParser.getDepartingDateWithDash(),
                            'date_end': scopeParser.getReturningDateWithDash(),
                            'funnel_name': funnelName,
                            'funnel_step': funnelStep
                        }
                    }
                }



                console.info("============== show the interactionEvent ============");
                console.info(interactionEvent);

                $window.dataLayer.push(
                    interactionEvent
                );
            },

            /*
                Serializes the GTM model and pushes the datalayer
            */
            pushDataLayer: function (action, value) {
            }



        }
    }]);
