/**
* @desc the ngPassengerInfo provides support for collect the information of a passenger. This is used in purchase 1
* @example <div ng-passenger-info pax="pax" paxinfo="paxinfo[$index]" position="$index" cards="cards" datelastarrival="datelastarrival"></div>
*/

webClient.directive('ngPassengerInfo', ['$window', '$timeout', '$i18next', 'templateService', 
    function ($window, $timeout, $i18next, templateService) {
    var directive = {};
    directive.restrict = 'EA';
    directive.scope = {
        pax: "=",
        paxinfo: "=",
        position: "=",
        cards: "=",
        datelastarrival: "=",
        mandatoryinformation: "=",
        remarkpassenger: "=",
        purchaseresponse: "=",
        unaccompaniedminorrule: "="
    };
    directive.link = function ($scope, $element) {

        templateService.compile($scope, $element, 'tpl/_BookingPaxsInfo.html');

        $scope.getUrl = $window.getUrl;
        $scope.getStaticFolder = $window.getStaticFolder;
        $scope.getGlobalStaticFolder = $window.getGlobalStaticFolder;
        $scope.getTypeStaticFolder = $window.getTypeStaticFolder;
        $scope.enableTravellerPhone = $window.enableTravellerPhone;
        $scope.enableBlockAgencyRemarks = $window.enableBlockAgencyRemarks;
        $scope.remarkPassenger = $scope.remarkpassenger && $scope.enableBlockAgencyRemarks;
        $scope.isServair = window.site.partnerId === "servair";
        $scope.isCnas = window.site.partnerId === "cnas";
        $scope.isAdosspp = window.site.partnerId === "adosspp";
        $scope.isCnasDomTom = window.site.partnerId === "cnasdomtom";
        $scope.siteType = $window.site.type;
        $scope.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        $scope.upSell = $window.getUrlVars()['upsell'] == 'true';
        $scope.forceSpecificAge = false;
        $scope.specificAge = 0;
        $scope.hasFlightHotelFormSummary = false;

        console.log("PAXINFO ngPassengerInfo");
        console.log($scope.paxinfo);
        if (typeof $scope.paxinfo === 'undefined') {
            $scope.paxinfo = {};
        }
        $scope.hasFlightHotelFormSummary = hasCookie("FlightHotelFormSummary") && $window.getUrlVars()['hotelSummary'] === 'true';

        var mndInfo = false;
        if ($scope.purchaseresponse.informationData.MandatoryInformation == "True") {
            mndInfo = true;
        }
        $scope.mandatoryPassport = $scope.purchaseresponse.kiwi && mndInfo;

        if ($window.isAirbus) $scope.isAirbus = $window.isAirbus;

        //Traduction
        $scope.getT = function (key) {            
            var res = $i18next.t(key.toLowerCase());            
            return res;
        };


        //Mandatory information for Paxes
        $scope.enableMandatoryInformationForPaxes = $window.enableMandatoryInformationForPaxes;
        $scope.mandatoryInformation = $scope.mandatoryinformation && $scope.enableMandatoryInformationForPaxes || $scope.mandatoryPassport;

        //Cambio para victor adp
        if ($window.site) {
            $scope.sitePartnerId = $window.site.partnerId;
        }

        if ($window.isAirbus) {
            $scope.setAirbusFirstPassenger = function () {
                if ($scope.airbusExternalloginInfo) {
                    $scope.paxinfo.lastName = $scope.airbusExternalloginInfo.Nom;
                    $scope.paxinfo.firstName = $scope.airbusExternalloginInfo.Prenom;
                }
            };
        }        

        $scope.setPassengerPhoneCountryCode = function (phoneCountryCode, countryCode) {
            console.log("We here phoneCountryCode : " + phoneCountryCode + "  -- and countryCode :  " + countryCode);  
            $scope.paxinfo.phoneCountryCode = phoneCountryCode;
            $scope.countryCode = countryCode;
            $scope.phoneCountryCode = phoneCountryCode;
        };



        var isTheFirstAdultPassenger = $scope.position == 0 && $scope.paxinfo.paxType == 0;
        
        if (isTheFirstAdultPassenger)
        {
            $timeout(function () {
                // add time out 
                $scope.listCountriesCode = $window.countriesCode;
                if (!$scope.paxinfo.phoneCountryCode) {
                    //$scope.setPassengerPhoneCountryCode('33', 'FR');
                    var langCaps = window.market ? window.market : lng.substring(3);

                    if ($scope.sitePartnerId.toLowerCase() === 'europa')
                        langCaps = "FR";

                    if(window.site.partnerId.substring(0,2) == "be"){
                        langCaps = "BE";
                    }

                    if (window.partnerName == "neckermann") {
                        langCaps = "BE";
                    }

                    var langItem = $scope.listCountriesCode.filter(function (item) { return item.iso.toLowerCase() === langCaps.toLowerCase(); }).first();
                    $scope.setPassengerPhoneCountryCode(langItem.code, langItem.iso);
                }
            else
                { 
                    if ($scope.listCountriesCode) {
                        var cCodesKey = $scope.listCountriesCode.groupByKey('code');
                    }

                    if (cCodesKey && cCodesKey[$scope.paxinfo.phoneCountryCode] && cCodesKey[$scope.paxinfo.phoneCountryCode].length > 0)
                        $scope.countryCode = cCodesKey[$scope.paxinfo.phoneCountryCode][0].iso;
                    else
                        $scope.countryCode = cCodesKey[$scope.paxinfo.phoneCountryCode].iso;

                    $scope.setPassengerPhoneCountryCode($scope.paxinfo.phoneCountryCode, $scope.countryCode);
                }
            }, 300);
            //
        }        
        //End Traveler Phone

        $scope.cardsKey = null;
        if ($scope.cards)
            $scope.cardsKey = $scope.cards.groupByKey("reference");
        $scope.cardName = "";

        if ($scope.paxinfo && !$scope.paxinfo.fidelityCardType && $scope.cards.length > 0)
            $scope.paxinfo.fidelityCardType = $scope.cards[0].reference;

        if ($scope.paxinfo && $scope.paxinfo.fidelityCardType) {
            if ($scope.cardsKey[$scope.paxinfo.fidelityCardType])
                $scope.cardName = $scope.cardsKey[$scope.paxinfo.fidelityCardType].name;
            else {
                $scope.paxinfo.fidelityCardType = null;
                $scope.paxinfo.fidelityCardNumber = null;
            }
        }

        $scope.countriesCode = [];
        $.each(countriesCode, function (i, el) {
            if ($.inArray(el, $scope.countriesCode) === -1) $scope.countriesCode.push(el);
        });
        var countriesCodeKey = countriesCode.groupByKey('iso');
        $scope.countryName = "";

        if ($scope.paxinfo && $scope.paxinfo.passportCountry && $scope.paxinfo.passportCountry != "") {
            var country = countriesCodeKey[$scope.paxinfo.passportCountry];
            if (country.length > 0)
                $scope.countryName = country[0].name;
            else
                $scope.countryName = country.name;
        }

        $scope.callDrop = function (_event, type, mode) {
            var varIsCountry = (type == 'country') ? 1 : 0;
            var name = 'i-' + type + '-' + $scope.position;
            var value = $("#ul-" + type + "-" + $scope.position).get(0);
            $window.FSelect({ e: _event, myObj: value, vName: name, isCountry: varIsCountry, vMode: mode });
        };

        $scope.callTitlePop = function (_event, type) {
            var value = _event.target;
            console.log("$scope.callTitlePop: " + value);
            var name = 'i-' + type + '-' + $scope.position + '-pop';
            $window.FPop({ oTarget: value, iPop: name, vStyle: 'classic' });
        };
        $scope.callSearchList = function (_event, type, o) {
            var value = _event.target;
            var name = 'i-' + type + '-' + $scope.position + '-ul';
            $window.searchInList(value, name, o);
        };
        $scope.openAddInfo = function (pos) {
            //Add InspecLet Completed tag
            window.sendInspectlet('tagSession', { PlusDInfoUsed: true });
            var name = 'i-smooth-' + pos + "-h";
            var opener = $("#smooth-" + pos).get(0);
            if ($("#i-open-addinfo-" + pos).is(':checked')) {
                $("#i-open-addinfo-cont-" + pos).addClass('c-new-form-cont--active');
            } else {
                $("#i-open-addinfo-cont-" + pos).removeClass('c-new-form-cont--active');
            }
            $window.FSmoothOpen({ oOpener: opener, iSmooth: name });
        };


        //Add Flags in combo box's
        $timeout(function () {
            $window.FCountriesFlags({ iUl: 'i-country-' + $scope.position + '-ul' });


            //$window.FCountriesFlags({ iUl: 'i-title-' + $scope.position + '-text' });
            //Flags in Travller phone must be added only for firts passenger
            if (isTheFirstAdultPassenger) {
                $window.FCountriesFlags({ iUl: 'i-phone-codes-' + $scope.position + '-ul' });
            }
        }, 300);


        $scope.paxinfo.paxType = $scope.pax.key;
        $window.XLFormInit({ iCont: 'i-form' });

        $scope.$watch('paxinfo.birthDate', function () {
            $timeout(function () {
                if ($scope.paxinfo.birthDate !== "") {
                    var dateString = $scope.datelastarrival;

                    if (!$scope.hasFlightHotelFormSummary) {
                        if ($scope.paxinfo.paxType === "0") {
                            var dateMin = moment(dateString).subtract(100, 'years');
                            $('input', '#pax-' + $scope.position).attr('data-error-date-min', $i18next.t('airbooking.errorscheck.invaliddate'));
                            if (window.site.partnerId === "visabusiness") {
                                var dateMax = moment(dateString).subtract(18, 'years');
                                $('input', '#pax-' + $scope.position).attr('data-error-date-max', $i18next.t('airbooking.paxsinfo.unadultevisa'));
                            } else {
                                var dateMax = moment(dateString).subtract(12, 'years');
                                $('input', '#pax-' + $scope.position).attr('data-error-date-max', $i18next.t('airbooking.paxsinfo.unadulte'));
                            }
                        }
                        else if ($scope.paxinfo.paxType === "1") {
                            var dateMin = moment(dateString).subtract(12, 'years');
                            var dateMax = moment(dateString).subtract(2, 'years');
                            $('input', '#pax-' + $scope.position).attr('data-error-date-min', $i18next.t('airbooking.twotoelleven'));
                            $('input', '#pax-' + $scope.position).attr('data-error-date-max', $i18next.t('airbooking.twotoelleven'));


                        }
                        else if ($scope.paxinfo.paxType === "2") {
                            var dateMin = moment(dateString).subtract(2, 'years');
                            var dateMax = moment(dateString);
                            dateMin = dateMin.add(1, 'days');
                            $('input', '#pax-' + $scope.position).attr('data-error-date-min', $i18next.t('airbooking.onetwentythree'));
                            $('input', '#pax-' + $scope.position).attr('data-error-date-max', $i18next.t('airbooking.onetwentythree'));
                        }
                    } else {
                        if ($scope.paxinfo.paxTypeHotel == "ADT") {
                            var dateMin = moment(dateString).subtract(100, 'years');
                            $('input', '#pax-' + $scope.position).attr('data-error-date-min', $i18next.t('airbooking.errorscheck.invaliddate'));
                            var dateMax = moment(dateString).subtract(18, 'years');
                            $('input', '#pax-' + $scope.position).attr('data-error-date-max', $i18next.t('airbooking.paxsinfo.unadultevisa'));
                        }
                        if ($scope.paxinfo.paxTypeHotel == "CHD") {
                            var dateMin = moment(dateString).subtract(parseInt($scope.paxinfo.specificAge) + 1, 'years');
                            var dateMax = moment(dateString).subtract($scope.paxinfo.specificAge, 'years');
                            $('input', '#pax-' + $scope.position).attr('data-error-date-min', $i18next.t('airbooking.twotoseventeen'));
                            $('input', '#pax-' + $scope.position).attr('data-error-date-max', $i18next.t('airbooking.twotoseventeen'));
                        }
                        if ($scope.paxinfo.paxTypeHotel == "INF") {
                            var dateMin = moment(dateString).subtract(2, 'years');
                            var dateMax = moment(dateString);
                            dateMin = dateMin.add(1, 'days');
                            $('input', '#pax-' + $scope.position).attr('data-error-date-min', $i18next.t('airbooking.onetwentythree'));
                            $('input', '#pax-' + $scope.position).attr('data-error-date-max', $i18next.t('airbooking.onetwentythree'));
                        }
                    }
                };

                // NICO ********************************
                dateMin = dateMin.format('DD/MM/YYYY');
                dateMax = dateMax.format('DD/MM/YYYY');
           
                $('input', '#pax-' + $scope.position).attr('data-check', "{check:'date',date_min:'" + dateMin + "',date_max:'" + dateMax + "',birthday:true,nolive:true}");
                $('input', '#pax-' + $scope.position).attr('data-error-addon', $scope.paxinfo.paxType);
                // NICO ********************************
            }, 300);
        }, true);

        $scope.$on('updateNameAlert', function (e, data) {

            var index = data;

            $(".paxinfo-lastname[double]").each(function () {
                XLFieldCheck({ oObj: $(this)[0] });
            });

            if (index > 0) {
                XLFormValidStyle({ oObj: $(".paxinfo-lastname")[index], vError: true, vErrorCode: 'pax-same-name' });
                $(".paxinfo-lastname:eq(" + index + ")").attr('double', '');

            }
        });


        $scope.passportNumberChanged=  function () {
            if ($scope.paxinfo.passportNumber != undefined && $scope.paxinfo.passportNumber != "")
            {
                $("#i-pport-exp-" + $scope.position).attr('data-check', "{check:'date'}");
                $("#i-country-"+$scope.position+"-text").attr('data-check', "{check:'required'}");
            }                
            else
            {
                //ppor exp
                $("#i-pport-exp-" + $scope.position).removeAttr('data-check', "{check:'date'}");
                $("#i-pport-exp-" + $scope.position).val("");
                $scope.paxinfo.passportExpireDate = "";
                $("#i-pport-exp-" + $scope.position).parent().removeClass("c-field-ok");

                //ppor number
                $("#i-pport-" + $scope.position).parent().removeClass("c-field-ok");

                //country
                $scope.paxinfo.passportCountry = "";
                $("#i-country-" + $scope.position + "-text").removeAttr('data-check', "{check:'required'}");
                $("#i-country-" + $scope.position + "-text").html("");
                $("#i-country-" + $scope.position + "-text").parent().removeClass("c-field-ok");
            }

            var val = $scope.paxinfo.passportNumber;
            val = val.replace(/ /g, '');
            $scope.paxinfo.passportNumber = val;            
        };
    };
    return directive;
}]);
