webClient.controller('AirPaymentBe2Bill', ['$scope', '$window', '$timeout', '$i18next', 'tseApiClient', 'tseUtil', 'tseAirResultsHelper', 'trackingService', 'gtmProduct', 'omnitureService',
    function ($scope, $window, $timeout, $i18next, tseApiClient, tseUtil, airHelper, trackingService, gtmProduct, omnitureService) {
        $scope.getText = function (key) { return window.culture.texts[key]; };
        $scope.apiClient = tseApiClient.createClient($window.site.apiUrl, $window.site.apiUrlSSL);
        var searchUrl = $window.site.currentAirRequest.searchUrl + "?" + tseUtil.Location.getQueryString();

        var bkgData = tseUtil.Session.get('bkgData_' + $window.site.currentAirRequest.requestId);
        $scope.bkgData = bkgData;
        window.$scope = $scope;
        window.displayBrandedFaresSummary = true;
        $scope.enableUpSellProducts = $window.enableUpSellProducts || $window.sessionStorage.getItem('forceUpsell') === 'true';
        $scope.accommodation = null;
        $scope.siteType = window.site.type;

        getBundleWidget("mrfly-widget-loader.js").done(function () {

        });

        var hfields = null;
        if ($window.isAirbus) $scope.isAirbus = $window.isAirbus;
        //Add Tracking facebook
        try {
            if (window.site.partnerId == 'cdiscountvol') {
                var returningDeparturedate = '';
                var departingArrivaldate = '';
                var destinationAirport = '';
                var returningArrivalDate = '';
                //Multi destination
                if ($scope.bkgData.request.multiDestination) {
                    var itinerariesMultuDestination = $scope.bkgData.request.itineraries;
                    for (var i = 0; i <= itinerariesMultuDestination.length; i++) {
                        var itinerariesData = $scope.bkgData.purchaseResponse.itineraries[i];
                        destinationAirport = itinerariesData.destination.cityIATA;
                        var itinerariesSegment = itinerariesData.segments[itinerariesData.segments.length - 1].arrivalDateTime;
                        departingArrivaldate = moment(itinerariesSegment).format("DD-MM-YYYY");
                        //!function (f, b, e, v, n, t, s) {
                        //    if (f.fbq) return; n = f.fbq = function () { n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments) };
                        //    if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = [];
                        //    t = b.createElement(e); t.async = !0; t.src = v; s = b.getElementsByTagName(e)[0];
                        //    s.parentNode.insertBefore(t, s)
                        //}(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
                        //fbq('init', '317884282206794');
                        //fbq('track', 'InitiateCheckout',
                        //    {
                        //        content_type: 'flight',
                        //        departing_departure_date: moment(itinerariesData.date).format("DD-MM-YYYY"),
                        //        returning_departure_date: returningDeparturedate,
                        //        origin_airport: itinerariesData.origin.cityIATA,
                        //        destination_airport: destinationAirport,
                        //        departing_arrival_date: moment(itinerariesData.date).format("DD-MM-YYYY"),
                        //        returning_arrival_date: returningArrivalDate,
                        //        num_adults: $scope.bkgData.request.adults > 0 ? $scope.bkgData.request.adults : 0,
                        //        num_children: $scope.bkgData.request.childs > 0 ? $scope.bkgData.request.childs : 0,
                        //        num_infants: $scope.bkgData.request.infants > 0 ? $scope.bkgData.request.infants : 0,
                        //        travel_class: $scope.bkgData.request.cabinClass == 'Y' ? 'economy' : 'business'
                        //    });
                    }
                }
            } else {
                //Aller/Retour and Aller simple
                if ($scope.bkgData.request.roundTrip) {
                    var itinerariesData = $scope.bkgData.purchaseResponse.itineraries[0];
                    var itinerariesSegment = itinerariesData.segments[itinerariesData.segments.length - 1].arrivalDateTime;
                    returningDeparturedate = moment(itinerariesSegment).format("DD-MM-YYYY");
                    departingArrivaldate = moment($scope.bkgData.request.goDate2.date).format("DD-MM-YYYY");
                    returningArrivalDate = moment($scope.bkgData.purchaseResponse.itineraries[1].segments[itinerariesData.segments.length - 1].arrivalDateTime).format("DD-MM-YYYY");
                    destinationAirport = $scope.bkgData.request.origin2.cityIATA;
                } else {
                    if ($scope.bkgData.request.oneWay) {
                        destinationAirport = $scope.bkgData.request.destination.cityIATA;
                        var itinerariesData = $scope.bkgData.purchaseResponse.itineraries[0];
                        var itinerariesSegment = itinerariesData.segments[itinerariesData.segments.length - 1].arrivalDateTime;
                        returningArrivalDate = departingArrivaldate = returningDeparturedate = moment(itinerariesSegment).format("DD-MM-YYYY");
                    }
                }
                //!function (f, b, e, v, n, t, s) {
                //    if (f.fbq) return; n = f.fbq = function () { n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments) };
                //    if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = [];
                //    t = b.createElement(e); t.async = !0; t.src = v; s = b.getElementsByTagName(e)[0];
                //    s.parentNode.insertBefore(t, s)
                //}(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
                //fbq('init', '317884282206794');
                //fbq('track', 'InitiateCheckout',
                //    {
                //        content_type: 'flight',
                //        departing_departure_date: moment($scope.bkgData.request.goDate.date).format("DD-MM-YYYY"),
                //        returning_departure_date: returningDeparturedate,
                //        origin_airport: $scope.bkgData.request.origin.cityIATA,
                //        destination_airport: destinationAirport,
                //        departing_arrival_date: departingArrivaldate,
                //        returning_arrival_date: returningArrivalDate,
                //        num_adults: $scope.bkgData.request.adults > 0 ? $scope.bkgData.request.adults : 0,
                //        num_children: $scope.bkgData.request.childs > 0 ? $scope.bkgData.request.childs : 0,
                //        num_infants: $scope.bkgData.request.infants > 0 ? $scope.bkgData.request.infants : 0,
                //        travel_class: $scope.bkgData.request.cabinClass == 'Y' ? 'economy' : 'business'
                //    });
            }
        }
        catch (ex) {
            console.log(ex.message);
            $window.trackingError = ex.message;
        }

        //Tracking
        try {
            //GTM
            if (trackingService.isActivated()) {
                trackingService.initDataLayer('checkout');
                trackingService.loadSessionData('step', 3);
                trackingService.setOption($scope.bkgData.bookingResponse.paymentMethod.paymentMethodName);
                trackingService.parseScope($scope);
                trackingService.pushDataLayer('step', 4);
            }

            //omniture
            if (omnitureService.isActivated()) {
                omnitureService.sendEvent(omnitureService.PURCHASE_4_VOL, omnitureService.FLIGHT_TUNNEL, null);
            }

            console.log("on utilise ce composant ??? ==> AirPaymentBody Be2biill .js");

            if (window.site.partnerId == 'cdiscountvol') {

                console.log("avant le tracking de fbq cdiscount Initiate Checkout Air Payment Body Be2Bill");

                var departingDepartureDate = moment($scope.bkgData.purchaseResponse.itineraries.first().departureDateTime).format("YYYY-MM-DD");
                console.log(" =====> departingDepartureDate IS = " + departingDepartureDate);

                var departingArrivalDate = moment($scope.bkgData.purchaseResponse.itineraries.first().arrivalDateTime).format("YYYY-MM-DD");

                console.log(" =====> departingArrivalDate IS = " + departingArrivalDate);


                var returningDepartureDate = $scope.bkgData.request.oneWay ? '' : moment($scope.bkgData.purchaseResponse.itineraries.last().departureDateTime).format("YYYY-MM-DD");
                console.log(" =====> returningDepartureDate IS = " + returningDepartureDate);

                var returningArrivalDate = $scope.bkgData.request.oneWay ? '' : moment($scope.bkgData.purchaseResponse.itineraries.last().arrivalDateTime).format("YYYY-MM-DD");
                console.log(" =====> returningArrivalDate IS = " + returningArrivalDate);

                function waitForfbq() {
                    if (typeof fbq === "undefined") {
                        requestAnimationFrame(waitForfbq);
                    }
                    fbq('track', 'InitiateCheckout', {
                        type_service: 'VOYAGES',
                        content_category: 'VOL',
                        content_type: 'flight',
                        content_ids: $scope.bkgData.request.origin.cityIATA + '-' + $scope.bkgData.request.destination.cityIATA,
                        value: $scope.bkgData.prixTotal.value,
                        currency: 'EUR',
                        origin_airport: $scope.bkgData.purchaseResponse.itineraries[0].origin.airportIata,
                        destination_airport: $scope.bkgData.purchaseResponse.itineraries[0].destination.airportIata,
                        departing_departure_date: departingDepartureDate,
                        returning_departure_date: returningDepartureDate,
                        departing_arrival_date: departingArrivalDate,
                        returning_arrival_date: returningArrivalDate,
                        num_adults: $scope.bkgData.request.adults > 0 ? $scope.bkgData.request.adults : 0,
                        num_children: $scope.bkgData.request.childs > 0 ? $scope.bkgData.request.childs : 0,
                        num_infants: $scope.bkgData.request.infants > 0 ? $scope.bkgData.request.infants : 0,
                        travel_class: $scope.bkgData.request.cabinClass == 'Y' ? 'economy' : 'business',
                        compagnie: $scope.bkgData.purchaseResponse.itineraries[0].marketingCarrier.name,
                        preferred_num_stops: $scope.bkgData.purchaseResponse.itineraries[0].stops
                    });
                    console.log("apres le tracking de fbq cdiscount Initiate Checkout Air Payment Body Be2Bill ");
                }

                requestAnimationFrame(waitForfbq);
            }
        }
        catch (ex) {
            console.log(ex.message);
            $window.trackingError = ex.message;
        }
        var getBasketDetails = function () {
            var siteTypeIsB2b = $window.site.type === 2;
            var proposal = $scope.bkgData.purchaseResponse.proposalsRequested;
            var mandatoryFees = [];
            var totalAccommodations = siteTypeIsB2b ? $scope.bkgData.agencyMarginHotel.value : 0;
            $scope.bkgData.accommodations.forEach(function (accommodation) {
                totalAccommodations += siteTypeIsB2b ? accommodation.totalPriceRacknet : accommodation.totalPriceForcedPrice;
            });
            mandatoryFees.push({
                flexyDescription: getT('airbooking.flightprice'),
                totalPrice: $scope.bkgData.prixTotal.value,
                prices: []
            });
            mandatoryFees.push({
                flexyDescription: getT('mrfly-widget-accommodation-info.hotelprice'),
                totalPrice: totalAccommodations,
                prices: []
            });
            if (proposal.mandatoryFees) {
                proposal.mandatoryFees.forEach(function (mandatoryFee) {
                    mandatoryFee.flexyDescription = getT('mrfly-widget-basket.bookingfees');
                });
                Array.prototype.push.apply(mandatoryFees, proposal.mandatoryFees);
            }
            return mandatoryFees;
        }
        $scope.LoadVueJsWidgets = function () {
            if ($scope.bkgData.accommodations && $scope.bkgData.accommodations.any()) {

                //nights number
                var nights = moment($scope.bkgData.accommodations[0].to).diff(moment($scope.bkgData.accommodations[0].from), "days");
                $scope.bkgData.accommodations[0].nightNumber = nights;

                getBundleWidget("mrfly-widget-accommodation-info.js").done(function () {

                    mrflyWidgets['mrfly-widget-accommodation-info'].locale = $window.lng.substring(0, 2);
                    mrflyWidgets['mrfly-widget-accommodation-info'].accommodation = $scope.bkgData.accommodations[0];
                    mrflyWidgets['mrfly-widget-accommodation-info'].siteType = window.site.type;
                    if ($scope.bkgData.agencyMarginHotel) {
                        mrflyWidgets['mrfly-widget-accommodation-info'].agencyMarginHotel = $scope.bkgData.agencyMarginHotel.value;
                    }
                })
                    .fail(function (jqxhr, settings, exception) {
                        console.log(exception);
                    });


                getBundleWidget("mrfly-widget-basket-total-price.js").done(function () {
                    mrflyWidgets['mrfly-widget-basket-total-price'].price = $scope.bkgData.totalBasketPrice();
                    var extraDetails = [];

                    var bags = basketHelper.getAdditionalBagsPriceDetails($scope.siteType, $scope.bkgData.bookingRequest.passengers);
                    var insurances = basketHelper.getInsurancePriceDetails($scope.siteType, $scope.bkgData.bookingRequest.insurance, $scope.bkgData.purchaseResponse.proposalsRequested.insurances);
                    Array.prototype.push.apply(extraDetails, bags);
                    Array.prototype.push.apply(extraDetails, insurances);

                    //Seatmap
                    var seatmapObj = JSON.parse(window.sessionStorage.getItem('selectedSeats_' + $scope.bkgData.purchaseResponse.purchaseId));
                    if (seatmapObj != null) {
                        var seatmap = basketHelper.getSeatMapPriceDetails($scope.siteType, seatmapObj);
                        Array.prototype.push.apply(extraDetails, seatmap);
                    }

                    var basketDetails = basketHelper.getPriceDetails(
                        $window.site.type,
                        $scope.bkgData.purchaseResponse.proposalsRequested,
                        $scope.bkgData.accommodations,
                        $scope.bkgData.agencyMarginHotel,
                        extraDetails
                    );

                    mrflyWidgets['mrfly-widget-basket-total-price'].basketDetails = basketDetails;
                })
                    .fail(function (jqxhr, settings, exception) {
                        console.log(exception);
                    });

            }
        };

        if (bkgData) {

            bkgData.totalBasketPrice = function () {
                if ($scope.bkgData.accommodations && $scope.bkgData.accommodations.any()) {
                    //never ever use totalPriceForcedPrice in B2B!
                    var proposal = $scope.bkgData.purchaseResponse.proposalsRequested;
                    var mandatoryOptionsFees = 0;
                    if (proposal.mandatoryFees) {
                        mandatoryOptionsFees = proposal.mandatoryFees.reduce(function (accumulator, mandatoryFee) {
                            return accumulator + mandatoryFee.totalPrice;
                        }, 0);
                    }
                    if (siteTypeIsB2b) {
                        // mandatory Options
                        var insurance = 0;
                        if ($scope.bkgData.insurancesSelected.isRepatriament != null && $scope.bkgData.insurancesSelected.isRepatriament.amount != null) {
                            insurance = parseFloat($scope.bkgData.insurancesSelected.isRepatriament.amount);
                        }
                        var totalAmount = ($scope.bkgData.prixTotalWithoutMargin.value - insurance + $scope.bkgData.accommodations[0].totalPriceRacknet);
                        $scope.bkgData.agencyMarginHotel.value = getAmountFromMargin(totalAmount, $scope.bkgData.agencyMarginHotel.margin);
                        var basketPrice = totalAmount + $scope.bkgData.agencyMarginHotel.value + insurance + mandatoryOptionsFees;
                        console.log("AirPaymentBodyBe2Bill.B2B.basketPrice", basketPrice);
                        return basketPrice;
                    }
                    else {
                        var basketPrice = $scope.bkgData.prixTotal.value + $scope.bkgData.accommodations[0].totalPriceForcedPrice + mandatoryOptionsFees;
                        console.log("AirPaymentBodyBe2Bill.B2C.basketPrice", basketPrice);
                        return basketPrice;
                    }
                } else {
                    var basketPrice = $scope.bkgData.prixTotal.value;
                    console.log("AirPaymentBodyBe2Bill.Other.basketPrice", basketPrice);
                    return basketPrice;
                }
            };

            var keyGenerated = "";
            keyGenerated = bkgData.request.requestId + "_" + bkgData.purchaseRequest.index + "_" + bkgData.purchaseRequest.group + "_" + bkgData.purchaseRequest.proposal;
            bkgData.bookingRequest = tseUtil.Session.get("bookingRequest_" + keyGenerated);
            if (bkgData.purchaseResponse.productIsTrain) $scope.hideInsurance = bkgData.purchaseResponse.productIsTrain;
            else $window.hideInsurance ? $scope.hideInsurance = true : $scope.hideInsurance = false;
            if (bkgData.bookingRequest != null && bkgData.bookingRequest.requestId == bkgData.request.requestId) {
                var _insuranceSelected = tseUtil.Session.get("insurancesSelected_" + keyGenerated);
                bkgData.insurancesSelected = _insuranceSelected;

                $scope.bankCardsKey = bkgData.bookingRequest.paymentMethod.bankCard.bankCards.groupByKey("bankCardReference");

                $scope.webProcessed = true;
                if (bkgData.accommodations && bkgData.accommodations.any()) {
                    $scope.LoadVueJsWidgets();
                }

                $scope.updateBookingSummary = function () {
                    $scope.$broadcast("onBookingSummaryUpdate");
                };

                $scope.$on("onBookingSummaryUpdateCompleted", function (event, args) {

                    if (bkgData.accommodations && bkgData.accommodations.any() && $scope.isAirbus) {
                        $scope.prixTotalWithoutMargin = { value: bkgData.prixTotalWithoutMargin.value + bkgData.accommodations[0].totalPriceForcedPrice };
                    }
                    else {
                        $scope.prixTotalWithoutMargin = bkgData.prixTotalWithoutMargin;
                    }
                });
                $scope.updateBookingSummary();

                window.tokenizeHandler = function () {
                    var cardName = $("#i-sec-owner").val();
                    if (cardName == null || cardName == undefined || cardName == "") {
                        $window.XLConfirm({ vTitle: $i18next.t("simplepayment.checkdata"), vTextYes: "OK", vHideNo: true });
                        return;
                    }

                    hfields.createToken(function (result) {
                        //be2bill result
                        console.log(result);
                        if (result.execCode == '0000') {
                            // Add token to form request
                            // Also add cardtype: unknown / visa / mastercard / american_express / maestro / bancontact
                            $scope.goTo(result.hfToken, result.cardType, result.selectedBrand);
                        }
                        else {
                            $window.XLConfirm({ vTitle: "Error", vTextYes: "OK", vHideNo: true });
                        }
                    });
                    return false;
                };


                $scope.setBankCardReference = function (key) {
                    $scope._cardReference = key;
                };

                $scope.goTo = function (item, cardType, selectedBrand) {

                    var selectedBrand = (typeof selectedBrand !== 'undefined') ? selectedBrand : "";

                    //$window.sendAnalytics('/Flightsearch/Payment/CBwait', 'Payment CB Wait');

                    //Tracking
                    try {
                        //GTM
                        if (trackingService.isActivated()) {
                            trackingService.pushPageData('/Flightsearch/Payment/CBwait', 'Payment CB Wait', lng, false);
                        }
                    }
                    catch (ex) {
                        console.log(ex.message);
                        $window.trackingError = ex.message;
                    }

                    //$window.sendXiti("tunnel_resavol::attente_paiement_resavol", 3);

                    $window.site.pushNoBackOnHistory();
                    var cardName = $("#i-sec-owner").val();

                    window.mrflyWidgets['airbooking-process-loader'].$store.commit("showLoader");


                    var ReturnParameters = [];
                    ReturnParameters[0] = { "Key": "HfToken", "Value": item };
                    ReturnParameters.push({ "Key": "SelectedBrand", "Value": selectedBrand });
                    if (window.site.partnerId == "servair") {
                        //PostTotal Before Payment and get result 
                        var selectedProduct = window.sessionStorage.getItem("selectedProduct");
                        var bookingNumber = null;
                        var origineAirportIata;
                        var destinationAirportIata;
                        if ($scope.bkgData != null && $scope.bkgData.bookingResponse != undefined && $scope.bkgData.bookingResponse.numbers.length > 0) {
                            bookingNumber = $scope.bkgData.bookingResponse.numbers[0].bookingNumber;
                            origineAirportIata = bkgData.purchaseResponse.itineraries[0].origin.airportIata;
                            destinationAirportIata = bkgData.purchaseResponse.itineraries[0].destination.airportIata;
                        }
                        var aireportIata = origineAirportIata + destinationAirportIata;
                        $window.Typescript.Servair.Services.ServairService.doPostTotal(bkgData.bookingRequest.requestId, $window.site.currentAirRequest.bookingId, selectedProduct, bookingNumber, aireportIata).then(function (result) {

                            if (result.data === true) {
                                //ReturnParameters[1] = { "Key": "AliasCreated", "Value": $("#i-check-f1")[0].checked ? "1" : "0" };
                                $scope.apiClient.processPayment({
                                    "requestId": bkgData.bookingRequest.requestId,
                                    "purchaseId": $scope.bkgData.purchaseResponse.purchaseId,
                                    "paymentId": bkgData.bookingResponse.paymentId,
                                    "type": 1,
                                    "returnUrl": $window.returnUrlFor3DS,
                                    "paymentMethod": $scope.bkgData.bookingRequest.paymentMethod,
                                    "transactionMethod": $scope.bkgData.bookingRequest.paymentMethod.transactionMethod,
                                    "bankcard": {
                                        "cardholder": cardName,
                                        "reference": cardType
                                    },
                                    "returnParameters": ReturnParameters
                                }).success(function (data) {
                                    var finishUrl = tseUtil.Url.appendValueToUrlBefore($window.site.currentAirRequest.finishUrl, "prqid", data.paymentRequestId);
                                    var selectedProduct = window.sessionStorage.getItem("selectedProduct");
                                    finishUrl = tseUtil.Url.appendValueToUrl(finishUrl, "selectedproduct", selectedProduct);
                                    window.location.href = finishUrl;
                                });
                            }
                            else {
                                //redirect to error page because postTotal failed
                                document.location.href = "/airpaymentresponse/" + bkgData.bookingRequest.requestId + "/" + $window.site.currentAirRequest.bookingId + "?posttotalfailed=1";
                            }

                        });
                    }
                    else {
                        //ReturnParameters[1] = { "Key": "AliasCreated", "Value": $("#i-check-f1")[0].checked ? "1" : "0" };
                        $scope.apiClient.processPayment({
                            "requestId": bkgData.bookingRequest.requestId,
                            "purchaseId": $scope.bkgData.purchaseResponse.purchaseId,
                            "paymentId": bkgData.bookingResponse.paymentId,
                            "type": 1,
                            "returnUrl": $window.returnUrlFor3DS,
                            "paymentMethod": $scope.bkgData.bookingRequest.paymentMethod,
                            "transactionMethod": $scope.bkgData.bookingRequest.paymentMethod.transactionMethod,
                            "bankcard": {
                                "cardholder": cardName,
                                "reference": cardType
                            },
                            "returnParameters": ReturnParameters
                        }).success(function (data) {
                            var finishUrl = tseUtil.Url.appendValueToUrlBefore($window.site.currentAirRequest.finishUrl, "prqid", data.paymentRequestId);
                            window.location.href = finishUrl;
                        });
                    }


                };

                angular.element(function () {

                    console.log("initializing be2bill");

                    var id = $scope.bkgData.bookingRequest.paymentMethod.bankCard.apiKeyId;
                    var value = $scope.bkgData.bookingRequest.paymentMethod.bankCard.apiKeyValue;
                    var fontFamily = "Arial, Helvetica, sans-serif";
                    var fontSize = "14px";
                    var style = {
                        "input": {
                            "font-size": fontSize
                        },
                        "::placeholder": {
                            "font-size": fontSize,
                            "font-family": fontFamily,
                            "color": "#999999"
                        },
                        ":valid": {
                            "font-family": fontFamily,
                            "font-size": fontSize
                        },
                        ":empty": {
                            "font-family": fontFamily,
                            "font-size": fontSize
                        },
                        ":focus": {
                            "font-family": fontFamily,
                            "font-size": fontSize
                        },
                        ":invalid": {
                            "font-family": fontFamily,
                            "font-size": fontSize
                        }
                    };
                    hfields = be2bill.hostedFields({
                        key: {
                            id: id,
                            value: value
                        },
                        fields: {
                            'brand': {
                                id: 'brand-container'
                            },
                            'card': {
                                id: 'card',
                                placeholder: $i18next.t('simplepayment.cardnumber'),
                                style: style,
                                enableAutospacing: true,
                            },
                            'expiry': {
                                id: 'expiry',
                                placeholder: $i18next.t('simplepayment.date'),
                                style: style
                            },
                            'cryptogram': {
                                id: 'cryptogram',
                                placeholder: $i18next.t('simplepayment.ccv'),
                                style: style
                            },
                        },
                        location: "fr"
                    });
                    hfields.load();
                });
            }
            else {
                $window.FLoading({ vAction: 'show' });
                window.location.href = searchUrl;
            }

            //Taxes details click
            $scope.$on("displayTaxesDetailsClick", function (event, args) {
                $scope.displayTaxesDetailModal = true;
                $scope.modalTaxesKey = args.key.replaceAll(":", "-");
                $scope.modalTaxesKey = $scope.modalTaxesKey.replaceAll("\\|", "-");
                $scope.modalTaxesKeyAux = $scope.modalTaxesKey + "-" + args.paxFares.paxType;
                $scope.modalTaxesPaxFares = args.paxFares;

                $scope.modalShowMargin = false;

                var airlines = JSON.parse(window.sessionStorage.getItem("airlines"));

                args.marketingCarriers.each(function (value, key) {

                    if (airlines != null && airlines[value].showMargin == true || value === 'AF') {
                        $scope.modalShowMargin = true;
                    }
                });

                $scope.$broadcast("updateTaxesDetailModalData", { key: $scope.modalTaxesKey, fares: $scope.modalTaxesPaxFares });

                //Remodal workaround
                $(document).find(".remodal").each(function (a, c) {
                    var d = $(c), e = d.data("remodal-options");
                    e ? ("string" == typeof e || e instanceof String) && (e = j(e)) : e = {},
                        d["remodal"](e)
                });

                $timeout(function () {
                    $("[data-remodal-id='taxes-detail-modal-" + $scope.modalTaxesKeyAux + "']").remodal().open();
                },
                    200, false);
            });

            //Luggage details click
            $scope.$on("displayLuggageDetailsClick", function (event, args) {
                $scope.displayLuggageDetailModal = true;
                $scope.modalLuggageBagType = args.bagType;

                $scope.$broadcast("updateLuggageDetailModalData", { bagType: $scope.modalLuggageBagType });

                $scope.modalShowMargin = false;

                var airlines = JSON.parse(window.sessionStorage.getItem("airlines"));

                args.marketingCarriers.each(function (value, key) {

                    if (airlines != null && airlines[value].showMargin == true || value === 'AF') {
                        $scope.modalShowMargin = true;
                    }
                });

                //Remodal workaround
                $(document).find(".remodal").each(function (a, c) {
                    var d = $(c), e = d.data("remodal-options");
                    e ? ("string" == typeof e || e instanceof String) && (e = j(e)) : e = {},
                        d["remodal"](e)
                });

                $timeout(function () {
                    $('[data-remodal-id="luggage-detail-modal"]').remodal().open();
                },
                    200, false);
            });


        }
        else {
            $window.FLoading({ vAction: 'show' });
            window.location.href = searchUrl;
        }
    }]);